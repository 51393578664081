<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-0" *ngIf="visitors.length > 0">
            <div class="col-12 form-group mb-4">
                <label style="font-size: 0.6rem;">Buscar</label>
                <input (keyup)="lookingFor($event)" placeholder="Buscar (Departamento, torre, nombre, ...)" type="text" class="form-control material-design-primary"/>
            </div> 
        </div>

        <div class="col-12 d-flex justify-content-center my-3">
            <ngb-pagination [(page)]="page" [maxSize]="5" [pageSize]="pageSize" [collectionSize]="auxVisitors.length">
            </ngb-pagination>
        </div>

        <div class="col-12 p-0">
            <table class="table table-striped text-center">
                <thead>
                    <tr class="bg-secondary">
                        <th>Nombres</th>
                        <th>Departamento</th>
                        <th>Torre</th>
                        <th>{{history ? 'Fecha de llegada' : 'Fecha de alta'}}</th>
                        <th *ngIf="history">Fecha de salida</th>
                    </tr>
                   
                </thead>
                <tbody *ngIf="!loading">
                    <tr *ngFor="let visitor of auxVisitors | slice: (page-1) * pageSize : page * pageSize; index as i; first as isFirst">
                        <td>{{visitor.name != null ? visitor.name : getNames(visitor.names)}}</td>
                        <td>{{visitor.flat}}</td>
                        <td>{{visitor.building}}</td>
                        <td *ngIf="!history">{{visitor.dateCreated.seconds*1000 | date: 'EEEE, MMMM d, y HH:mm'}}</td>
                        <td *ngIf="history">
                            <span *ngIf="visitor.dateArrive">{{visitor.dateArrive.seconds*1000 | date: 'EEEE, MMMM d, y HH:mm'}}</span>
                            <span *ngIf="!visitor.dateArrive">No se presentó</span>
                        </td>
                        <td *ngIf="history">
                            <span *ngIf="visitor.isNew && visitor.dateExit != null && visitor.dateArrive != null">{{visitor.dateExit.seconds*1000 | date: 'EEEE, MMMM d, y HH:mm'}}</span>
                            <span *ngIf="visitor.isNew && visitor.dateExit == null && visitor.dateArrive != null">No ha salido</span>
                            <span *ngIf="visitor.isNew && visitor.dateExit == null && visitor.dateArrive == null">-</span>
                            <span *ngIf="!visitor.isNew">No hay fecha de salida registrada</span>
                        </td>
                        <!-- <td>{{history ? visitor.dateCreated.seconds*1000 | date: 'dd/MM/yyyy HH:mm:ss' : visitor.dateArrive ? visitor.dateArrive.seconds*1000 | date: 'dd/MM/yyyy HH:mm:ss' : 'No se presentó'}}</td> -->

                    </tr>

                    <tr *ngIf="visitors.length == 0">
                        <td [attr.colspan]="colSpan">No hay visitas registradas</td>
                    </tr>
                </tbody>

                <div class="col-sm-3" *ngIf="history">
                    <i (click)="exportTemplateAsExcel()" class="fa fa-file-excel-o fa-3x style-icon" aria-hidden="true"></i>
                </div>
               
                <tbody *ngIf="loading">
                    <tr>
                        <td [attr.colspan]="colSpan">Cargando...</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-12 d-flex justify-content-center mt-5">
            <ngb-pagination [(page)]="page" [maxSize]="5" [pageSize]="pageSize" [collectionSize]="auxVisitors.length">
            </ngb-pagination>
        </div>
    </div>
</div>
