<div class="container-fluid py-3">
    <div class="row">
        <div class="col-12">
            <div class="table100">
                <table id="customers" class="table table-striped text-center">
                    <thead>
                        <tr class="bg-secondary">
                            <th>Nombre</th>
                            <th>E-mail</th>
                            <th>Descripción</th>
                            <th>Estatus</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let watchmam of watchmen; index as i; first as isFirst">
                            <td class="align-middle">{{watchmam.nombre}}</td>
                            <td class="align-middle">{{watchmam.email}}</td>
                            <td class="align-middle">{{watchmam.descripcion}}</td>
                            <td class="align-middle">{{watchmam.status ? 'Activo' : 'Inactivo'}}</td>
                            <td class="align-middle">
                                <ng-template #editar>Editar usuario</ng-template>
                                <ng-template #borrar>Eliminar usuario</ng-template>
                                <button (click)="openModalEditWatcher(watchmam.id)" class="btn" [ngbTooltip]="editar"><i class="material-icons">edit</i></button>
                                <button (click)="deleteUser(watchmam)" class="btn" [ngbTooltip]="borrar"><i class="material-icons">delete</i></button>
                            </td>
                        </tr>
                        <tr *ngIf="watchmen.length == 0">
                            <td colspan="5">No hay vigilantes registrados</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-modal-agregar-vigilante></app-modal-agregar-vigilante>