<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="container-fluid body-edit">
        <div class="row">

            <!-- <div class="col-sm-12">
                <label>Condominio</label>
                <select formControlName="idCondominio">
                    <option *ngFor="let condo of condos; index as i; first as isFirst" [value]="condo.key" (change)="condoName(condo.name)">
                        {{condo.nombre}}
                    </option>
                </select>
            </div> -->

            <div class="col-sm-6 mb-5">
                <label>Nombre del {{user.descripcion}}</label>
                <input type="text" formControlName="nombre" class="form-control material-design-primary">
            </div>

            <div class="col-sm-6 mb-5">
                <label>Correo electrónico</label>
                <input type="text" formControlName="email" class="form-control material-design-primary">
            </div>


            <div class="col-sm-6">
                <label>Elija que modulos operar </label>
                <label class="container">Reservaciones
                    <input formControlName="isReservacion" type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Servicios
                    <input formControlName="isServicio" type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <!-- <div class="col-sm-6">
                <label>Permisos que tendra el usuario</label>
                <label class="container">Escribir
                    <input formControlName="isEscribir" type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Editar
                    <input formControlName="isEditar" type="checkbox">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Eliminar
                    <input formControlName="isEliminar" type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div> -->

            <div class="col-sm-6">
                <div class="row">

                    <div class="col-12 mb-3">
                        Permisos que tendra el usuario
                    </div>

                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <div class="checkbox checkbox-slider--b-flat checkbox-slider-md col-12 p-0">
                                <label class="d-flex justify-content-between col-12">
                                    <input type="checkbox" class="" formControlName="isEscribir"><span class="m-0"><h2>Escribir</h2></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <div class="checkbox checkbox-slider--b-flat checkbox-slider-md col-12 p-0">
                                <label class="d-flex justify-content-between col-12">
                                    <input type="checkbox" class="" formControlName="isEditar"><span class="m-0"><h2>Editar</h2></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <div class="checkbox checkbox-slider--b-flat checkbox-slider-md col-12 p-0">
                                <label class="d-flex justify-content-between col-12">
                                    <input type="checkbox" class="" formControlName="isEliminar"><span class="m-0"><h2>Eliminar</h2></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <label class="container">Editar
                    <input formControlName="isEditar" type="checkbox">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Eliminar
                    <input formControlName="isEliminar" type="checkbox">
                    <span class="checkmark"></span>
                </label> -->
            </div>

            <div class="col-sm-6">
                <label>Torres</label>
                <label *ngFor="let building of buildings; index as i; first as isFirst" class="container">{{building.building.nombre}}
                    <input (change)="torres(building)"
                        type="checkbox"
                        [checked]="building.status"
                        [(ngModel)]="building.status"
                    >
                    <span class="checkmark"></span>
                </label>
            </div>


            <div class="col-sm-12 mt-5">
                <input type="submit" value="Guardar" class="btn btn-success col-12">
            </div>

        </div>
    </div>
</form>