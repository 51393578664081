import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseAuthService } from '../../../../services/firebase/firebase-auth.service';
import { FirestoreService } from '../../../../services/firestore/firestore.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-agregar-vigilante',
  templateUrl: './modal-agregar-vigilante.component.html',
  styleUrls: ['./modal-agregar-vigilante.component.css']
})
export class ModalAgregarVigilanteComponent implements OnInit {

  closeResult = '';
  public dataMail: any;
  public idCondominio;
  public isEscribir;

  public formWatchman: FormGroup;

  public loading: boolean = false;
  public submitted: boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private firebaseAuth: FirebaseAuthService,
    private firestoreService: FirestoreService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.isEscribir = localStorage.getItem('isEscribir');
    this.formWatchman = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      descripcion: ['Usuario Operativo'],
      status: [true],
      idTipo: [4],
      idCondominio: [this.idCondominio],
    });
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    // console.log(this.formWatchman.value);
    if (this.formWatchman.invalid) {
      this.loading = false;
      return;
    }

    this.firestoreService.addUserWatchman(this.formWatchman.value).then(() => {
      this.dataMail= {
        to: this.formWatchman.value.email,
        message: "Buenas tardes, bienvenido a condoclick, usted ha sido dado de alta por un administrador, favor de crear una cuenta, para que pueda tener acceso.",
        subject: "condoclick.com.mx",
        html:
        "<html>" +
        "<body>" +  
        "<h1>CondoClick</h1>"+
        "<p>Buenas tardes, bienvenido a condoclick, usted ha sido dado de alta por un administrador, favor de crear una cuenta, para que pueda tener acceso.<p>" +
        "<a href='condoclick.com.mx/registro'>Debe dar click aqui.</a>" +
        "</body>" +
        "</html>",

        }
        this.sendMail(this.dataMail);
     
    }, (error) => {
      console.error(error);
    });
  }

  get f(){ return this.formWatchman.controls; }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  close(content){
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  sendMail(data) {
    this.firebaseAuth.sendMail(data).subscribe(
      response => {
        console.log(response);
        this.loading = false;
        this.submitted = false;
        this.formWatchman.reset();
        this.close('');
      },
      error => {
        console.log(error);
        this.loading = false;
        this.submitted = false;
        this.close('');
      }
    );
  }

}
