<ng-template #content let-modal>
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title"><b>Agregar Vigilante</b></h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form id="addWatchman" [formGroup]="formWatchman" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-12 mb-5">
                    <label for="dateReservation">Nombre del vigilante</label>
                    <input [disabled]="loading" type="text" formControlName="name" id="dateReservation" class="form-control material-design-primary" placeholder="Nombre del vigilante" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Por favor, ingresa nombre del vigilante</div>
                    </div>
                </div>

                <div class="form-group col-12 mb-4">
                    <label for="dateReservation">Correo Electrónico</label>
                    <input [disabled]="loading" type="email" formControlName="email" id="dateReservation" class="form-control material-design-primary" placeholder="Correo Electrónico" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Por favor, ingresa correo electrónico del vigilante</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="container-fluid px-4 py-3">
        <div class="row">
          <button *ngIf="!loading" type="button" class="btn btn-danger col-12 mb-3" (click)="modal.close('Save click')">Cancelar</button>
          <button type="submit" [disabled]="loading" form="addWatchman" class="btn btn-success col-12">{{loading ? 'Cargando...' : 'Agregar Vigilante'}}</button>
  
        </div>
      </div>
</ng-template>

<button *ngIf="isEscribir == 'true'" class="btn btn-lg position-fixed m-5 rounded-circle bg-success shadow" style="bottom: 0px; right: 0px;" (click)="open(content)">
    <i class="material-icons text-white mt-2">add</i>
</button>