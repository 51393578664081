import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../services/firestore/firestore.service';
import { map, flatMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { Reservation } from 'src/app/models/reservation.model';

import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-modal-reservacion',
  templateUrl: './modal-reservacion.component.html',
  styleUrls: ['./modal-reservacion.component.css']
})
export class ModalReservacionComponent implements OnInit {

  public idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public idArea = this.currentRoute.snapshot.params.idArea;
  closeResult = '';
  public users = [];
  public user: any;
  public area: any;
  public flat: any;
  public dateSelected: any;
  public blockSelected: any;
  public areas = [];
  public flats = [];
  public blocks = [];
  public auxBlock = [];
  public towers = [];
  public tower: any;
  public isEscribir;
  public messageBlocks: any = "";
  public loading: boolean = false;
  public disabled: boolean = true;

  public buttonBookDisabled: boolean = true;
  public buttonBookLabel: string = "Reservar";

  public reservationDate;

  constructor(
    private firestoreService: FirestoreService,
    private currentRoute: ActivatedRoute,
    private router: Router,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.isEscribir = localStorage.getItem('isEscribir');
    this.getUsers();
    this.getAreas();
    this.getTowers();
  }

  /*
   ██████╗██╗  ██╗███████╗ ██████╗██╗  ██╗██████╗ ██╗      ██████╗  ██████╗██╗  ██╗ █████╗ ██╗   ██╗ █████╗ ██╗██╗      █████╗ ██████╗ ██╗     ███████╗
  ██╔════╝██║  ██║██╔════╝██╔════╝██║ ██╔╝██╔══██╗██║     ██╔═══██╗██╔════╝██║ ██╔╝██╔══██╗██║   ██║██╔══██╗██║██║     ██╔══██╗██╔══██╗██║     ██╔════╝
  ██║     ███████║█████╗  ██║     █████╔╝ ██████╔╝██║     ██║   ██║██║     █████╔╝ ███████║██║   ██║███████║██║██║     ███████║██████╔╝██║     █████╗
  ██║     ██╔══██║██╔══╝  ██║     ██╔═██╗ ██╔══██╗██║     ██║   ██║██║     ██╔═██╗ ██╔══██║╚██╗ ██╔╝██╔══██║██║██║     ██╔══██║██╔══██╗██║     ██╔══╝
  ╚██████╗██║  ██║███████╗╚██████╗██║  ██╗██████╔╝███████╗╚██████╔╝╚██████╗██║  ██╗██║  ██║ ╚████╔╝ ██║  ██║██║███████╗██║  ██║██████╔╝███████╗███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝  ╚═══╝  ╚═╝  ╚═╝╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝

  */
  /**
   * FUNCTION checkBlockAvailable
   */
  async checkBlockAvailable(): Promise<boolean> {
    let flag = false;

    let showHours = false;

    let dateStart, dateEnd;

    let futureDate = moment().add({ hours: this.area.antelacion });
    let auxFutureDate = moment().add({ hours: this.area.antelacion + 1 });

    console.log("Future date: ", futureDate.toString());    

    let dateSelected = moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${moment().format('HH:mm:ss')}`);

    let auxDateSelected = moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${moment().format('HH:mm:ss')}`).add({ hours: 1 });

    console.log("Date selected: ", auxDateSelected.toString());

    console.log("Diferrencia de dias: ", auxDateSelected.diff(moment(), 'days'));

    this.dateSelected = dateSelected;

    if (auxDateSelected.diff(moment(), 'days') >= 0) {
      if (moment().isBefore(dateSelected)) {
        console.log("SI");

        if (moment().add({ hours: this.area.antelacion }).isBefore(dateSelected)) {
          console.log("SI BEFOREHEAD");
          showHours = false;
        }

        else {
          showHours = true;

          console.log("Diferencia de fecha seleccionada y la fecha futura: ", moment.duration(moment(`${moment(dateSelected).format('YYYY-MM-DD')} 23:59:59`).diff(futureDate)).hours());


          if (moment.duration(moment(`${moment(dateSelected).format('YYYY-MM-DD')} 23:59:59`).diff(futureDate)).hours() > 0) {
            dateStart = moment(`${moment(dateSelected).format('YYYY-MM-DD')} 00:00:00`);
            dateEnd = futureDate;
          }

          else {
            dateStart = moment(`${moment(dateSelected).format('YYYY-MM-DD')} 00:00:00`);
            dateEnd = moment(`${moment(dateSelected).format('YYYY-MM-DD')} 23:59:59`);
          }
        }
      }

      else {
        showHours = true;

        console.log("Future date difference with now in days", moment(auxFutureDate).diff(moment(), 'days'));


        if (moment(auxFutureDate).diff(moment(), 'days') > 0) {
          console.log("Si es despues");
          dateStart = moment();
          dateEnd = moment(`${moment().format('YYYY-MM-DD')} 23:59:59`);
        }

        else {
          dateStart = moment();
          dateEnd = futureDate;
        }
      }
    }

    else {
      showHours = false;
    }

    console.log(showHours);
    

    let hoursDocs = await this.firestore.collection(
      `/Condominios/${this.idCondominio}/Reservaciones`,
      ref => ref.where('idArea', '==', this.area.id)
      .where('fechaInicio', '>=', moment().toDate())
      .where('fechaInicio', '<=', moment(dateEnd).toDate())
      .where('status', '==', true)
    ).get().toPromise();

    console.log(hoursDocs.docs.length);
    

    let reservationsBooked = hoursDocs.docs.map((hourDoc) => {
      // console.log(hourDoc.data());

      return new Reservation({
        id: hourDoc.id,
        ...hourDoc.data() as any
      })
    });

    console.log(reservationsBooked);

    let bookersReservationsBooked = reservationsBooked.filter(
      reservation => reservation.idDepartamento == this.flat.id
    );

    // console.log(bookersReservationsBooked);

    let currentDate = moment(this.utilService.serverDate);

    console.log(currentDate);
    

    console.log(moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${this.blockSelected.fin}`));
    console.log(moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${this.blockSelected.inicio}`));
    let dateBooked = moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${this.blockSelected.inicio}`)
    console.log(dateBooked.diff(currentDate, 'hours'));
    
    let diffHours = dateBooked.diff(currentDate, 'hours');

    if(diffHours <= this.area.antelacion) {
      if(bookersReservationsBooked.length < this.area.porDepartamento) {

        let blocksReservationsBooked = reservationsBooked.filter(
          reservation => reservation.bloque == this.blockSelected.id
        );

        if(blocksReservationsBooked.length < this.area.capacidad) {
          flag = true;
        } 
      }
    } else {
      console.log('Fuera de horario');
      
    }

    // hoursDocs.toPromise()

    return flag;
  }

  /*
  ███████╗███████╗████████╗████████╗ ██████╗ ██╗    ██╗███████╗██████╗
  ██╔════╝██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗██║    ██║██╔════╝██╔══██╗
  ███████╗█████╗     ██║      ██║   ██║   ██║██║ █╗ ██║█████╗  ██████╔╝
  ╚════██║██╔══╝     ██║      ██║   ██║   ██║██║███╗██║██╔══╝  ██╔══██╗
  ███████║███████╗   ██║      ██║   ╚██████╔╝╚███╔███╔╝███████╗██║  ██║
  ╚══════╝╚══════╝   ╚═╝      ╚═╝    ╚═════╝  ╚══╝╚══╝ ╚══════╝╚═╝  ╚═╝

  */
  /**
   * FUNCTION setTower
   */
  setTower(obj) {
    this.tower = obj;
    console.log("setTower: ", obj);

    this.reservationDate = undefined;
    this.blocks = [];
    this.disabled = true;

    this.getDepartments();
  }


  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ██╗    ██╗███████╗██████╗ ███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗██║    ██║██╔════╝██╔══██╗██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║██║ █╗ ██║█████╗  ██████╔╝███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║██║███╗██║██╔══╝  ██╔══██╗╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝╚███╔███╔╝███████╗██║  ██║███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝  ╚══╝╚══╝ ╚══════╝╚═╝  ╚═╝╚══════╝

  */
  /**
   * FUNCTION getTowers
   */
  getTowers() {
    this.firestore.collection(`/Condominios/${this.idCondominio}/Torres`)
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c => ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      .subscribe(towers => {
        console.log(towers);
        this.towers = towers;
        this.towers.length > 0 ? () => {
          this.tower = this.towers[0];
          this.getDepartments();
        } : null;
      })
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██████╗ ███████╗ █████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
  ██║  ███╗█████╗     ██║   ███████║██████╔╝█████╗  ███████║
  ██║   ██║██╔══╝     ██║   ██╔══██║██╔══██╗██╔══╝  ██╔══██║
  ╚██████╔╝███████╗   ██║   ██║  ██║██║  ██║███████╗██║  ██║
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝

  */
  /**
   * FUNCTION getArea
   */
  getArea() {
    this.area = this.areas.filter((area) =>
      area.id == this.idArea
    );

    this.area = this.area[0];

    console.log(this.area);
  }

  /*
  ██████╗  ██████╗  ██████╗ ██╗  ██╗
  ██╔══██╗██╔═══██╗██╔═══██╗██║ ██╔╝
  ██████╔╝██║   ██║██║   ██║█████╔╝
  ██╔══██╗██║   ██║██║   ██║██╔═██╗
  ██████╔╝╚██████╔╝╚██████╔╝██║  ██╗
  ╚═════╝  ╚═════╝  ╚═════╝ ╚═╝  ╚═╝

  */
  /**
   * FUNCTION book
   */
  async book() {
    this.buttonBookDisabled = true;
    this.buttonBookLabel = 'Reservando...';

    let timeDelayed = this.utilService.getRandomTime(10000);

    console.log(timeDelayed);

    await setTimeout(async () => {
      let flag = await this.checkBlockAvailable();

      console.log(flag);

      if(flag) {
        
        this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones`).add({
          area: this.area.nombre,
          bloque: this.blockSelected.id,
          fechaFin: moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${this.blockSelected.fin}`).toDate(),
          fechaInicio: moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${this.blockSelected.inicio}`).toDate(),
          icon: this.area.icon,
          idArea: this.area.id,
          idDepartamento: this.flat.id,
          idTorre: this.flat.id,
          usuario: 'Administración',
          departamento: this.flat.nombre,
          torre: this.tower.nombre,
          fechaAlta: moment().toDate(),
          status: true,
          os: navigator.userAgent,
        }).then(() => {
          console.log("SEGUN YA SE BOOKEO");
          this.disabled = true;
          this.buttonBookLabel = 'Reservar';
          this.getDismissReason(null);
          this.modalService.dismissAll();
        })
      } else {
        this.blocks = [];
        this.buttonBookLabel = 'Reservar';
        this.messageBlocks = 'No hay disponibilidad';
      }
    }, timeDelayed);

  }

  getDepartments() {
    this.firestore.collection(`/Condominios/${this.idCondominio}/Torres/${this.tower.id}/Departamentos`, ref =>
      ref.orderBy("nombre"))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c => ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      .subscribe(flats => {
        this.flats = flats;
        this.flats.length > 0 ? this.flat = this.flats[0] : null;
      })
  }

  /*
  ███████╗███████╗████████╗██████╗ ██╗      ██████╗  ██████╗██╗  ██╗
  ██╔════╝██╔════╝╚══██╔══╝██╔══██╗██║     ██╔═══██╗██╔════╝██║ ██╔╝
  ███████╗█████╗     ██║   ██████╔╝██║     ██║   ██║██║     █████╔╝
  ╚════██║██╔══╝     ██║   ██╔══██╗██║     ██║   ██║██║     ██╔═██╗
  ███████║███████╗   ██║   ██████╔╝███████╗╚██████╔╝╚██████╗██║  ██╗
  ╚══════╝╚══════╝   ╚═╝   ╚═════╝ ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝

  */
  /**
   * FUNCTION setBlock
   */
  setBlock(obj) {
    this.blockSelected = obj;
    console.log(obj);
    this.buttonBookDisabled = false;
  }

  /*
   ██████╗██╗  ██╗███████╗ ██████╗██╗  ██╗ █████╗ ██████╗ ███████╗ █████╗  █████╗ ██╗   ██╗ █████╗ ██╗██╗      █████╗ ██████╗ ██╗     ███████╗
  ██╔════╝██║  ██║██╔════╝██╔════╝██║ ██╔╝██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗██║   ██║██╔══██╗██║██║     ██╔══██╗██╔══██╗██║     ██╔════╝
  ██║     ███████║█████╗  ██║     █████╔╝ ███████║██████╔╝█████╗  ███████║███████║██║   ██║███████║██║██║     ███████║██████╔╝██║     █████╗
  ██║     ██╔══██║██╔══╝  ██║     ██╔═██╗ ██╔══██║██╔══██╗██╔══╝  ██╔══██║██╔══██║╚██╗ ██╔╝██╔══██║██║██║     ██╔══██║██╔══██╗██║     ██╔══╝
  ╚██████╗██║  ██║███████╗╚██████╗██║  ██╗██║  ██║██║  ██║███████╗██║  ██║██║  ██║ ╚████╔╝ ██║  ██║██║███████╗██║  ██║██████╔╝███████╗███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝  ╚═══╝  ╚═╝  ╚═╝╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝

  */
  /**
   * FUNCTION checkAreaAvailable
   */
  checkAreaAvailable(event) {

    this.buttonBookDisabled = true;

    // console.log(event);
    if (event.target.value != "") {

      let showHours = false;

      let dateStart, dateEnd;

      let futureDate = moment().add({ hours: this.area.antelacion });
      let auxFutureDate = moment().add({ hours: this.area.antelacion + 1 });

      console.log("Future date: ", futureDate.toString());

      let dateSelected = moment(`${moment(event.target.value).format('YYYY-MM-DD')} ${moment().format('HH:mm:ss')}`);

      let auxDateSelected = moment(`${moment(event.target.value).format('YYYY-MM-DD')} ${moment().format('HH:mm:ss')}`).add({ hours: 1 });

      console.log("Date selected: ", auxDateSelected.toString());

      console.log("Diferrencia de dias: ", auxDateSelected.diff(moment(), 'days'));

      this.dateSelected = dateSelected;

      if (auxDateSelected.diff(moment(), 'days') >= 0) {
        if (moment().isBefore(dateSelected)) {
          console.log("SI");

          if (moment().add({ hours: this.area.antelacion }).isBefore(dateSelected)) {
            console.log("SI BEFOREHEAD");
            showHours = false;
          }

          else {
            showHours = true;

            console.log("Diferencia de fecha seleccionada y la fecha futura: ", moment.duration(moment(`${moment(dateSelected).format('YYYY-MM-DD')} 23:59:59`).diff(futureDate)).hours());


            if (moment.duration(moment(`${moment(dateSelected).format('YYYY-MM-DD')} 23:59:59`).diff(futureDate)).hours() > 0) {
              dateStart = moment(`${moment(dateSelected).format('YYYY-MM-DD')} 00:00:00`);
              dateEnd = futureDate;
            }

            else {
              dateStart = moment(`${moment(dateSelected).format('YYYY-MM-DD')} 00:00:00`);
              dateEnd = moment(`${moment(dateSelected).format('YYYY-MM-DD')} 23:59:59`);
            }
          }
        }

        else {
          showHours = true;

          console.log("Future date difference with now in days", moment(auxFutureDate).diff(moment(), 'days'));


          if (moment(auxFutureDate).diff(moment(), 'days') > 0) {
            console.log("Si es despues");
            dateStart = moment();
            dateEnd = moment(`${moment().format('YYYY-MM-DD')} 23:59:59`);
          }

          else {
            dateStart = moment();
            dateEnd = futureDate;
          }
        }
      }

      else {
        showHours = false;
      }

      // this.dateSelected = dateSelected;
      // console.log(moment(`${moment(event.target.value).format('YYYY-MM-DD')} 00:00:00`).toDate());
      // // console.log(Date());
      // console.log('DATOS');
      // console.log(this.area);
      // console.log(this.flat);

      // console.log("DateStart: ", dateStart.toDate(), " - DateEnd ", dateEnd.toDate());


      if (showHours) {

        console.log("DateStart: ", moment(dateStart).toDate());
        console.log("DateEnd: ", moment(dateEnd).toDate());

        console.log("id apartament: ", this.flat.id);


        this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones`, ref =>
          ref.where('idArea', '==', this.area.id)
            .where('idDepartamento', '==', this.flat.id)
            .where('fechaInicio', '>', moment().toDate())
            .where('fechaInicio', '<', moment(dateEnd).toDate())
            .where('status', '==', true)
        )
          .snapshotChanges()
          .pipe(map(docs =>
            docs.map(
              c =>
                ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
            )
          ))
          .subscribe(reser => {
            console.log("reservaciones: ", reser);
            console.log("DateStart: ", dateStart.toDate(), " - DateEnd ", dateEnd.toDate());
            if (reser.length < this.area.porDepartamento) {
              this.firestore.collection(`/Condominios/${this.idCondominio}/AreasComunes/${this.area.id}/Bloques`, ref =>
                ref.orderBy('inicio')
              )
                .snapshotChanges()
                .pipe(map(docs =>
                  docs.map(
                    c => ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
                  )
                ))
                .subscribe(blocks => {
                  console.log('Bloques', blocks);
                  this.blocks = [];

                  this.auxBlock = blocks;

                  blocks.map((block, index) => {
                    console.log('bloque id: ', block.id);
                    console.log("evento again ", event.target.value);

                    this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones`, ref =>
                      ref.where('idArea', '==', this.area.id)
                        .where('bloque', '==', block.id)
                        .where('fechaInicio', '>', dateStart.toDate())
                        .where('fechaInicio', '<', dateEnd.toDate())
                        .where('status', '==', true)
                    )
                      .snapshotChanges()
                      .pipe(map(docs =>
                        docs.map(
                          c => ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
                        )
                      ))
                      .subscribe(hours => {
                        console.log("hours lenght: ", hours.length);

                        console.log("DateStart: ", dateStart.toDate(), " - DateEnd ", dateEnd.toDate());

                        if (hours.length < this.area.capacidad) {
                          console.log("SI DISPONIBLE");
                          console.log(block);

                          console.log(`${moment.duration(moment(`${moment(this.dateSelected).format('YYYY-MM-DD')} ${this.auxBlock[index].inicio}`).diff(moment.now())).hours()}`);

                          if (moment.duration(moment(`${moment(dateStart).format('YYYY-MM-DD')} ${this.auxBlock[index].inicio}`).diff(dateEnd)).minutes() <= 0 && moment.duration(moment(`${moment(dateStart).format('YYYY-MM-DD')} ${this.auxBlock[index].inicio}`).diff(dateStart)).minutes() >= 0) {
                            this.blocks.push(block);
                            console.log("Si se agrego");
                            this.blockSelected = this.blocks[0];
                            this.messageBlocks = ``;
                          }

                          this.disabled = false;

                        }

                        else {
                          this.blocks = [];
                        }
                      })
                  })
                  // console.log("HORAS DISPONIBLES");

                  // console.log(this.blocks);

                }
                )
            }
            else {
              console.log("No hay reservaciones para ti :O");
              this.blocks = [];
              this.messageBlocks = `Ya tiene una reservación dentro de las ${this.area.antelacion}`;
              this.disabled = true;
            }
          })
      }

      else {
        this.blocks = [];
        this.disabled = true;
      }

    }
  }

  /*
  ███████╗███████╗████████╗███████╗██╗      █████╗ ████████╗
  ██╔════╝██╔════╝╚══██╔══╝██╔════╝██║     ██╔══██╗╚══██╔══╝
  ███████╗█████╗     ██║   █████╗  ██║     ███████║   ██║
  ╚════██║██╔══╝     ██║   ██╔══╝  ██║     ██╔══██║   ██║
  ███████║███████╗   ██║   ██║     ███████╗██║  ██║   ██║
  ╚══════╝╚══════╝   ╚═╝   ╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝

  */
  /**
   * FUNCTION setFlat
   */
  setFlat(obj) {
    this.flat = obj;
    console.log(this.flat);
    this.disabled = true;

    this.reservationDate = undefined;
    this.blocks = [];
    this.disabled = true;

    // this.checkAreaAvailable();
  }

  getAreas() {
    this.firestoreService.getAreasList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(areas => {
      this.areas = areas;
      console.log(areas);
      this.getArea();
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.users.filter(v => v.email))
    )

  formatter = (x: any) => {
    this.user = x;
    console.log(this.user);
    this.getDepartments();
    return x.email;
  };

  getUsers() {
    this.firestoreService.getUsersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(users => {
      this.users = users;
      console.log(users);
    });

    // this.firestore.collection(`/Condominios/${this.idCondominio}/AreasComunes/${this.area.id}/Bloques`, ref =>
    //   ref.orderBy('inicio')
    // )
    // .snapshotChanges()
    // .pipe(map(docs =>
    //   docs.map(
    //     c => ({id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any>})
    //   )
    // ))
    // .subscribe(blocks =>
    // {
    //   console.log(blocks);

    // });

    // this.firestore.collection(`/Departamentos`), ref
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
