import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { flatMap, map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'app-modal-descargar-reporte',
  templateUrl: './modal-descargar-reporte.component.html',
  styleUrls: ['./modal-descargar-reporte.component.css']
})
export class ModalDescargarReporteComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore, 
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  close(content){
    this.activeModal.close(content);
  }

}
