import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { map, flatMap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EditUserWatcherModalComponent } from './edit-user-watcher-modal/edit-user-watcher-modal.component';

@Component({
  selector: 'app-usuario-vigilante',
  templateUrl: './usuario-vigilante.component.html',
  styleUrls: ['./usuario-vigilante.component.css']
})
export class UsuarioVigilanteComponent implements OnInit {

  public idCondominio;
  public watchmen: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getWatchmen();
  }

  deleteUser(watchman: any){
    if (confirm(`¿Desea borrar vigilante ${watchman.nombre}?`)) {
      this.firestore.collection(`/Administradores`).doc(watchman.id).delete();
    }
    else {

    }
  }

  getWatchmen(){
    try{
      var query = this.firestore.collection(`/Administradores`, ref => 
        ref.where('idCondominio', '==', this.idCondominio)
        .where('idTipo', '==', 4)
        .orderBy('nombre', 'asc')
      )
      .snapshotChanges()
        .pipe(map(docs =>
          docs.map(
            c => ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
          )
        ))
        .subscribe(wm => {
          console.log("all",wm);
          this.watchmen = wm;
        });

      console.log(this.watchmen);
    } catch(e){
      console.log(e.toString());
      
    }
  }

  openModalEditWatcher(id){
    const modalRef = this.modalService.open(EditUserWatcherModalComponent);

    modalRef.componentInstance.id = id;

    modalRef.result.then((result) => {
      console.log(result);
      // this.getProducts();
    })
    .catch((error) => {
      console.log(error);
      // this.getProducts();
    });
  }

}
