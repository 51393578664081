<head>
  <title>Gestión de Servicios • CondoClick</title>
</head>
<div class="container-fluid" *ngIf="!isService && idTipo == 3">
  <div class="row">

    <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-5" *ngFor="let tower of towers; index as i; first as isFirst">
      <div class="card">
        <img (click)="showServices(tower.key)" src="{{tower.url}}" alt="Avatar" style="width:100%">
        <div class="container text-center pt-3">
          <h4><b>{{tower.nombre}}</b></h4>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="!isService && idTipo == 2 || !isService && idTipo == 1">
  <div class="row">
    <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-5" *ngFor="let tower of towers; index as i; first as isFirst">
      <div class="card">
        <img (click)="showServices(tower.key)" src="{{tower.url}}" alt="Avatar" style="width:100%">
        <div class="container text-center pt-3">
          <h4><b>{{tower.nombre}}</b></h4>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="tab" *ngIf="isService">
  <button class="tablinks" (click)="pending()">Pendientes</button>
  <button class="tablinks" (click)="process()">En proceso</button>
  <button class="tablinks" (click)="finish()">Concluidos</button>
  <i (click)="exportTemplateAsExcel()" class="fa fa-file-excel-o fa-3x style-icon" aria-hidden="true"></i>
</div>


<div class="table100" *ngIf="isService && isPending">
  <table id="" class="table table-striped text-center">
    <thead>
      <tr class="bg-secondary">
        <th>Servicio</th>
        <th>Departamento</th>
        <th>Torre</th>
        <th>Fecha de alta</th>
        <th>Fecha de cierre</th>
        <th>Estatus</th>
        <!-- <th>Accion</th> -->
  
      </tr>
    </thead>
    <tr *ngFor="let service of services | slice: (page-1) * pageSize : page * pageSize; index as i; first as isFirst">
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.servicio}}</td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.departamento}}</td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">{{service.torre}}
      </td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.fechaAlta.seconds*1000 | date:'dd/MM/yyyy HH:mm'}}</td>
      <!-- <td>{{service.fechaCierre.seconds*1000 | date:'dd/MM/yyyy'}}</td> -->
      <td *ngIf="service.status == 0">Recibido</td>
      <td class="align-icon">
        <ng-template #marcha>Poner en marcha</ng-template>
        <i [ngbTooltip]="marcha" *ngIf="isEditar == 'true'" (click)="inProcess(i, services[i])" class="fa fa-car"
          aria-hidden="true"></i>
        <!-- <i *ngIf="isEliminar == 'true'" (click)="delete(i,service)" class="fa fa-trash" aria-hidden="true"></i> -->
      </td>
    </tr>

    <tr *ngIf="services.length == 0" class="text-center">
      <td colspan="6">No hay servicios disponibles.</td>
    </tr>

  </table>
  <br>
  <div class="col-12 d-flex justify-content-center">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="services.length">
    </ngb-pagination>
  </div>
  <div class="col-sm-12" *ngIf="isEscribir == 'true'">
    <button routerLink="/gestion-servicios-form" class="button">
      Nuevo
      <div class="button__horizontal"></div>
      <div class="button__vertical"></div>
    </button>
  </div>
  <!-- <div class="col-sm-6" *ngIf="isEscribir == 'true'">
    <button (click)="toExcel()" class="button">
      Excel
      <div class="button__horizontal"></div>
      <div class="button__vertical"></div>
    </button>
  </div> -->
</div>

<div class="table100" *ngIf="isService && isProcess">
  <table id="" class="table table-striped text-center">
    <thead>
      <tr class="bg-secondary">
        <th>Servicio</th>
        <th>Departamento</th>
        <th>Torre</th>
        <th>Fecha de alta</th>
        <th>Fecha de cierre</th>
        <th>Estatus</th>
        <!-- <th>Accion</th> -->
  
      </tr>
    </thead>
    <tr
      *ngFor="let service of servicesProcess | slice: (page-1) * pageSize : page * pageSize; index as i; first as isFirst">
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.servicio}}</td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.departamento}}</td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">{{service.torre}}
      </td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.fechaAlta.seconds*1000 | date:'dd/MM/yyyy HH:mm'}}</td>
      <!-- <td>{{service.fechaCierre.seconds*1000 | date:'dd/MM/yyyy'}}</td> -->
      <td *ngIf="service.status == 1">En proceso</td>
      <td class="align-icon">
        <ng-template #ter>Terminar servicio</ng-template>
        <i [ngbTooltip]="ter" *ngIf="isEditar == 'true'" (click)="update(i, servicesProcess[i])"
          class="fa fa-pencil-square-o" aria-hidden="true"></i>
        <!-- <i *ngIf="isEliminar == 'true'" (click)="delete(i,service)" class="fa fa-trash" aria-hidden="true"></i> -->
      </td>
    </tr>

    <tr *ngIf="servicesProcess.length == 0" class="text-center">
      <td colspan="6">No hay servicios pendientes.</td>
    </tr>

  </table>
  <br>
  <div class="col-12 d-flex justify-content-center">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="servicesProcess.length">
    </ngb-pagination>
  </div>
  <div class="col-sm-12" *ngIf="isEscribir == 'true'">
    <button routerLink="/gestion-servicios-form" class="button">
      Nuevo
      <div class="button__horizontal"></div>
      <div class="button__vertical"></div>
    </button>
  </div>
</div>

<div class="pt-4" *ngIf="isService && isFinish">

  <div class="col-12 mb-2">
    <small style="font-weight: 300;">Seleccionar rango de fechas</small>
  </div>

  <div class="col-12 mb-4">
          
    <form class="form-inline">
      <div class="form-group hidden">
        <div class="input-group">
          <input name="datepicker"
                 class="form-control"
                 ngbDatepicker
                 #datepicker="ngbDatepicker"
                 [autoClose]="'outside'"
                 (dateSelect)="onDateSelection($event)"
                 [displayMonths]="2"
                 [dayTemplate]="t"
                 outsideDays="hidden"
                 [startDate]="fromDate!"
                 tabindex="-1">
          <ng-template #t let-date let-focused="focused">
            <span class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group">
          <input #dpFromDate
                 class="form-control" placeholder="yyyy-mm-dd"
                 name="dpFromDate"
                 [value]="formatter.format(fromDate)"
                 (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar p-0 d-flex align-items-center" (click)="datepicker.toggle()" type="button"><i class="material-icons-outlined px-2">today</i></button>
          </div>
        </div>
      </div>
      <div class="form-group ml-2">
        <div class="input-group">
          <input #dpToDate
                 class="form-control" placeholder="yyyy-mm-dd"
                 name="dpToDate"
                 [value]="formatter.format(toDate)"
                 (input)="toDate = validateInput(toDate, dpToDate.value)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar p-0 d-flex align-items-center" (click)="datepicker.toggle()" type="button"><i class="material-icons-outlined px-2">today</i></button>
          </div>
        </div>
      </div>
    </form>

  </div>

  <table id="" class="table table-striped text-center">
    <thead>
      <tr class="bg-secondary">
        <th>Servicio</th>
        <th>Departamento</th>
        <th>Torre</th>
        <th>Fecha de alta</th>
        <th>Fecha de cierre</th>
        <th>Estatus</th>
        <!-- <th>Accion</th> -->
  
      </tr>
    </thead>
    <tr
      *ngFor="let service of servicesFinish | slice: (page-1) * pageSize : page * pageSize; index as i; first as isFirst">
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.servicio}}</td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.departamento}}</td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">{{service.torre}}
      </td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.fechaAlta.seconds*1000 | date:'dd/MM/yyyy HH:mm'}}</td>
      <td (click)="showInfo(service)" onclick="document.getElementById('id01').style.display='block'">
        {{service.fechaCierre.seconds*1000 | date:'dd/MM/yyyy HH:mm'}}</td>
      <td *ngIf="service.status == 2">Concluidos</td>
    </tr>

    <tr *ngIf="servicesFinish.length == 0 && selected" class="text-center">
      <td colspan="6">No hay servicios terminados disponibles.</td>
    </tr>

    <tr *ngIf="servicesFinish.length == 0 && !selected" class="text-center">
      <td colspan="6">Seleccione un rango de fechas.</td>
    </tr>

  </table>
  <br>
  <div class="col-12 d-flex justify-content-center">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="servicesFinish.length">
    </ngb-pagination>
  </div>
  <div class="col-sm-12" *ngIf="isEscribir == 'true'">
    <button routerLink="/gestion-servicios-form" class="button">
      Nuevo
      <div class="button__horizontal"></div>
      <div class="button__vertical"></div>
    </button>
  </div>
</div>

<div class="row" style="width: 50%;">
  <div class="w3-container">
    <div id="id01" class="w3-modal" style="width: 122%; padding-top: 96px;">
      <div class="w3-modal-content">
        <header class="w3-container w3-teal" style="background-color: #008591 !important;">
          <span onclick="document.getElementById('id01').style.display='none'"
            class="w3-button w3-display-topright">&times;</span>
          <br>

          <h2> <i class="fa fa-info-circle" aria-hidden="true"></i> Detalle del servicio solicitado</h2>


        </header>
        <div class="w3-container">
          <hr>
          <p><i class="fa fa-asterisk" aria-hidden="true"></i><strong> Torre: </strong>{{torreS}}</p>
          <p><i class="fa fa-asterisk" aria-hidden="true"></i><strong> Departamento: </strong>{{departamentoS}}</p>
          <p><i class="fa fa-asterisk" aria-hidden="true"></i><strong> Comentarios del cliente: </strong>{{comentarioS}}
          </p>
          <hr>
        </div>
        <!-- <footer class="w3-container w3-teal">
          <p>Modal Footer</p>
        </footer> -->
      </div>
    </div>
  </div>

</div>