import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  QuerySnapshot, 
  DocumentChangeAction, 
  DocumentReference 
} from '@angular/fire/firestore';
import { map, flatMap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CrearServicioAreaComponent } from './crear-servicio-area/crear-servicio-area.component';

import { ToastService } from '../../../../../services/toast/toast.service';
import { firestore } from 'firebase';

@Component({
  selector: 'app-servicios-area-seguridad',
  templateUrl: './servicios-area-seguridad.component.html',
  styleUrls: ['./servicios-area-seguridad.component.css']
})
export class ServiciosAreaSeguridadComponent implements OnInit {

  public area: any;
  public idArea = this.currentRoute.snapshot.params.idArea;
  public idCondominium: String = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public loading: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private currentRoute: ActivatedRoute,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    public toastService: ToastService,
  ) { }

  changeValueStatus(service, index){
    console.log(index);
    console.log(service);
    
    let ref = this.area.ref as DocumentReference;
    this.area.services[index] = {...service, lastUpdate: firestore.Timestamp.now()};

    ref.update({services: this.area.services})
    .then(() => {
      this.toastService.show('¡Estatus actualizado exitosamente!', { classname: 'bg-success text-light'});
    })
    .catch((e) => {
      console.log(e);
      this.toastService.show('No se pudo actualizar estatus', { classname: 'bg-danger text-light'});
    })
  }

  deleteIndex(index){
    let newArray = this.area.services.filter((service, i) => {
      return i != index;
    });

    return newArray;
  }

  deleteService(service, index){
    if(window.confirm(`¿Desea borrar servicio ${service.name} de area común para seguridad?`)){
      this.area.services = this.deleteIndex(index);
      let ref = this.area.ref as DocumentReference;
      ref.update({services: this.area.services})
      .then(() => {
        this.toastService.show('¡Servicio borrado exitosamente!', { classname: 'bg-success text-light'});
      })
      .catch((e) => {
        console.log(e);
        this.toastService.show('No se pudo borrar servicio', { classname: 'bg-danger text-light'});
      })
    }
  }

  getDataArea(){
    this.firestore.collection(`/Condominios/${this.idCondominium}/AreasSeguridad`)
    .doc(this.idArea).snapshotChanges()
    .subscribe((area) => {
      this.area = {
        ref: area.payload.ref,
        id: area.payload.id,
        ...area.payload.data() as DocumentChangeAction<any>,
        services: area.payload.data()['services'] || [],
      };
      console.log(this.area);
      this.loading = false;
    })
  }

  openModalCreateNewService(){
    const modalRef = this.modalService.open(CrearServicioAreaComponent);

    modalRef.componentInstance.area = this.area;

    modalRef.result.then((result) => {
      console.log(result);
      // this.getProducts();
    })
    .catch((error) => {
      console.log(error);
      // this.getProducts();
    });
  }

  ngOnInit(): void {
    this.getDataArea();
  }

}
