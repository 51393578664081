import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { flatMap, map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'app-modal-agregar-reporte',
  templateUrl: './modal-agregar-reporte.component.html',
  styleUrls: ['./modal-agregar-reporte.component.css']
})
export class ModalAgregarReporteComponent implements OnInit {

  public areas: Array<any> = [];
  public buildings: Array<any> = [];
  public flats: Array<any> = [];
  public services: Array<any> = [];

  public buildingSelected: boolean = false;
  public submitted: boolean = false;
  public typeChanged: boolean = false;

  public formReport: FormGroup;

  public step: number = 0;
  public type: number = 0;
  
  public idCodominium: String;
  public text: String;

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore, 
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.idCodominium = localStorage.getItem('condominio').replace(/['"]+/g, '');

    this.formReport = this.formBuilder.group({
      type: ['', Validators.required],
      building: [null, Validators.required],
      flat: ['', Validators.required],
      service: ['', Validators.required],
      comment: [''],
      area: [null],
    });

    this.getAreas();
    this.getBuildings();
    this.getServicesFlat();
  }

  get f(){ 
    return this.formReport.controls; 
  }

  checkType(event){
    // console.log(event);
    this.typeChanged = true;
    this.step = 1;
    if(event.target.value > 0){

      this.buildingSelected = false;
      
      if(event.target.value == 1){
        this.type = 1;
        this.text = 'Seleccionar Área';

        this.formReport = this.formBuilder.group({
          type: [this.formReport.value.type, Validators.required],
          area: ['', Validators.required],
          // flat: ['', Validators.required],
          service: [this.formReport.value.service, Validators.required],
          comment: [this.formReport.value.comment],
        });
      }

      if(event.target.value == 2){
        this.type = 2;
        this.text = 'Seleccionar Torre';
        this.formReport = this.formBuilder.group({
          type: [this.formReport.value.type, Validators.required],
          building: ['', Validators.required],
          flat: ['', Validators.required],
          service: [this.formReport.value.service, Validators.required],
          comment: [this.formReport.value.comment],
        });
      }
      
    }

    else{

    }
  }

  close(content){
    this.activeModal.close(content);
  }

  getAreas(){
    this.firestore.collection(`/Condominios/${this.idCodominium}/AreasSeguridad`, ref => 
      ref.where('status', '==', true).orderBy('name', 'asc')
    )
    .snapshotChanges()
    .pipe(map(docs =>
      docs.map(
        c => ({ id: c.payload.doc.id, ref: c.payload.doc.ref, ...c.payload.doc.data() as DocumentChangeAction<any> })
      )
    ))
    .subscribe(areas => {
      this.areas = areas;
    });
  }

  getBuildings(){
    this.firestore.collection(`/Condominios/${this.idCodominium}/Torres`, ref => 
      ref.where('status', '==', true).orderBy('nombre', 'asc')
    )
    .snapshotChanges()
    .pipe(map(docs =>
      docs.map(
        c => ({ id: c.payload.doc.id, ref: c.payload.doc.ref, ...c.payload.doc.data() as DocumentChangeAction<any> })
      )
    ))
    .subscribe(buildings => {
      this.buildings = buildings;
    });
  }

  getFlats(){
    console.log(this.formReport.value);
    
    this.buildingSelected = this.formReport.value.building != null ? true : false;

    // return;
    if(this.buildingSelected){
      this.firestore.collection(`/Condominios/${this.idCodominium}/Torres/${this.formReport.value.building.id}/Departamentos`, ref => 
        ref.where('status', '==', true).orderBy('nombre', 'asc')
      )
      .get()
      .pipe(map(docs =>
        docs.docs.map(
          c => ({ id: c.id, ref: c.ref, ...c.data() as DocumentChangeAction<any> })
        )
      ))
      .subscribe(flats => {
        this.flats = flats;
      });
    }

    else{
      this.flats = [];
    }
  }

  getServicesFlat() {
    this.firestore.collection(`/Condominios/${this.idCodominium}/DepartamentosSeguridad`, ref => 
      ref.where('status', '==', true).orderBy('name', 'asc')
    )
    .snapshotChanges()
    .pipe(map(docs =>
      docs.map(
        c => ({ id: c.payload.doc.id, ref: c.payload.doc.ref, ...c.payload.doc.data() as DocumentChangeAction<any> })
      )
    ))
    .subscribe(services => {
      this.services = services;
    });
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.formReport);
    if (this.formReport.invalid) {
        return;
    }

    console.log(this.formReport);

    let dateNow = new Date();

    if(this.formReport.value.type == 1){
      this.firestore.collection(`/Condominios/${this.idCodominium}/Reportes`).add({
        "comentario": this.formReport.value.comment,
        "departamento": this.formReport.value.area.name,
        "fechaAlta": dateNow,
        "idDepartamento": this.formReport.value.area.id,
        "idTorre": this.formReport.value.area.id,
        "servicio": this.formReport.value.service.name,
        "status": 0,
        "torre": this.formReport.value.area.name,
        "isArea": true,
      }).then((value) => {
        this.toastService.show('Reporte agregado exitosamente', { classname: 'bg-success text-light'});
        this.close('');
      })
    }

    else{
      this.firestore.collection(`/Condominios/${this.idCodominium}/Reportes`).add({
        "comentario": this.formReport.value.comment,
        "departamento": this.formReport.value.flat.nombre,
        "fechaAlta": dateNow,
        "idDepartamento": this.formReport.value.flat.id,
        "idTorre": this.formReport.value.building.id,
        "servicio": this.formReport.value.service.name,
        "status": 0,
        "torre": this.formReport.value.building.nombre,
        "isArea": false,
      }).then((value) => {
        this.toastService.show('Reporte agregado exitosamente', { classname: 'bg-success text-light'});
        this.close('');
      })
    }

  }

  setAreaSelected($obj){
    // console.log($obj);
    // console.log(this.formReport.value);
    
    if(typeof $obj !== 'undefined'){
      this.step = 2;
    }

    else{
      this.step = 1;
    }
    
  }

}
