import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.component.html',
  styleUrls: ['./departamentos.component.css']
})
export class DepartamentosComponent implements OnInit {

  public towers = [];

  constructor(private firestoreService: FirestoreService) { }

  ngOnInit(): void {
    this.getTowersList();
  }


  getTowersList() {
    this.firestoreService.getTowersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  // departmentT(key, nombre){
  //   console.log(key, nombre)
  //   // routerLink="/departamento-tablas/{{tower.key}}/{{tower.nombre}}"
  // }
}
