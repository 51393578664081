import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalle-reservacion',
  templateUrl: './detalle-reservacion.component.html',
  styleUrls: ['./detalle-reservacion.component.css']
})
export class DetalleReservacionComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  close(content){
    this.activeModal.close(content);
  }

}
