import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-ver-avisos',
  templateUrl: './ver-avisos.component.html',
  styleUrls: ['./ver-avisos.component.css']
})
export class VerAvisosComponent implements OnInit {

  public avisos = [];

  constructor(
    private firestoreService: FirestoreService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.getAvisos();
  }


  getAvisos() {
    this.firestoreService.getAvisos().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(avisos => {
      this.avisos = avisos;
    });
  }

  async delete(aviso) {

    if(aviso.imageRef){
      console.log('Si tiene referencia');
      await this.storage.ref(aviso.imageRef).delete();
    }

    else{
      console.log('No tiene referencia');
      
    }
    this.firestoreService
      .deleteAviso(aviso.key)
      .catch(err => console.log(err));
  }

  update(key, data) {
    if(data.status)
    data.status = false;
    else
    data.status = true;
    console.log(key, data);
    this.firestoreService
      .updateTower(key, data)
      .catch(err => console.log(err));
  }

}