import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-editar-servicio',
  templateUrl: './editar-servicio.component.html',
  styleUrls: ['./editar-servicio.component.css']
})
export class EditarServicioComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  checkoutForm;
  public torre: any;
  public departamento: any;
  public icons = [];
  public icon: any;
  public servicio: { idServicio: string, nombre: string }
  public idCondominio;
  public dataService = [];

  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }
  constructor(
    private firestoreService: FirestoreService,
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private rutaActiva: ActivatedRoute,
    private router: Router
  ) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      icon: [''],
      status: ''
    });

    this.servicio = {
      idServicio: this.rutaActiva.snapshot.params.idServicio,
      nombre: this.rutaActiva.snapshot.params.nombre
    };
  }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getIcons();
    this.getService();
  }

  setIcon($obj) {
    this.icon = $obj;
    // console.log($obj);
  }

  getIcons() {
    this.firestore.collection('/Iconos').snapshotChanges()
      .pipe(
        map(
          icons => icons.map(
            c => ({ key: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
          )
        )
      ).subscribe(icons => {
        this.icons = icons;
      })
  }

  onSubmit(customerData) {
    var date = new Date();
    customerData.icon = this.icon.icon;
    customerData.status = true;
    customerData.fechaAlta = date.getTime();
    this.firestoreService.updateService(this.servicio.idServicio, customerData).then(() => {
      this.router.navigate(['/servicios']);
    }, (error) => {
      console.error(error);
    });
   
  }

  getService() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Servicios', ref =>
      ref.where('nombre', '==', this.servicio.nombre))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));
    snapshotResult.subscribe(doc => {
      this.dataService.push(
        doc.payload.doc.data(),
      );

      this.checkoutForm.controls['nombre'].setValue(this.dataService[0].nombre);

    });
  }
}



