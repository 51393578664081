import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, flatMap, mergeMapTo, retry, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/firestore';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import * as moment from 'moment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { textChangeRangeIsUnchanged } from 'typescript';
import * as firebase from 'firebase';
import { async } from 'rxjs/internal/scheduler/async';
import { UseExistingWebDriver } from 'protractor/built/driverProviders';


@Injectable({
  providedIn: 'root'
})

export class FirestoreService {
  public users = [];
  public idCondominio;
  private dbPath;
  firebaseRef: AngularFirestoreCollection<any[]> = null;
  public myClient;
  public clientRef;
  currentMessage;
  messaging =  firebase.messaging.isSupported() ? firebase.messaging() : null;

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    // private afMessaging: AngularFireMessaging
    private angularFireMessaging: AngularFireMessaging
  ) {

    if (localStorage.getItem('condominio') != null) {
      this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    }

    this.dbPath = '/Condominios/' + this.idCondominio + '/Torres';
    this.firebaseRef = firestore.collection(this.dbPath);
  }


  public requestPermissions() {
    if(firebase.messaging.isSupported()){
      // this.angularFireMessaging.tokenChanges.subscribe(
      //   (token) => { localStorage.setItem('nToken', token);  console.log(token)},
      //   (error) => { console.error(error); },
      // )

      // var token = this.angularFireMessaging.getToken.subscribe(
      //   (token) => { localStorage.setItem('tokeeen', token); },
      //   (error) => { console.error("errror roken", error); },
      // );

      this.angularFireMessaging.deleteToken(localStorage.getItem('nToken').replace(/['"]+/g, '')).subscribe((data) => { console.log("delete token", data) })

      this.angularFireMessaging.requestPermission
        .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
        .subscribe(
          (token) => { localStorage.setItem('nToken', token); },
          (error) => { console.error(error); },
        );



      // this.angularFireMessaging.requestToken.subscribe(
      //   (token) => {
      //     localStorage.setItem('nToken', token);
      //     console.log(token);
      //   },
      //   (err) => {
      //     console.error('Unable to get permission to notify.', err);
      //   }
      // );
    }
  }

  public receiveMessage() {
    if(firebase.messaging.isSupported()){
      console.log("hello world //////");
      this.angularFireMessaging.onMessage(function (payload) {
        console.log('Received background message ', payload);
        // // Customize notification here
        // const notificationTitle = 'Background Message Title';
        // const notificationOptions = {
        //     body: 'Background Message body.',
        //     icon: '/firebase-logo.png'

        // };

        // return registration.showNotification(notificationTitle, notificationOptions);
      });
    }
  }


  // save the permission token in firestore
  private saveToken(user, token): void {

    const currentTokens = user.fcmTokens || {}
    console.log(currentTokens, token)

    // If token does not exist in firestore, update db
    if (!currentTokens[token]) {
      const userRef = this.firestore.collection('users').doc(user.uid)
      const tokens = { ...currentTokens, [token]: true }
      userRef.update({ fcmTokens: tokens })
    }
  }

  addUserWatchman(user: any) {
    let data = {
      descripcion: 'Vigilante',
      email: user.email,
      idCondominio: user.idCondominio,
      idTipo: user.idTipo,
      nombre: user.name,
      status: user.status,
    };
    return this.firestore.collection('/Administradores').add(data);
  }

  getUsersList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Usuarios');
  }

  getUsersServices(keyTower, keyDept): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + keyTower + '/Departamentos/' + keyDept + '/Usuarios');
  }

  deleteCustomer(key: string): Promise<void> {
    return this.firestore.collection("/Usuarios").doc(key).delete();
  }

  deleteDepartment(key: string, keyTower: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + keyTower + '/Departamentos').doc(key).delete();
  }

  deleteUsuarioRoot(key: string): Promise<void> {
    return this.firestore.collection('/Departamentos').doc(key).delete();
  }

  deleteUsuario(key: string, keyDept: string, keyTower: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + keyTower + '/Departamentos/' + keyDept + '/Usuarios').doc(key).delete();
  }


  deleteService(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Servicios').doc(key).delete();
  }

  deleteSecurity(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Seguridad').doc(key).delete();
  }

  async deleteCondos(key: string): Promise<void> {

    await this.firestore.collection('/Departamentos', ref => 
      ref.where('idCondominio', '==', key))
      .get().subscribe(async (values) => {
        await values.docs.forEach( async (flat) => {
          this.firestore.collection('/Departamentos').doc(flat.id).delete();
        })
      });
      
      return this.firestore.collection('/Condominios').doc(key).delete();
  }

  deleteServicesRequest(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Solicitudes').doc(key).delete();
  }

  deleteReportRequest(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Reportes').doc(key).delete();
  }

  deleteSupport(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Soporte').doc(key).delete();
  }

  deleteAdmin(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Admin').doc(key).delete();
  }

  deleteAdminRoot(key: string): Promise<void> {
    return this.firestore.collection('/Administradores').doc(key).delete();
  }

  deleteUserOper(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/UsuariosOperativos').doc(key).delete();
  }

  updateAdmin(key: string, value: any): Promise<void> {
    return this.firestore.collection('/Administradores').doc(key).update(value);
  }

  deleteReservation(key: string): Promise<void> {
    return this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones`).doc(key).delete();
  }


  updateCustomer(key: string, value: any): Promise<void> {
    return this.firestore.collection("/Usuarios").doc(key).update(value);
  }

  createUser(data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }) {
    return this.firestore.collection('/Usuarios').add(data);
  }

  createTower(data: { nombre: string, url: string, fechaAlta: string, status: boolean }) {
    console.log("create tower", this.idCondominio);
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres').add(data);
  }

  createService(data: { nombre: string, fechaAlta: Date, status: string }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Servicios').add(data);
  }

  createSecurity(data: { nombre: string, fechaAlta: Date, status: string }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Seguridad').add(data);
  }

  createSupport(data: { nombre: string, descripcion: string, status: boolean }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Soporte').add(data);
  }

  createDepartment(data: { nombre: string, fechaAlta: string, status: string }, idTorre: string) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + idTorre + '/Departamentos').add(data);
  }

  createDepartmentFlutter(data: { nombre: string, fechaAlta: Date, status: boolean }) {
    return this.firestore.collection('/Departamentos').add(data);
  }


  deleteTower(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres').doc(key).delete();
  }

  deleteAviso(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Notificaciones').doc(key).delete();
  }

  public updateService(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Servicios').doc(documentId).set(data);
  }

  public updateSecurity(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Seguridad').doc(documentId).set(data);
  }

  public updateDept(idTower: string, documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + idTower + '/Departamentos').doc(documentId).set(data);
  }

  public updateTowers(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres').doc(documentId).set(data);
  }

  public updateUserOper(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/UsuariosOperativos').doc(documentId).set(data);
  }

  public updateAdminOper(documentId: string, data: any) {
    return this.firestore.collection('/Administradores').doc(documentId).set(data);
  }

  public updateCondos(documentId: string, data: any) {
    console.log({status: data.status});
    this.firestore.collection('/Departamentos', ref => 
      ref.where('idCondominio', '==', documentId))
      .get().subscribe(async (values) => {
        await values.docs.forEach( async (flat) => {
          this.firestore.collection('/Departamentos').doc(flat.id).update({status: data.status})
        })

        return this.firestore.collection('/Condominios').doc(documentId).update(data);
      });
  }

  public updateOperateServices(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Solicitudes').doc(documentId).set(data);
  }

  public updateOperateReport(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Reportes').doc(documentId).set(data);
  }

  public updateSoporte(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Soporte').doc(documentId).set(data);
  }

  public updateUser(key: string, data: any) {
    return this.firestore.collection('/Usuarios').doc(key).set(data);
  }

  public updateTower(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres').doc(documentId).set(data);
  }

  public updateArea(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes').doc(documentId).set(data);
  }

  public updateDepartment(documentId: string, data: any) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/MlDRymc7vz5W4c5BNgBy/Departamentos').doc(documentId).set(data);
  }

  public updateDepartmentUser(documentId: string, data: any, keyTower: string, keyDepartment: string) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + keyTower + '/Departamentos/' + keyDepartment + '/Usuarios').doc(documentId).set(data);
  }



  getCustomersList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Usuarios');
  }

  getBloquesList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes/OUwdE7peutC4WX9Y2ewe/Bloques');
  }

  getAreasList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes');
  }

  getServicesList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Servicios');
  }

  getSecurityList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Seguridad');
  }

  getCondosList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios');
  }


  getSupportList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Soporte');
  }


  getAdminList(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Admin');
  }

  getUsersOper(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/UsuariosOperativos');
  }


  getReservaciones(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Reservaciones');
  }

  createArea(data: { nombre: string, capacidad: number, bloque: number, porDepartamento: number, antelacion: number }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes').add(data);
  }

  createAviso(data: { descripcion: string, capacidadMaxima: number, horaApertura: string, horaCierre: string, bloquesMaximos: number, bloquesHorario: number }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Notificaciones').add(data);
  }

  createBloques(data: { descripcion: string, capacidadMaxima: number, horaApertura: string, horaCierre: string, bloquesMaximos: number, bloquesHorario: number }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes/OUwdE7peutC4WX9Y2ewe/Bloques').add(data);
  }

  createCondo(data: { nombre: string, status: boolean, url: string }) {
    return this.firestore.collection('/Condominios').add({ dueDate: moment().add({ months: 1 }).date(), ...data });
  }

  createServicePending(data: { nombre: string, departamento: string, servicio: string, status: number, idTorre: string }) {
    console.log("dataaaa", data, '/Condominios/' + this.idCondominio + '/Solicitudes');
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Solicitudes').add(data);
  }

  createReport(data: { nombre: string, departamento: string, servicio: string, status: number, idTorre: string }) {
    console.log("dataaaa", data, '/Condominios/' + this.idCondominio + '/Reportes');
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Reportes').add(data);
  }

  createAdmin(data: { idCondominio: string, nombre: string, email: string, descripcion: string, status: boolean, idTipo: number }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Admin').add(data);
  }

  createUserOper(data: { idCondominio: string, nombre: string, email: string, descripcion: string, status: boolean, idTipo: number, isReservacion: boolean, isServicio: boolean, isEscribir: boolean, isEditar: boolean, isEliminar: boolean, torresKey: [] }) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/UsuariosOperativos').add(data);
  }

  createAdminRootOper(data: { idCondominio: string, nombre: string, email: string, descripcion: string, status: boolean, idTipo: number, isReservacion: boolean, isServicio: boolean, isEscribir: boolean, isEditar: boolean, isEliminar: boolean, torre: boolean, torresKey: [] }) {
    return this.firestore.collection('/Administradores').add(data);
  }

  createAdminRoot(data: { idCondominio: string, nombre: string, email: string, descripcion: string, status: boolean, idTipo: number }) {
    return this.firestore.collection('/Administradores').add(data);
  }


  createBloque(data: { inicio: string, fin: string, status: boolean }, key: string) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes/' + key + '/Bloques').add(data);
  }


  deleteArea(key: string): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes').doc(key).delete();
  }

  public deleteBloques(key: string, keyArea): Promise<void> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes/' + keyArea + '/Bloques').doc(key).delete();
  }

  public getTowersList() {
    return this.firebaseRef;
  }

  public getUseruariosOperativos(email) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/UsuariosOperativos', ref => ref.where('email', '==', email));
  }

  getTowers(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres');
  }

  getAvisos(): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Notificaciones');
  }

  getTowersAdmin(key): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + key + '/Torres');
  }

  getBloques(idArea): AngularFirestoreCollection<any[]> {
    console.log('/Condominios/' + this.idCondominio + '/AreasComunes/' + idArea + '/Bloques');
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes/' + idArea + '/Bloques');
  }


  public getDepartmentsList(key: string): AngularFirestoreCollection<any[]> {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + key + '/Departamentos');
  }

  public getUsersById(key: string, keyDept: string): AngularFirestoreCollection<any[]> {
    console.log("hello world usersbyid", key, keyDept);
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + key + '/Departamentos/' + keyDept + '/Usuarios');

  }

  public getRol(key: string) {
    return this.firestore.collection('/Administradores').doc(key).snapshotChanges();
  }

  public getDept(key: string, keyTower) {
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + keyTower + '/Departamentos').doc(key).snapshotChanges();
  }

  public getTorre(key: string) {
    console.log("torres", key);
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres').doc(key).snapshotChanges();
  }


  public getTowerServices(key: string) {
    console.log("key", key);
    return this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres').doc(key).snapshotChanges();
  }

  public getCondominio(key: string) {
    return this.firestore.collection('/Condominios').doc(key);
  }


  public getCustomer() {
    return this.firestore.collection('DBCondominios').doc("usuarios");
  }

  // createCustomer(customer: any): void {
  //   this.customersRef.add({...customer});
  // }

  //Obtiene todos los gatos
  public getCats() {
    return this.firestore.collection('DBCondominios').get();
  }
  //Actualiza un gato

  // public getUsers(): Observable<any[]> {
  //   return this.http.get<any[]>(environment.firebaseConfig.databaseURL + "/inquilinos.json");
  // }

  public getUsers(): Observable<any[]> {
    return this.http.get<any[]>(environment.firebaseConfig.databaseURL + "/inquilinos.json");
  }

  // updateCustomer(key: string, value: any): Promise<void> {
  //   return this.customersRef.doc(key).update(value);
  // }
  // getTowersList(): AngularFirestoreCollection<any[]> {
  //   return this.towersRef;
  // }

  query() {
    // const size$ = new Subject<string>();
    // const queryObservable = size$.pipe(
    //   switchMap(size =>
    //     this.firestore.collection('Condominios', ref => ref.where('size', '==', size)).valueChanges()
    //   )
    // );

    const snapshotResult = this.firestore.collection('torres', ref =>
      ref.where('status', '==', true)
        .limit(1))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));

    snapshotResult.subscribe(doc => {
      this.myClient = doc.payload.doc.data();
      this.clientRef = doc.payload.doc.ref;
      console.log("hello fackin world", this.myClient, this.clientRef);
    });
  }

}