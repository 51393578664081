<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>
    <div class="container-fluid">
        <div class="row">

            <!-- <div class="col-sm-12">
                <label>Condominio</label>
                <select formControlName="idCondominio">
                    <option *ngFor="let condo of condos; index as i; first as isFirst" [value]="condo.key" (change)="condoName(condo.name)">
                        {{condo.nombre}}
                    </option>
                </select>
            </div> -->

            <div class="col-sm-6">
                <label>Nombre del administrador</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Correo electronico</label>
                <input id="nombre" type="text" formControlName="email"><span class="highlight"></span>
            </div>


            <div class="col-sm-6">
                <label>Elija que modulos operara </label>
                <label class="container">Reservaciones
                    <input formControlName="isReservacion" type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Servicios
                    <input formControlName="isServicio" type="checkbox">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Seguridad
                    <input formControlName="isSeguridad" type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-sm-6">
                <label>Permisos que tendra el usuario</label>
                <label class="container">Escribir
                    <input formControlName="isEscribir" type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Editar
                    <input formControlName="isEditar" type="checkbox">
                    <span class="checkmark"></span>
                </label>
                <label class="container">Eliminar
                    <input formControlName="isEliminar" type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-sm-6">
                <label>Torres</label>
                <label *ngFor="let tower of towers; index as i; first as isFirst" class="container">{{tower.nombre}}
                    <input (change)="torres(tower.key,checkoutForm.value.torre)" formControlName="torre" type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>


            <div class="col-sm-12">
                <input [disabled]="checkoutForm.invalid" type="submit" value="Guardar">
            </div>

        </div>
    </div>
</form>