import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseAuthService } from '../../services/firebase/firebase-auth.service';
import { environment } from '../../../environments/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = 'adminCondominios';
  loged: any = localStorage.getItem('logIn') != '' ? JSON.parse(localStorage.getItem('logIn')) : '';
  isLogIn;
  isRegister = false;
  isForgot = false;
  public user = [];
  public idUser;
  public isAlert = false;


  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  register = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  forgot = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  forgotForm = new FormGroup({
    userEmail: new FormControl(''),
  });

  constructor(private authSvc: FirebaseAuthService, private router: Router, private firestoreService: FirestoreService, private firestore: AngularFirestore) { }

  ngOnInit(): void {
  }

  logOut() {
    environment.logged.isLogedIn = false;
    this.isLogIn = false;
    localStorage.clear;
  }

  async onGoogleLogin() {
    try {
      const user = await this.authSvc.loginGoogle();
      if (user) {
        localStorage.setItem('logIn', JSON.stringify(user));
        environment.logged.isLogedIn = true;
        this.isLogIn = this.loged.emailVerified;
        this.rol(user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onFaceBookLogin() {
    try {
      const user = await this.authSvc.loginFace();
      if (user) {
        localStorage.setItem('logIn', JSON.stringify(user));
        environment.logged.isLogedIn = true;
        this.isLogIn = this.loged.emailVerified;
        this.rol(user)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onLogin() {
    const { email, password } = this.loginForm.value;
    try {
      const user = await this.authSvc.login(email, password);
      if (user) {
        localStorage.setItem('logIn', JSON.stringify(user));
        environment.logged.isLogedIn = true;
        this.getIdUser();
        this.rol(this.idUser);
        var idCondominioLocal = localStorage.getItem('condominio');
        this.getDataCondos(idCondominioLocal);
        this.router.navigate(['/home']);

      } else {
        this.isAlert = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onRegister() {
    const { email, password } = this.register.value;
    try {
      const user = await this.authSvc.register(email, password);
      if (user) {
        this.isRegister = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // showRegister() {
  //   this.isRegister = true;
  //   this.router.navigate(['/registro']);
  // }

  onSendEmail(): void {
    this.authSvc.sendVerificationEmail();
  }

  ngOnDestroy() {
    this.authSvc.logout();
  }

  async onReset() {
    try {
      const { userEmail } = this.forgotForm.value;
      await this.authSvc.resetPassword(userEmail);
      window.alert('Email sent, check your inbox!');
      // this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }

  showForgot() {
    this.isForgot = true;
  }

  rol(id) {
    this.firestoreService.getRol(id).subscribe((user) => {
      this.user.push({
        id: user,
        nombre: user.payload.data()['nombre'],
        email: user.payload.data()['email'],
        descripcion: user.payload.data()['descripcion'],
        idTipo: user.payload.data()['idTipo'],
        status: user.payload.data()['status'],
        idCondominio: user.payload.data()['idCondominio'],
        isEditar: user.payload.data()['isEditar'],
        isEliminar: user.payload.data()['isEliminar'],
        isEscribir: user.payload.data()['isEscribir'],
        isReservacion: user.payload.data()['isReservacion'],
        isServicio: user.payload.data()['isServicio'],
        isSeguridad: user.payload.data()['isSeguridad']
      });
      localStorage.setItem('userOper', JSON.stringify(this.user[0].idTipo));
      localStorage.setItem('condominio', this.user[0].idCondominio);
      localStorage.setItem('isEditar', JSON.stringify(this.user[0].isEditar));
      localStorage.setItem('isEliminar', JSON.stringify(this.user[0].isEliminar));
      localStorage.setItem('isEscribir', JSON.stringify(this.user[0].isEscribir));
      localStorage.setItem('isReservacion', JSON.stringify(this.user[0].isReservacion));
      localStorage.setItem('isServicio', JSON.stringify(this.user[0].isServicio));
      localStorage.setItem('isSeguridad', JSON.stringify(this.user[0].isSeguridad));
      localStorage.setItem('email', JSON.stringify(this.user[0].email));
      localStorage.setItem('nombre', JSON.stringify(this.user[0].nombre));
      localStorage.setItem('descripcion', JSON.stringify(this.user[0].descripcion));
      window.location.reload();
    });
  }

  async getIdUser() {
    try {
      const { email, password } = this.loginForm.value;
      const snapshotResult = this.firestore.collection('/Administradores', ref =>
        ref.where('email', '==', email))
        .snapshotChanges()
        .pipe(flatMap(clients => clients));
      snapshotResult.subscribe(doc => {
        this.idUser = doc.payload.doc.id;
      });

    } catch (error) {
      console.log(error);
    }
  }

  getDataCondos(idCondominio) {
    var condominio = [];
    this.firestoreService.getCondominio(idCondominio).snapshotChanges().subscribe((condo) => {
      condominio.push({
        nombre: condo.payload.data()['nombre'],
        status: condo.payload.data()['status'],
        url: condo.payload.data()['url'],
        fecha: condo.payload.data()['fecha']
      });
      localStorage.setItem('condoData', JSON.stringify(condominio));
    });
  }

}