import { Injectable } from '@angular/core';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  DocumentReference, 
  QuerySnapshot 
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Icon } from 'src/app/models/icon/icon.model';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION createIcon                            */
  /* -------------------------------------------------------------------------- */

  async createIcon(name: string): Promise<DocumentReference>{
    return this.firestore.collection('Iconos')
      .add({
        "icon": name,
        "name": name,
      });
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION deleteIcon                            */
  /* -------------------------------------------------------------------------- */

  async deleteIcon(icon: Icon){

    let filePath = '';

    if(icon.filePath != null){

      filePath = icon.filePath;

    }

    else{
      let url = decodeURIComponent(icon.image);

      let urlSplited = url.toString().split('?')[0].split('/');
      console.log(url.toString().split('?')[0].split('/'));

      filePath = `${urlSplited[urlSplited.length - 3]}/${urlSplited[urlSplited.length - 2]}/${urlSplited[urlSplited.length - 1]}`;

    }

    try{
      await this.storage.ref(filePath).delete().toPromise();
    }

    catch(error){
      console.log(error);
      
    }

    this.firestore.collection('Iconos').doc(icon.id).delete();

    // console.log(filePath);

  }

  async updateIcon(id: string, data: any): Promise<any>{
    return this.firestore.collection('Iconos').doc(id)
      .update({
        ...data,
        'lastUpdate': moment().toDate()
      });
  }
}
