<div *ngIf="isAlert" class="alert">
	<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
	<strong><i class="fa fa-flag" aria-hidden="true"></i></strong> Se le ha enviando un correo con un link para cambiar su contraseña.
</div>

<div *ngIf="showAlert" class="alert">
	<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
	<strong><i class="fa fa-flag" aria-hidden="true"></i></strong> Su contraseña debe contar con al menos 6 caracteres.
</div>


<div class="all">
	<form [formGroup]="loginForm" method="get" id="login-form" class="login-form" autocomplete="off" role="main">
        <h1 class="a11y">Cambio de contraseña</h1>
		<img src="../assets/images/condoclick.jpeg" alt="image" class="logo">
		<div>
			<label class="label-email">
				<input (change)="onCheckRegister()" formControlName="email" type="email" class="text" name="email"
					placeholder="Email" tabindex="1" required />
				<span class="required">Email</span>
			</label>
		</div>
		<input (click)="onRegister()" type="submit" value="Cambiar contraseña" />
	</form>

</div>