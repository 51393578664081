<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="row">

                <div class="col-12 text-center mb-4">
                    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
                    </ngb-datepicker>
                    
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day"
                            [class.focused]="focused"
                            [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)"
                            (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                </div>

            </div>
        </div>
    </div>
    <app-lista-visitas [(visitors)]="rangeVisitors" [(loading)]="loading" [(history)]="history"></app-lista-visitas>
</div>