<header>
    <title>{{flat.nombre}} • CondoClick</title>
</header>

<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="container-fluid">

        <!-- <div class="row">

            <div class="col-6">
                
            </div>

            <div class="col-6">

                <div class="checkbox checkbox-slider--b-flat checkbox-slider-md">
                    <label>
                        <input type="checkbox" class="" [(ngModel)]="flat.status" (change)="changeValueStatus()" [checked]="flat.status"><span class="m-0"></span>
                    </label>
                </div>

            </div>
        </div> -->

        <div class="row">

            <div class="col-12">
                <label>Nombre</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>

            <!-- <div class="col-sm-6">
                <label>Imagen</label>
                <input formControlName="imagen" type="file" class="form-control material-design-primary" accept=".png, .jpg" (change)="onUpload($event)" required>
            </div> -->

            <!-- <div class="col-sm-12">
                <label>Fecha Alta </label>
                <input formControlName="fechaAlta" type="date" id="birthday" name="birthday">
            </div> -->

            <div class="col-sm-12">
                <input [disabled]="checkoutForm.invalid" type="submit" value="Guardar">

            </div>

        </div>
    </div>

</form>