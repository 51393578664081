import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';


@Component({
  selector: 'app-editar-departamentos',
  templateUrl: './editar-departamentos.component.html',
  styleUrls: ['./editar-departamentos.component.css']
})
export class EditarDepartamentosComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  checkoutForm;
  public torre: any;
  public departamento: any;
  public dataDepartment: { idTorre: string, nombreTorre: string, idUser: string, idDept: string, nombre: string };
  public dataDept: { idTorre: string, nombre: string };
  public idCondominio;
  public userFlutter = [];
  public dept = [];

  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }

  constructor(private rutaActiva: ActivatedRoute, private firestore: AngularFirestore, private firestoreService: FirestoreService, private calendar: NgbCalendar, private formBuilder: FormBuilder, private router: Router) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      status: '',
      fechaAlta: ''
    });
  }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.dataDepartment = {
      idTorre: this.rutaActiva.snapshot.params.idTorre,
      nombreTorre: this.rutaActiva.snapshot.params.nombreTorre,
      idUser: this.rutaActiva.snapshot.params.idUsuario,
      idDept: this.rutaActiva.snapshot.params.idDepartment,
      nombre: this.rutaActiva.snapshot.params.nombre,
    };

    this.getDept();
  }

  onSubmit(departamento) {
    // departamento.departamento = "69F";
    // departamento.hora = new Date("31/8/2020").getTime();
    // departamento.idCodominio = "3g55YOabc7lzgUt8GiPH";
    // departamento.torre = this.dataDepartment.nombreTorre
    // departamento.usuario = this.dataDepartment.idUser;
    departamento.status = true;
    departamento.fechaAlta = new Date();
    // this.firestoreService.createDepartmentFlutter(departamento).then(() => {
    // }, (error) => {
    //   console.error(error);
    // });
    // this.router.navigate(['/departamentos']);
  }

  onSubmitDept(users) {
    users.status = true;
    this.onSubmit(users);
    this.firestoreService.updateDept(this.dataDepartment.idTorre, this.dataDepartment.idDept, users).then(() => {
      this.router.navigate(['/departamento-tablas/' + this.dataDepartment.idTorre + '/' + this.dataDepartment.nombreTorre]);
    }, (error) => {
      console.error(error);
    });

  }

  getDept() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + this.dataDepartment.idTorre + '/Departamentos', ref =>
      ref.where('nombre', '==', this.dataDepartment.nombre))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));
    snapshotResult.subscribe(doc => {
      this.dept.push(
        doc.payload.doc.data(),
      );

      this.checkoutForm.controls['nombre'].setValue(this.dept[0].nombre);

    });
  }

}

