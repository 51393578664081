<div class="table100">
    <table id="customers">
        <tr>
            <th>Condominio</th>
            <th>Url</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr (click)="changeCondo(condo.key)" *ngFor="let condo of condos; index as i; first as isFirst">
            <td>{{condo.nombre}}</td>
            <!-- <td>{{condo.url}}</td> -->
            <img style="width:50px;height:50px;" src="{{condo.url}}">
            <td *ngIf="condo.status == true">Activo</td>
            <td *ngIf="condo.status == false">Inactivo</td>
            <td  class="align-icon">
                <i style="color: rgb(30, 207, 39);" *ngIf="condoChoose == condo.key" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
                <i *ngIf="condoChoose != condo.key" class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <br>
    <div class="col-sm-12">
        <button routerLink="/condos-form" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
    </div>
</div>