import { Injectable } from '@angular/core';
import { firestore } from 'firebase';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public serverDate: string = '';

  constructor(
    private http: HttpClient
  ) { 
    this.getServerDate();
  }

  /*
   ██████╗ ███████╗████████╗██████╗  █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║   ██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔══╝     ██║   ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝███████╗   ██║   ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION getDate
   */
  getDate(date): String{
    try{
      let dateFormated;

      if(moment(date).isValid()){
        dateFormated = moment(date).format("DD [de] MMM [de] YYYY");
      }

      else{
        // dateFormated = (date as firestore.Timestamp).toDate()
        // dateFormated = (date as Date).getUTCMilliseconds();
        // dateFormated = (date as Date).toLocaleDateString();
        dateFormated = moment((date as firestore.Timestamp).toDate()).format("DD [de] MMM [de] YYYY");
        // dateFormated = moment((date as number).toFixed(0)).format('YYYY-MM-DD');
      }

      return dateFormated;
    }

    catch(error){
      console.log(error);
      
      return date;
    }
  }

  /*
   ██████╗ ███████╗████████╗██████╗  █████╗ ███╗   ██╗██████╗  ██████╗ ███╗   ███╗████████╗██╗███╗   ███╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔══██╗██╔═══██╗████╗ ████║╚══██╔══╝██║████╗ ████║██╔════╝
  ██║  ███╗█████╗     ██║   ██████╔╝███████║██╔██╗ ██║██║  ██║██║   ██║██╔████╔██║   ██║   ██║██╔████╔██║█████╗
  ██║   ██║██╔══╝     ██║   ██╔══██╗██╔══██║██║╚██╗██║██║  ██║██║   ██║██║╚██╔╝██║   ██║   ██║██║╚██╔╝██║██╔══╝
  ╚██████╔╝███████╗   ██║   ██║  ██║██║  ██║██║ ╚████║██████╔╝╚██████╔╝██║ ╚═╝ ██║   ██║   ██║██║ ╚═╝ ██║███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝  ╚═════╝ ╚═╝     ╚═╝   ╚═╝   ╚═╝╚═╝     ╚═╝╚══════╝

  */
  /**
   * FUNCTION getRandomTime
   */
  getRandomTime(maxTime: number): number {
    return Math.ceil(Math.random() * (maxTime - 1000) + 1000);
  }

  async getServerDate() {
    
    try {
      let response = await this.http.get(
        `${environment.condoClick.urlApi}/dateTime`
      ).toPromise();
  
      console.log(response);

      let res = response as any;
  
      if(response) {

        console.log(moment(res.dateTime));
        this.serverDate = moment(res.dateTime).format();
        // this.serverDate = moment(res.dateTime).format('YYYY-DD-MM HH:mm:ss');
        console.log(this.serverDate);
        // utcOffset = Math.abs(utcOffset) / 60
        
      }
    } catch(error) {
      console.log(error);
      
    }
    
  }

  isValidTimestamp(ts) {
    const date = new Date(ts);
    return !(isNaN(date.getTime()));
  }
}
