import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../services/firestore/firestore.service';
import { map, flatMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { ModalReservacionComponent } from '../modal-reservacion/modal-reservacion.component';
import * as XLSX from 'xlsx';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reservaciones-pendientes',
  templateUrl: './reservaciones-pendientes.component.html',
  styleUrls: ['./reservaciones-pendientes.component.css']
})
export class ReservacionesPendientesComponent implements OnInit {
  public reservations = [];
  public idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public idArea = this.currentRoute.snapshot.params.idArea;
  pageSize: number = 20;
  page = 1;
  isEditar;
  isEliminar;
  isEscribir;
  templateToExcel: string[][] = [];
  data = [];
  public allS = [];
  public kindOfUser;
  public currentBook: any;

  constructor(private firestoreService: FirestoreService,
    private currentRoute: ActivatedRoute,
    private router: Router,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.isEditar = localStorage.getItem('isEditar');
    this.isEscribir = localStorage.getItem('isEscribir');
    this.isEliminar = localStorage.getItem('isEliminar');
    this.kindOfUser = localStorage.getItem('userOper');
    this.getReservations(this.currentRoute.snapshot.params.idArea);
  }

  getReservations(idArea) {

    let date = new Date();
    console.log(date);
    console.log(idArea);
    
    var query = this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones`, ref => 
    ref.where('idArea', '==', idArea)
    .where('fechaInicio', '>=', date)
    .orderBy('fechaInicio', 'asc')
    )
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      .subscribe(reser => {
        console.log("reserv",reser);
        this.reservations = reser;
      });

    console.log(this.reservations);

  }

  delete(key) {
    console.log(key);
    if (confirm("¿Desea borrar reservación?")) {
      this.firestoreService
        .deleteReservation(key)
        .catch(err => console.log(err));
    }
    else {

    }
  }

  cancel(key) {
    // console.log(key);
    if (confirm("¿Desea cancelar reservación?")) {
      this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones/`)
      .doc(`${key}`).update({
        status: false
      })
    }
    else {

    }
  }

  public excelHeaders: string[] = ["Servicio", "Departamento", "Torre", "Fecha de alta", "Estatus"];

  public arraryT = [];
  exportTemplateAsExcel() {
    for (var i = 0; i < this.reservations.length; i++) {
      if (i == 0) {
        this.templateToExcel.push(
          this.excelHeaders
        )
      }

      this.data = [
        this.reservations[i].servicio,
        this.reservations[i].departamento,
        this.reservations[i].torre,
        new Date(this.allS[i].fechaAlta.seconds * 1000),
        this.reservations[i].status == 0 ? 'Pendientes' : 'Concluidos'
      ]

      this.templateToExcel.push(
        this.data
      )

    }
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Servicios');
    XLSX.writeFile(wb, "test" + ".xlsx");
  }

  showDetails(reservacion, content){
    // console.log(reservacion);
    this.currentBook = reservacion;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

}
