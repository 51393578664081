import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: AngularFireStorage,
  ) { }

  uploadImage(
    data: {
      filePath: string,
      file: any
    },
    callBack: Function
  ){
    let ref = this.storage.ref(data.filePath);
    let task = this.storage.upload(data.filePath, data.file);
    task.snapshotChanges()
        .pipe(
            finalize(() => {
              let downloadURL = ref.getDownloadURL();
              downloadURL.subscribe( async downloadURLResponse => {

                callBack(downloadURLResponse);

                // await docFlat.update({
                //   'url': downloadURLResponse,
                //   'filePath': filePath
                // });

                // this.loading = false;

                // this.router.navigate(['/torres']);
              });
            }),
          )
        .subscribe();

  }
}
