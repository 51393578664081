import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-boards',
  templateUrl: './boards.component.html',
  styleUrls: ['./boards.component.css']
})
export class BoardsComponent implements OnInit {

  public boards: any = [];
  public loading: boolean = true;
  public idCondominio;
  public user: any = localStorage.getItem('logIn') != '' ? JSON.parse(localStorage.getItem('logIn')) : '';

  constructor(
    private firestore: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');

    this.getboards();
  }

  getboards(){
    this.firestore.collection(`Condominios/${this.idCondominio}/Pizarra`, ref => 
      ref.orderBy('fechaAlta', 'desc')
    )
    .snapshotChanges()
    .subscribe((snapshots) => {
      this.boards = [];
      snapshots.forEach((element: any) => {
        this.boards.push({
          id: element.payload.doc.id, ...element.payload.doc.data()
        });
      });

      
    })
  }

  delete(idPizarra){
    
    if (confirm("¿Desea quitar publicación?")) {
      this.firestore.collection(`/Condominios/${this.idCondominio}/Pizarra/`)
      .doc(`${idPizarra}`).delete();

      this.firestore.collection(`/Condominios/${this.idCondominio}/Logs`)
      .add({user: this.user.uid, action: "delete", where: "Pizarra", date: Date.now()});
      
    }
    // else {

    // }
  }

}
