import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseAuthService } from '../../services/firebase/firebase-auth.service';
import { environment } from '../../../environments/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { flatMap } from 'rxjs/operators';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public user = [];
  public existsEmail;
  public isAlert = false;
  public showAlert = false;

  loginForm = new FormGroup({
    email: new FormControl('')
  });


  constructor(private authSvc: FirebaseAuthService, private router: Router, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.existsEmail = false;
  }


  async onRegister() {
    try {
      const { email } = this.loginForm.value;
      this.authSvc.resetPassword(email)
      this.isAlert = true;
    } catch (error) {
      console.log(error);
    }
  }

  async onCheckRegister() {
    try {
      const { email, password } = this.loginForm.value;
      const snapshotResult = this.firestore.collection('/Administradores', ref =>
        ref.where('email', '==', email))
        .snapshotChanges()
        .pipe(flatMap(clients => clients));
      snapshotResult.subscribe(doc => {
        this.existsEmail = doc.payload.doc.exists;
        this.user.push(
          doc.payload.doc.data(),
        );
      });
    } catch (error) {
      console.log(error);
    }
  }


  // async onLogin() {
  //   // const { email, password } = this.loginForm.value;
  //   try {
  //     const user = await this.authSvc.login(email, password);
  //     if (user) {
  //      console.log(user);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }


  public forgotPass(email) {
    try {
      this.authSvc.resetPassword(email)
    } catch (error) {
      console.log(error);
    }
  }

}
