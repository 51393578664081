<div class="limiter">
    <div class="container-table100">
        <div class="wrap-table100">
            <div class="table100">
                <table id="customers">
                    <tr>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Fecha Alta</th>
                        <th>Id de Usuario</th>
                        <th>Torre</th>
                        <th>Departamento</th>
                        <th>Activar</th>
                        <th>Rechazar</th>

                    </tr>
                    <tr *ngFor="let user of users; index as i; first as isFirst">
                        <td>{{user.nombre}} {{user.apellido}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.fechaAlta | date:'dd/MM/yyyy'}}</td>
                        <td>{{user.uid}}</td>
                        <td>{{user.torre}}</td>
                        <td>{{user.departamento}}</td>
                        <td> 
                            <i (click)="activateUser(user.key, users[i])" class="fa fa-check-circle" aria-hidden="true"></i>
                        </td>
                        <td>
                            <i (click)="deleteArea(user.key)" class="fa fa-trash" aria-hidden="true"></i>
                        </td>
                    </tr>

                </table>
                <br>
                <!-- <div class="col-sm-12">
                    <button routerLink="/usuarios-form" class="button">
                        Nuevo
                        <div class="button__horizontal"></div>
                        <div class="button__vertical"></div>
                    </button>
                     <i routerLink="/areas-form" class="fa fa-plus-square fa-2x"></i>
                </div> -->
            </div>
        </div>
    </div>
</div>