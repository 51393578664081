import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class SecurityService {

    constructor(
        private firestore: AngularFirestore,
    ) {

    }

    createSecurityArea(idCondominium: String, data: any){
        return this.firestore.collection(`/Condominios/${idCondominium}/AreasSeguridad`).add(data);
    }

    createSecurityFlat(idCondominium: String, data: any){
        return this.firestore.collection(`/Condominios/${idCondominium}/DepartamentosSeguridad`).add(data);
    }

    getSecurityAreas(idCondominium: String): AngularFirestoreCollection<any[]>{
        return this.firestore.collection(`/Condominios/${idCondominium}/AreasSeguridad`);
    }

    getSecurityFlats(idCondominium: String): AngularFirestoreCollection<any[]> {
        return this.firestore.collection(`/Condominios/${idCondominium}/DepartamentosSeguridad`);
    }

}