import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { convertCompilerOptionsFromJson } from 'typescript';

@Component({
  selector: 'app-condominios',
  templateUrl: './condominios.component.html',
  styleUrls: ['./condominios.component.css']
})
export class CondominiosComponent implements OnInit {

  public condos = [];
  public users = [];
  public towers = [];
  public isCondo;
  public condominioOn;
  public checkoutForm;
  public showAlert = false;
  public deleteId;

  constructor(
    private firestoreService: FirestoreService, 
    private firestore: AngularFirestore, 
    private formBuilder: FormBuilder,
    private storage: AngularFireStorage, 
  ) {
    this.checkoutForm = this.formBuilder.group({
      fechaCad: ['', [Validators.required]],
      test: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getCondos();
  }

  getCondos() {
    const snapshotResult = this.firestore.collection('/Condominios', ref =>
      ref.orderBy('fechaCad', 'asc'))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      this.condos = reser;
    });
  }

  async delete(key) {

    // this.firestoreService
    //   .deleteCondos(key)
    //   .catch(err => console.log(err));

    let doc = await this.firestore.collection('/Condominios').doc(key)
    .get().toPromise();

    console.log(doc.data());

    if(doc.data().filePath != null){
      let refImg = this.storage.ref(doc.data().filePath);
      await refImg.delete().toPromise();
    }

    await doc.ref.delete();

    document.getElementById('id03').style.display='none';
    
  }

  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateCondos(key, data)
      // .catch(err => console.log(err));
  }

  getUsers(idCondo) {
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('idTipo', '==', 2).where('idCondominio', '==', idCondo))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      this.users = reser;
    });
  }

  deleteAdmin(key, email) {
    this.firestoreService
      .deleteAdminRoot(key)
      .catch(err => console.log(err));
    // this.getAdmin(email);
  }

  updateAdmin(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateAdmin(key, data)
      .catch(err => console.log(err));
  }

  getTowersList() {
    this.firestoreService.getTowersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  condoKey(key) {
    this.isCondo = true;
  }

  getAdmin(email) {
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));

    snapshotResult.subscribe(doc => {
      this.firestoreService
        .deleteAdminRoot(doc.payload.doc.id)
        .catch(err => console.log(err));
    });

  }

  setCondo(data) {
    this.condominioOn = data;
  }

  onSubmit(data) {
    this.condominioOn.fechaCad = moment(data.fechaCad).toDate();
    if (data.test == "true")
      this.condominioOn.test = true;
    else
      this.condominioOn.test = false;

    this.firestoreService
      .updateCondos(this.condominioOn.id, this.condominioOn)
      // .catch(err => console.log(err));

    this.showAlert = true;
  }

  setDeleteId(id) {
    this.deleteId = id;
  }
}
