import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  QuerySnapshot, 
  DocumentChangeAction, 
  DocumentReference 
} from '@angular/fire/firestore';
import { map, flatMap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalle-area-seguridad',
  templateUrl: './detalle-area-seguridad.component.html',
  styleUrls: ['./detalle-area-seguridad.component.css']
})
export class DetalleAreaSeguridadComponent implements OnInit {

  public area: any;
  public idArea = this.currentRoute.snapshot.params.idArea;
  public idCondominium: String = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public loading: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private currentRoute: ActivatedRoute,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
  ) { }

  getDataArea(){
    this.firestore.collection(`/Condominios/${this.idCondominium}/AreasSeguridad`)
    .doc(this.idArea).snapshotChanges()
    .subscribe((area) => {
      this.area = {
        ref: area.payload.ref,
        id: area.payload.id,
        ...area.payload.data() as DocumentChangeAction<any>,
      };
      console.log(this.area);
      
    })
  }

  ngOnInit(): void {
    this.getDataArea();
  }

}
