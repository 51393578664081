import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { map, flatMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ModalDescargarReporteComponent } from './modal-descargar-reporte/modal-descargar-reporte.component';

@Component({
  selector: 'app-visitas',
  templateUrl: './visitas.component.html',
  styleUrls: ['./visitas.component.css']
})
export class VisitasComponent implements OnInit {

  public idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public auxVisitors: Array<any>;
  public soonVisitors: Array<any> = [];
  public historyVisitors: Array<any> = [];
  public loading = true;
  public history = false;

  constructor(
    private firestore: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.getVisits();
  }
  
  getVisits(){
    this.firestore.collection(`/Condominios/${this.idCondominio}/Visitantes`)
    .snapshotChanges()
    .pipe(map(docs =>
      docs.map(
        c => ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
      )
    ))
    .subscribe(visitors => {
      this.soonVisitors = [];
      this.historyVisitors = [];
      console.log("all",visitors);
      this.auxVisitors = visitors;

      this.auxVisitors.forEach((visitor, index) => {
        if(visitor.status){

          let timeA = moment(moment.now());
          let timeB = moment(visitor.dateCreated.toDate());

          // console.log(moment(moment.now()).format());
          // console.log(moment(visitor.dateCreated.toDate()).format());
          
          // console.log(timeA.diff(timeB, 'days') > 2);
          

          if(timeA.diff(timeB, 'days') > 10){
            this.historyVisitors.push(visitor);
          }

          else{
            this.soonVisitors.push(visitor);
          }
        }

        else{
          this.historyVisitors.push(visitor);
        }

        this.soonVisitors.sort((a,b) => {
          return b.dateCreated - a.dateCreated;
        });

        this.historyVisitors.sort((a,b) => {
          return b.dateCreated - a.dateCreated;
        });

        this.loading = false;
      });
    });
  }

  openModalDownloadReport(){
    // this.modalService.open(ModalDescargarReporteComponent, {
    //   // size: 'lg'
    // }).result.then((result) => {
    //   console.log(result);
    //   // this.getProducts();
    // })
    // .catch((error) => {
    //   console.log(error);
    //   // this.getProducts();
    // });
  }

}
