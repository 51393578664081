import { AfterViewInit, Component } from '@angular/core';
import { environment } from '../environments/environment';
import { FirebaseAuthService } from './services/firebase/firebase-auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginComponent } from './admin/login/login.component';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { flatMap, map, mergeMapTo } from 'rxjs/operators';
import * as moment from 'moment';
import { FirestoreService } from '../app/services/firestore/firestore.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'adminCondominios';
  loged: any = localStorage.getItem('logIn') != '' ? JSON.parse(localStorage.getItem('logIn')) : '';
  isLogIn;
  isRegister = false;
  isForgot = false;
  public isServicio;
  public isSeguridad;
  public isReservacion;
  public isCollapsed = false;
  public idCondominio;
  condominio: any;
  dueDate: any;
  public condominioData: any = [];
  public statusCondo;
  public fechaExp;
  public admin;
  public days = 0;
  public show;
  public countServices;
  public data;
  public countReservations;
  public isLoged = false
  public services = [];
  public email;
  public userData = [];
  public torresKey = [];
  public towers = [];
  public currentItem = 0;

  public tokenNotificacion: string;
  public userCurrent: any;

  public isCollapsedSecurity = true;
  public isCollapsedTest = true;

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  register = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  forgot = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  forgotForm = new FormGroup({
    userEmail: new FormControl(''),
  });

  constructor(
    private authSvc: FirebaseAuthService,
    private firebaseAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private firestoreService: FirestoreService,
    private angularFireMessaging: AngularFireMessaging,
    private router: Router
  ) { 

    // this.angularFireMessaging.messaging.subscribe(
    //   (msgings) => {
    // msgings.onMessage = msgings.onMessage.bind(msgings);
    // msgings.onTokenRefresh=msgings.onTokenRefresh.bind(msgings);
    //  })
  }

  ngOnInit(): void {
    firebase.analytics();
    this.admin = localStorage.getItem('userOper');
    this.isServicio = localStorage.getItem('isServicio');
    this.isSeguridad = localStorage.getItem('isSeguridad');
    this.isReservacion = localStorage.getItem('isReservacion');
    this.idCondominio = localStorage.getItem('condominio');
    this.email = JSON.parse(localStorage.getItem('email'));

    // this.angularFireMessaging.deleteToken(localStorage.getItem('nToken')).subscribe((
    //   data
    // ) => { console.log("delete", data); })

    // this.firebaseAuth.currentUser.then((user) => {
    //   console.log('user current');
    //   console.log(user);
    // });

    this.angularFireMessaging.tokenChanges.subscribe((newToken) => {
      console.log("token refresh", newToken)
    })

    this.angularFireMessaging.getToken.subscribe(async (newToken) => {
      // console.log("token get", newToken)
      this.tokenNotificacion = newToken;
      console.log("token tokenNotificacion", this.tokenNotificacion);

      this.saveToken(newToken);
      
    })

    this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
      .subscribe(
        (token) => { localStorage.setItem('nToken', token); },
        (error) => { console.error(error); },
      );


    this.countServices = 0;
    if (localStorage.getItem("logIn"))
      this.isLoged = true;
    else
      this.isLoged = false;

    if (this.admin == '3' || this.admin == '2') {
      // console.log("OPERTAIVO");
      this.getInfoCondominium();

    }


    if (this.admin == '3') {
      this.towersUser(this.email);
      this.getServices();
    }

    this.getDataCondos(this.idCondominio);

    // this.firestoreService.requestPermissions();
    // this.firestoreService.receiveMessage();
    // this.show = this.firestoreService.currentMessage;

    this.receiveMessage();
    this.countServices = 0;
    this.countReservations = 0;

  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (msg) => {
        this.data = msg;
        if (this.data.notification.title == "clear") {
          this.currentItem = 1;
          console.log("clear")
        }

        // this.angularFireMessaging.deleteToken();


        // if (this.data.notification.title == "Servicios")
        //   this.countServices = (this.countServices + 1);
        // else
        //   this.countReservations = (this.countReservations + 1);
        // console.log("show message!", this.data.notification.title);
      })
  }


  getInfoCondominium() {
    let snapshotResult = this.firestore.doc('/Condominios/' + this.idCondominio)
      .snapshotChanges()
      .pipe(map(docs =>
        ({ id: docs.payload.id, ...docs.payload.data() as DocumentChangeAction<any> })
      ))
      .subscribe(condo => {
        // console.log("condominio", condo);
        this.condominio = condo;
      });
  }

  logOut() {
    this.firebaseAuth.signOut().then(() => {
      this.isLogIn = false;
      this.admin = localStorage.clear;
      localStorage.setItem('userOper', "4");
      localStorage.setItem('logIn', '');
      window.location.reload();
    });
    
  }

  saveToken(token: string){
    this.firestore.collection(`/Administradores`, ref => 
      ref.where('email', '==', this.email)
    )
    .get()
    .subscribe((data) => {
      // console.log('email ff', this.email);
      // console.log(data.docs);
      if(data.docs.length > 0){
        // console.log(data.docs[0].data()['email']);

        let tokens = data.docs[0].data()['tokenNotifications'] != null ? data.docs[0].data()['tokenNotifications'] : [];
        console.log(tokens);

        if(tokens.length > 0){

          let flag = false;

          tokens.forEach(token => {
            if(token.includes(this.tokenNotificacion)){
              flag = true;
            }
          });

          if(!flag){
            tokens.push(this.tokenNotificacion);

            this.firestore.doc(`/Administradores/${data.docs[0].id}`).update({
              'tokenNotifications': tokens
            })
          }
        }

        else{
          tokens.push(this.tokenNotificacion);

          this.firestore.doc(`/Administradores/${data.docs[0].id}`).update({
            'tokenNotifications': tokens
          })
        }
      }
      
    })
  }

  async onGoogleLogin() {
    try {
      const user = await this.authSvc.loginGoogle();
      if (user) {
        localStorage.setItem('logIn', JSON.stringify(user));
        environment.logged.isLogedIn = true;
        this.isLogIn = this.loged.emailVerified;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onFaceBookLogin() {
    try {
      const user = await this.authSvc.loginFace();
      if (user) {
        localStorage.setItem('logIn', JSON.stringify(user));
        environment.logged.isLogedIn = true;
        this.isLogIn = this.loged.emailVerified;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onLogin() {
    const { email, password } = this.loginForm.value;
    try {
      const user = await this.authSvc.login(email, password);
      if (user) {
        console.log(user);
        localStorage.setItem('logIn', JSON.stringify(user));
        environment.logged.isLogedIn = true;
        this.isLogIn = this.loged.emailVerified;

      }
    } catch (error) {
      console.log(error);
    }
  }

  async onRegister() {
    const { email, password } = this.register.value;
    try {
      const user = await this.authSvc.register(email, password);
      if (user) {
        this.isRegister = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  showRegister() {
    this.isRegister = true;
  }

  onSendEmail(): void {
    this.authSvc.sendVerificationEmail();
  }

  ngOnDestroy() {
    this.authSvc.logout();
  }

  async onReset() {
    try {
      const { userEmail } = this.forgotForm.value;
      await this.authSvc.resetPassword(userEmail);
      window.alert('Email sent, check your inbox!');
      // this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }

  showForgot() {
    this.isForgot = true;
  }

  getDataCondos(idCondominio) {
    this.firestoreService.getCondominio(idCondominio).get().subscribe((condo) => {
      if (condo.exists) {
        this.condominioData.push({
          nombre: condo.data()['nombre'],
          status: condo.data()['status'],
          url: condo.data()['url'],
          fechaCad: condo.data()['fechaCad'],
          calle: condo.data()['calle'],
          ciudad: condo.data()['ciudad'],
          codigo: condo.data()['codigo'],
          colonia: condo.data()['colonia'],
          estado: condo.data()['estado'],
          imagen: condo.data()['imagen'],
          test: condo.data()['test'],
        });
        this.statusCondo = this.condominioData[0].status;
        this.fechaExp = this.condominioData[0].fechaCad;
        var today = new Date();
        this.days = moment(this.fechaExp.toDate()).diff(moment(), 'days');
        if (this.days < 0) {
          this.condominioData[0].status = false;
          this.firestoreService
            .updateCondos(idCondominio, this.condominioData[0])
            // .catch(err => console.log(err));
        }
      } else {
        console.log("El condominio asociado a este admin no existe");
      }


    });

    if (this.admin == '1') {
      this.statusCondo = true;
    }

  }

  getServices() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Solicitudes', ref =>
      ref.where('status', '==', 0).orderBy('fechaAlta', 'desc').limit(1))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      this.services = reser;
      for (var i = 0; i <= this.torresKey.length; i++) {
        if (this.torresKey[i] == this.services[0].idTorre) {
          this.countServices = (this.countServices + 1);
          this.currentItem = ++this.currentItem;
          this.clearCount();
        }
      }
    });

  }

  towersUser(email) {
    email = this.email.replace(/['"]+/g, '');
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));
    snapshotResult.subscribe(doc => {
      this.userData.push(
        doc.payload.doc.data()
      );
      this.torresKey = this.userData[0].torresKey;
      for (var i = 0; i <= this.torresKey.length; i++) {
        if (this.torresKey[i] != null) {
          this.firestoreService.getTowerServices(this.torresKey[i]).subscribe((user) => {
            this.towers.push({
              key: user.payload.id,
              // fechaAlta: user.payload.data()['fechaAlta'],
              // nombre: user.payload.data()['nombre'],
              // status: user.payload.data()['status'],
              // url: user.payload.data()['url'],
            });
          });
        }
      }
    });

  }

  goServices() {
    this.countServices = 1;
    this.router.navigate(['/gestionServicios']);
  }

  clearCount() {
    console.log("hw");
    let json = {
      "notification": {
        "title": "Nuevo Servicio",
        "body": "Han solicitado un nuevo servicio",
        "icon": "https://firebasestorage.googleapis.com/v0/b/condb-b725c.appspot.com/o/logo%2Fcondoclick.jpeg?alt=media&token=35a27bfc-576e-4363-bd5c-bf1ab4209074"
      },
      "to": localStorage.getItem('nToken').replace(/['"]+/g, '')
    }
    // obj.notification = this.notification;
    // obj.to = "c5IKx8xOtG2jT83SJ-EWXC:APA91bHy2zztpue9NnamsmJQCefBfNeBOn5dyd1fPaFUFNEPwFHNaIstWZETv2nUb60PpmJUvlgvGgWoOntyTF1LYNVGqfYNENBJoik0lKvwrTeG_Wtyt0iiUmtLdhD583IMYv5Y5O_R";

    this.authSvc.sendNotification(json).subscribe(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );
  }

}

