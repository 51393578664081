import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  public api = environment.copomex;
  public genericHeaders: HttpHeaders = new HttpHeaders({ 
    'Content-Type': 'application/json;charset=UTF-8',
    'Charset': 'utf-8',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

  constructor(
    private http: HttpClient
  ) { }

  getNeighborhoods(zip): Observable<any> {

    // `https://api.copomex.com/query/info_cp/${zip}?token=f872f772-c463-462c-ba73-b1a1dab3b6b3`,

    return this.http.get(
      `${this.api.urlApi}/query/info_cp/${zip}?token=${this.api.token}`,
    );
  }
}
