<div class="modal-header">
    <h1 class="modal-title">Crear reporte</h1>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form id="createReport" [formGroup]="formReport" (ngSubmit)="onSubmit()">
        <div class="row">

            <!-- <div class="form-group col-12">
                <label style="font-size: 0.6rem;" for="txtArea">Departamento</label>
                <select id="txtArea" class="custom-select" formControlName="category" [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                    <option [ngValue]="undefined" selected>Seleccionar departamento</option>
                    <option [ngValue]="department.idDepartment" *ngFor="let department of departments">{{department.department}}</option>
                </select>
                <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                    <div *ngIf="f.category.errors.required">Por favor, seleccione departamento</div>
                </div>
            </div>                 -->

            <div class="form-group col-12">
                <label style="font-size: 0.6rem;" for="txtArea">Tipo de reporte</label>
                <select class="custom-select" placeholder="Seleccionar tipo de reporte" (change)="checkType($event)" formControlName="type" [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                    <option value="0" disabled selected>Seleccionar tipo de reporte</option>
                    <option value="1">Área</option>
                    <option value="2">Departamento</option>
                </select>
                <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                    <div *ngIf="f.type.errors.required">Por favor, seleccione tipo de reporte</div>
                </div>
            </div>

             <div class="form-group col-12" *ngIf="typeChanged && type == 1 && step >= 1">
                <label style="font-size: 0.6rem;" for="txtArea">Áreas comunes</label>
                <!-- <select (change)="setAreaSelected($event)"  id="txtArea" class="custom-select" formControlName="area" [ngClass]="{ 'is-invalid': submitted && f.area.errors }">
                    <option [ngValue]="undefined" selected>Seleccionar área común</option>
                    <option [ngValue]="area" *ngFor="let area of areas; index as i">{{area.name}}</option>
                </select> -->
                <ng-select
                    placeholder="Seleccionar área" 
                    clearAllText="Clear" 
                    formControlName="area"
                    (change)="setAreaSelected($event)"
                    labelForId="txtArea"
                    [ngClass]="{ 'is-invalid': submitted && f.area.errors }"
                >
                    <ng-option *ngFor="let area of areas" [value]="area">
                        {{area.name}}
                    </ng-option>
                </ng-select>

                <div *ngIf="submitted && f.area.errors" class="invalid-feedback">
                    <div *ngIf="f.area.errors.required">Por favor, seleccione área común</div>
                </div>
            </div>

            <div class="form-group col-12" *ngIf="typeChanged && type == 2 && step >= 1">
                <label style="font-size: 0.6rem;" for="txtBulding">Torres</label>
                <!-- <select (change)="getFlats()" id="txtBulding" class="custom-select" formControlName="building" [ngClass]="{ 'is-invalid': submitted && f.building.errors }">
                    <option [ngValue]="undefined" selected>Seleccionar torre</option>
                    <option [ngValue]="building" *ngFor="let building of buildings; index as i">{{building.nombre}}</option>
                </select> -->
                <ng-select
                    placeholder="Seleccionar torre" 
                    clearAllText="Clear" 
                    formControlName="building"
                    (change)="getFlats()"
                    labelForId="txtBulding"
                    [ngClass]="{ 'is-invalid': submitted && f.building.errors }"
                >
                    <ng-option *ngFor="let building of buildings" [value]="building">
                        {{building.nombre}}
                    </ng-option>
                </ng-select>
                <div *ngIf="submitted && f.building.errors" class="invalid-feedback">
                    <div *ngIf="f.building.errors.required">Por favor, seleccione torre</div>
                </div>
            </div>

            <div class="form-group col-12" *ngIf="buildingSelected && type == 2 && step >= 1">
                <label style="font-size: 0.6rem;" for="txtFlat">Departamento</label>
                <!-- <select id="txtFlat" class="custom-select" formControlName="flat" [ngClass]="{ 'is-invalid': submitted && f.flat.errors }">
                    <option [ngValue]="undefined" selected>Seleccionar departamento</option>
                    <option [ngValue]="flat" *ngFor="let flat of flats; index as i">{{flat.nombre}}</option>
                </select> -->
                <ng-select
                    placeholder="Seleccionar departamento" 
                    clearAllText="Clear" 
                    formControlName="flat"
                    (change)="setAreaSelected($event)"
                    labelForId="txtFlat"
                    [ngClass]="{ 'is-invalid': submitted && f.flat.errors }"
                >
                    <ng-option *ngFor="let flat of flats" [value]="flat">
                        {{flat.nombre}}
                    </ng-option>
                </ng-select>
                <div *ngIf="submitted && f.flat.errors" class="invalid-feedback">
                    <div *ngIf="f.flat.errors.required">Por favor, seleccione departamento</div>
                </div>
            </div>
            
            <div class="form-group col-12" *ngIf="typeChanged && type == 1 && step >= 2">
                <label style="font-size: 0.6rem;" for="txtService">Servicios</label>
                <!-- <select id="txtService" class="custom-select" formControlName="service" [ngClass]="{ 'is-invalid': submitted && f.service.errors }">
                    <option [ngValue]="undefined" selected>Seleccionar servicio</option>
                    <option [ngValue]="service" *ngFor="let service of formReport.value.area.services; index as i">{{service.name}}</option>
                </select> -->
                <ng-select
                    placeholder="Seleccionar servicio" 
                    clearAllText="Clear" 
                    formControlName="service"
                    labelForId="txtService"
                    [ngClass]="{ 'is-invalid': submitted && f.service.errors }"
                >
                    <ng-option *ngFor="let service of formReport.value.area.services" [value]="service">
                        {{service.name}}
                    </ng-option>
                </ng-select>
                <div *ngIf="submitted && f.service.errors" class="invalid-feedback">
                    <div *ngIf="f.service.errors.required">Por favor, seleccione servicio</div>
                </div>
            </div>

            <div class="form-group col-12" *ngIf="typeChanged && type == 2 && step >= 2">
                <label style="font-size: 0.6rem;" for="txtService">Servicios</label>
                <!-- <select id="txtService" class="custom-select" formControlName="service" [ngClass]="{ 'is-invalid': submitted && f.service.errors }">
                    <option [ngValue]="undefined" selected>Seleccionar servicio</option>
                    <option [ngValue]="service" *ngFor="let service of formReport.value.area.services; index as i">{{service.name}}</option>
                </select> -->
                <ng-select
                    placeholder="Seleccionar servicio" 
                    clearAllText="Clear" 
                    formControlName="service"
                    labelForId="txtService"
                    [ngClass]="{ 'is-invalid': submitted && f.service.errors }"
                >
                    <ng-option *ngFor="let service of services" [value]="service">
                        {{service.name}}
                    </ng-option>
                </ng-select>
                <div *ngIf="submitted && f.service.errors" class="invalid-feedback">
                    <div *ngIf="f.service.errors.required">Por favor, seleccione servicio</div>
                </div>
            </div>

            <div class="col-12 form-group mb-4" *ngIf="typeChanged && step >= 1">
                <label for="txtComment" style="font-size: 0.6rem;">Descripción</label>
                <textarea class="form-control material-design-primary" formControlName="comment" id="txtComment" rows="3"></textarea>
            </div>

        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger col-12" (click)="activeModal.close('Close click')">Cancelar</button>
    <button type="submit" form="createReport" class="btn btn-success col-12">Crear</button>
</div>