// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  condoClick: {
    urlApi: 'https://us-central1-condb-b725c.cloudfunctions.net/mailer'
  },
  copomex: {
    urlApi: 'https://api.copomex.com',
    token: 'f872f772-c463-462c-ba73-b1a1dab3b6b3'
  },
  firebaseConfig: {
    apiKey: "AIzaSyAj0ulT8wggZkKHgzesQV7HWy8HVfa9oLw",
    authDomain: "condb-b725c.firebaseapp.com",
    databaseURL: "https://condb-b725c.firebaseio.com",
    projectId: "condb-b725c",
    storageBucket: "condb-b725c.appspot.com",
    messagingSenderId: "225392556081",
    appId: "1:225392556081:web:fcd16280ba6133469efd10",
    measurementId: "G-ZLEYKFXRG0"
  },
  logged: {
    isLogedIn: false,
  },
  rutes: {
    sendMail: "https://us-central1-condb-b725c.cloudfunctions.net/"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
