import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { FirebaseAuthService } from '../../services/firebase/firebase-auth.service';

@Component({
  selector: 'app-agregar-admin',
  templateUrl: './agregar-admin.component.html',
  styleUrls: ['./agregar-admin.component.css']
})
export class AgregarAdminComponent implements OnInit {

  public condos = [];
  checkoutForm;
  public downloadURL;
  public urlImagen: string;
  public nameCondo;
  public condominio = [];
  public towers = [];
  public towerKeys = [];
  public dataMail;

  constructor(private firestoreService: FirestoreService, private router: Router, private formBuilder: FormBuilder, private storage: AngularFireStorage, private firebaseAuth: FirebaseAuthService) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]],
      idCondominio: ['', [Validators.required]],
      torresKey: [],
      torre: '',
    });
  }

  ngOnInit(): void {
    this.getCondos();
    // this.getTowersList();
    this.firestoreService.query();
  }

  selectedFileFrontal = null;

  onSubmit(form) {
    form.descripcion = "Administrador";
    form.status = true;
    form.idTipo = 2;
    form.isReservacion = true;
    form.isServicio = true;
    form.isEscribir = true;
    form.isEditar = true;
    form.isEliminar = true;
    form.torresKey = this.towerKeys;
    this.firestoreService.createAdminRoot(form).then(() => {

      this.dataMail = {
        to: form.email,
        message: "Buenas tardes, bienvenido a condoclick, usted ha sido dado de alta por un super administrador, favor de crear una cuenta, para que pueda tener acceso.",
        subject: "condoclick.com.mx",
        html:
          "<head>" +
          "</head>" +
          "<html>" +
          "<body>" +
          "<h2>Condoclick</h2>" +
          "<p>Buenas tardes, bienvenido a condoclick, usted ha sido dado de alta por un super administrador, favor de crear una cuenta, para que pueda tener acceso.<p>" +
          "<a href='condoclick.com.mx/registro'>Debe dar click aqui.</a>" +
          "</body>" +
          "</html>",

        // "<img style='with:50%' src='https://firebasestorage.googleapis.com/v0/b/condb-b725c.appspot.com/o/logo%2Fcondoclick.jpeg?alt=media&token=35a27bfc-576e-4363-bd5c-bf1ab4209074'>" +
      }

      this.sendMail(this.dataMail);
      this.router.navigate(['/condominios']);

    }, (error) => {
      console.error(error);
    });
    this.router.navigate(['/condominios']);
  }

  getCondos() {
    this.firestoreService.getCondosList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(condos => {
      this.condos = condos;
    });
  }

  getCondo(key) {
    this.firestoreService.getCondominio(key).get().subscribe((condo) => {
      this.condominio.push({
        nombre: condo.data()['nombre'],
        status: condo.data()['status'],
        url: condo.data()['url']
      });
    });
  }

  getTowersList() {
    this.firestoreService.getTowersAdmin('').snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  torres(tower, isNew) {
    if (isNew) {
      this.towerKeys.push(
        tower
      )
    } else {
      var indice = this.towerKeys.indexOf(tower);
      this.towerKeys.splice(indice, 1);
    }
  }

  sendMail(data) {
    this.firebaseAuth.sendMail(data).subscribe(
      response => {
      },
      error => {
        console.log(error);
      }
    );
  }

  condoKey(data) {
    this.firestoreService.getTowersAdmin(data.idCondominio).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

}
