<ul ngbNav #nav="ngbNav" class="nav nav-pills mb-3 tab-material" id="pills-tab" role="tablist">
    <li [ngbNavItem]="1">
      <a ngbNavLink class="btn pills-tab bg-white text-black">Operativos</a>
        <ng-template ngbNavContent>
            <app-usuarios-operativos></app-usuarios-operativos>
        </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink class="btn pills-tab bg-white text-black">Visitas</a>
      <ng-template ngbNavContent>
        <app-usuario-vigilante></app-usuario-vigilante>
      </ng-template>
    </li>
  </ul>
  
  <div [ngbNavOutlet]="nav" class="mt-4"></div>