<div class="modal-header">
    <h1 class="modal-title">Editar vigilante</h1>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="formWatcher" (ngSubmit)="onSubmit()" id="createReport">
        <div class="form-row">

            <div class="form-group col-12 mb-5">
                <label>Nombre del vigilante</label>
                <input type="text" formControlName="nombre" class="form-control material-design-primary" placeholder="Nombre del vigilante" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">Por favor, introduzca nombre completo</div>
                </div>
            </div>

            <div class="form-group col-12 mb-5">
                <label>Correo electrónico</label>
                <input type="text" formControlName="email" class="form-control material-design-primary" placeholder="Correo Electrónico" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Por favor, introduzca correo electrónico</div>
                </div>
            </div>
            <!-- <div class="form-group col-12 mb-4 mt-3">
                <label for="txtName">Nombre del servicio</label>
                <input id="txtName" class="form-control material-design-primary" formControlName="name" placeholder="Nombre del área" name="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Por favor, introduzca nombre del servicio</div>
                </div>
            </div>

            <div class="col-12 form-group mb-3">
                <label>Icono</label>
                <ng-select [items]="icons" [searchable]="false" [clearable]="true" (change)="setIcon($event)" [ngClass]="{ 'is-invalid': submitted && iconSelected == '' }" >
                    <ng-template ng-label-tmp let-item="item">
                        <span class="mx-1">{{item.name}}</span>
                        <img src="{{item.image}}" width="20px" height="20px"/>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <img src="{{item.image}}" width="20px" height="20px"/>
                        <span class="mx-1">{{item.name}}</span>
                    </ng-template>
                </ng-select>
                <div *ngIf="submitted && iconSelected == ''" class="invalid-feedback">
                    <div *ngIf="iconSelected == ''">Por favor, seleccione un icono</div>
                </div>
            </div> -->

        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger col-12" (click)="activeModal.close('Close click')">Cancelar</button>
    <button type="submit" form="createReport" class="btn btn-success col-12">Guardar</button>
</div>