<div class="modal-header">
    <h1 class="modal-title">Reporte {{report.id}}</h1>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="row">
                    <div class="col-12">
                        <small class="text-secondary">Servicio</small>
                    </div>
                    <div class="col-12">
                        <h2>{{report.servicio}}</h2>
                    </div>
                </div>

                <div class="row" *ngIf="!report.isArea">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <small class="text-secondary">Torre</small>
                            </div>
                            <div class="col-12">
                                <h2>{{report.torre}}</h2>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <small class="text-secondary">Departamento</small>
                            </div>
                            <div class="col-12">
                                <h2>{{report.departamento}}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="report.isArea">
                    <div class="col-12">
                        <small class="text-secondary">Área</small>
                    </div>
                    <div class="col-12">
                        <h2>{{report.departamento}}</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <small class="text-secondary">Fecha Alta</small>
                            </div>
                            <div class="col-12">
                                <h2>{{report.fechaAlta.seconds*1000 | date:'dd/MM/yyyy HH:mm'}}</h2>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <small class="text-secondary">Estatus</small>
                            </div>
                            <div class="col-12">
                                <h2>{{report.status == 0 ? 'Pendiente' : report.status == 1 ? 'En proceso' : 'Concluido'}}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="report.fechaCierre != null">
                    <div class="col-12">
                        <small class="text-secondary">Fecha cierre</small>
                    </div>
                    <div class="col-12">
                        <h2>{{report.fechaCierre.seconds*1000 | date:'dd/MM/yyyy HH:mm'}}</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <small class="text-secondary">Comentarios</small>
                    </div>
                    <div class="col-12">
                        <h2>{{report.comentario}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="report.status < 2" type="button" class="btn btn-outline-info col-12" (click)="changeStatus()">{{report.status == 0 ? 'Pasar a En proceso' : 'Pasar a Concluido' }}</button>
    <button *ngIf="report.status == 2" type="button" class="btn btn-outline-danger col-12" (click)="deleteReport()">Borrar reporte</button>
    <button type="submit" form="createBrand" class="btn btn-success col-12" (click)="activeModal.close('Close click')">OK</button>
</div>