<head>
    <title>Agregar torre • CondoClick</title>
</head>

<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-6">
                <label>Nombre</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Imagen</label>
                <input type="file" class="form-control material-design-primary" accept=".png, .jpg" (change)="onUpload($event)" required>
            </div>

            <div class="col-sm-12">
                <input [disabled]="checkoutForm.invalid || loading" type="submit" value="Guardar">

            </div>

        </div>
    </div>

</form>