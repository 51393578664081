import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-agregar-usuario',
  templateUrl: './agregar-usuario.component.html',
  styleUrls: ['./agregar-usuario.component.css']
})
export class AgregarUsuarioComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  checkoutForm;
  public torre: any;
  public departamento: any;
  public idTorre;
  datos;
  // Seleccionamos o iniciamos el valor '0' del <select>
  opcionSeleccionadoTorre: string = '0';
  opcionSeleccionadoDept: string = '0';
  verSeleccion: string = '';
  data: { nombre: string, email: string, fechaAlta: string, uid: string, torre: string, departamento: string }

  public user: { key: string, nombre: string, email: string, fechaAlta: string, id: string }

  constructor(private firestoreService: FirestoreService, private calendar: NgbCalendar, private formBuilder: FormBuilder, private router: Router, private rutaActiva: ActivatedRoute) {
    this.checkoutForm = this.formBuilder.group({
      torre: '',
      departamento: '',
    });
  }

  ngOnInit(): void {
    this.user = {
      key: this.rutaActiva.snapshot.params.id,
      nombre: this.rutaActiva.snapshot.params.nombre,
      email: this.rutaActiva.snapshot.params.email,
      fechaAlta: this.rutaActiva.snapshot.params.fecha,
      id: this.rutaActiva.snapshot.params.idU,
    };
    this.getTowersList();
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  getTowersList() {
    this.firestoreService.getTowersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  getDepartments(key) {
    this.firestoreService.getDepartmentsList(key).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(departaments => {
      this.departaments = departaments;
      this.torre = "Java";
    });
  }

  onDept() {
    this.departamento = "9F";
  }

  onSubmit(customerData) {
    // this.firestoreService.createUser(customerData).then(() => {
    // }, (error) => {
    //   console.error(error);
    // });
    this.data = {
      nombre: this.user.nombre,
      email: this.user.email,
      fechaAlta: this.user.fechaAlta,
      uid: this.user.id,
      torre: customerData.torre,
      departamento: customerData.departamento
    }
    this.firestoreService
      .updateUser(this.user.key, this.data)
      .catch(err => console.log(err));
    this.router.navigate(['/usuarios']);
  }

  capturar() {
    // Pasamos el valor seleccionado a la variable verSeleccion
    this.verSeleccion = this.opcionSeleccionadoTorre;
    this.getDepartments(this.verSeleccion);
  }

  update(key) {
    this.data = {
      nombre: this.user.nombre,
      email: this.user.email,
      fechaAlta: this.user.fechaAlta,
      uid: this.user.id,
      torre: this.opcionSeleccionadoTorre,
      departamento: this.opcionSeleccionadoDept
    }
    this.firestoreService
      .updateUser(key, this.data)
      .catch(err => console.log(err));
  }
}