import { Component, OnInit, Injectable } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { summaryFileName } from '@angular/compiler/src/aot/util';
import { parse } from '@fortawesome/fontawesome-svg-core';
import * as moment from 'moment';
import {NgbTimeStruct, NgbTimeAdapter} from '@ng-bootstrap/ng-bootstrap';

const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string| null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
  }
}

@Component({
  selector: 'app-agregar-area',
  templateUrl: './agregar-area.component.html',
  styleUrls: ['./agregar-area.component.css'],
  providers: [{provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter}]
})
export class AgregarAreaComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  checkoutForm;
  public torre: any;
  public departamento: any;
  public horaI;
  public horaF;
  public cap;
  public bloques = [];
  blo;
  public horaA: Date;
  public horaB: Date;
  public diferenciaHoras: any;
  public capacidadMax = [];
  public bloquesMax = [];
  public differnce;
  public bloquesToInsert;
  public keyArea;
  public idCondominio;
  public sumaHoras2: number;
  public alert = false;
  public icons = [];
  public icon: any;
  time: '13:30:00';
  // public time: any = {hour: 13, minute: 30};
  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }
  // dataBloques: { inicio: string, fin: string, status: boolean }
public hours: any = [];
public hourStart = "00:00";
public hourEnd = "00:00";

nameValidator: boolean;
capacityValidator: boolean;
openedValidator: boolean;
closedValidator: boolean;
perFlatValidator: boolean;
blockScheduleValidator: boolean;
anticipationValidator: boolean;
iconValidator: boolean;

  public dataBloques;

  constructor(private firestoreService: FirestoreService, private router: Router, private formBuilder: FormBuilder, private firestore: AngularFirestore) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      capacidad: ['', [Validators.required]],
      horaApertura: ['', [Validators.required]],
      horaCierre: ['', [Validators.required]],
      porDepartamento: ['', [Validators.required]],
      bloquesHorario: ['', [Validators.required]],
      antelacion: ['', [Validators.required]],
      icon: ['', [Validators.required]],
      mensaje: '',
    });
  }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.arrayFull();
    this.array24();
    this.getIcons();
    this.getHours15();
  }

  getHours15(){
    for(let i = 0; i < 24; i++){
      let hour = "", minute = "";
      if(i < 10){
        hour = `0${i}`;
      }

      else{
        hour = `${i}`;
      }

      // this.hours.push(`${hour}`);

      for(let j = 0; j < 4; j++){
        switch (j){
          case 0:
            minute = "00";
            this.hours.push(`${hour}:${minute}`);
            break;
          case 1:
            minute = "15";
            this.hours.push(`${hour}:${minute}`);
            break;
          case 2:
            minute = "30";
            this.hours.push(`${hour}:${minute}`);
            break;
          case 3:
            minute = "45";
            this.hours.push(`${hour}:${minute}`);
            break;
        }
      }
    }
  }

  setIcon($obj){
    this.icon = $obj;
    // console.log($obj);
  }

  getIcons(){
    this.firestore.collection('/Iconos').snapshotChanges()
    .pipe(
      map(
        icons => icons.map(
          c => ({key: c.payload.doc.id, ... c.payload.doc.data() as DocumentChangeAction<any>})
        )
      )
    ).subscribe(icons => {
      this.icons = icons;
    })
  }

  arrayFull() {
    for (var i = 1; i <= 100; i++) {
      this.capacidadMax.push(
        i
      )
    }
  }

  array24() {
    for (var i = 1; i <= 8; i++) {
      this.bloquesMax.push(
        i
      )
    }

    this.bloquesMax.push(
      12
    )

    this.bloquesMax.push(
      24
    )

    this.bloquesMax.push(
      36
    )

    this.bloquesMax.push(
      48
    )

    this.bloquesMax.push(
      72
    )
  }


  selectedFileFrontal = null;

  onSelectedFileFrontal(event) {
    this.selectedFileFrontal = event.target.files[0];
  }

  onSubmit(areaData) {
    areaData.icon = this.icon != null ? this.icon.image : "";
    this.nameValidator = areaData.nombre != "" ? true : false;
    this.capacityValidator = areaData.capacidad != "" ? true : false;
    this.openedValidator = areaData.horaApertura != "" ? true : false;
    this.closedValidator = areaData.horaCierre != "" ? true : false;
    this.perFlatValidator = areaData.porDepartamento != "" ? true : false;
    this.blockScheduleValidator = areaData.bloquesHorario != "" ? true : false;
    this.anticipationValidator = areaData.antelacion != "" ? true : false;
    this.iconValidator = areaData.icon != "" ? true : false;
    
    if(this.nameValidator && this.capacityValidator && this.openedValidator && this.closedValidator
        && this.perFlatValidator && this.blockScheduleValidator && this.anticipationValidator
        && this.iconValidator
    ){
      
      let diffSchedules = moment(`${moment().format('YYYY-MM-DD')} ${areaData.horaCierre}`).diff(moment(`${moment().format('YYYY-MM-DD')} ${areaData.horaApertura}`), 'hours');

      // console.log(diffSchedules);

      // console.log(moment(`${moment().format('YYYY-MM-DD')} ${areaData.horaApertura}`));
      

      let howBlocks = Math.floor(diffSchedules / parseInt(areaData.bloquesHorario));

      // console.log(howBlocks);

      let auxScheduleStart = moment(`${moment().format('YYYY-MM-DD')} ${areaData.horaApertura}:00`);
      let arrayBlocks = [];

      for(let i = 0; i < howBlocks; i++){
        let auxStartHour = auxScheduleStart.format("HH:mm");
        let sumSchedule = auxScheduleStart.add({hour: areaData.bloquesHorario}).format("HH:mm")
        // console.log(`HORARIO ${i}`, auxStartHour, " - ", sumSchedule);
        // auxScheduleStart = auxScheduleStart.add({hour: areaData.bloquesHorario});
        arrayBlocks.push({inicio: auxStartHour, fin: sumSchedule, status: true});
      }

      // console.log(arrayBlocks);

      this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes').add({
        antelacion: parseInt(areaData.antelacion),
        bloque: parseInt(areaData.bloquesHorario),
        capacidad: parseInt(areaData.capacidad),
        icon: areaData.icon,
        nombre: areaData.nombre,
        porDepartamento: parseInt(areaData.porDepartamento),
        status: true,
        mensaje: areaData.mensaje,
        horaApertura: areaData.horaApertura,
        horaCierre: areaData.horaCierre,
        bloquesHorario: areaData.bloquesHorario
      }).then((area) => {
        // console.log(area.id);
        
        for(let i = 0; i < arrayBlocks.length; i++){
          this.firestore.collection(`/Condominios/${this.idCondominio}/AreasComunes/${area.id}/Bloques`)
          .add(arrayBlocks[i]).then((block) => {
            // console.log("Bloque: ", block.id);
            
          })
        }
      }).then((value) => {
        this.router.navigate(['/areasComunes']);
      })
      
    }

    else{
      window.alert("Faltan campos");
    }

    // try {

    //   this.getDifference(areaData.horaApertura, areaData.horaCierre);
    //   this.bloquesToInsert = Math.round(this.differnce / areaData.bloquesHorario);
    //   areaData.bloque = this.bloquesToInsert;
    //   areaData.antelacion = parseInt(areaData.antelacion, 8);
    //   areaData.capacidad = parseInt(areaData.capacidad, 8);
    //   areaData.porDepartamento = parseInt(areaData.porDepartamento, 8);
    //   areaData.bloquesHorario = parseInt(areaData.bloquesHorario, 8);
    //   console.log("areaDAta", areaData);
    //   this.firestoreService.createArea(areaData).then(() => {
    //   }, (error) => {
    //     console.error(error);
    //   });
    //   this.getServices(areaData.nombre, this.bloquesToInsert, areaData.horaCierre, areaData.horaApertura, this.keyArea, areaData.bloquesHorario);
    //   // this.agregarBloque(this.bloquesToInsert, areaData.horaCierre, areaData.horaApertura, this.keyArea);
    //   this.router.navigate(['/areasComunes']);

    // }
    // catch (error) {
    //   console.log(error);
    // }

  }

  agregarBloque(bloques, horaCierre, horaApertura, key) {
    // console.log("llave de area", key);
    // var dateOpen;
    // var dateClose;
    // var dateBloque;
    // var hoy = new Date();
    // var dd = hoy.getDate();
    // var mm = hoy.getMonth() + 1;
    // var yyyy = hoy.getFullYear();

    // var formatHora = '0' + bloques + ':00';

    // dateOpen = yyyy + '/' + mm + '/' + dd + ' ' + horaApertura;
    // dateClose = yyyy + '/' + mm + '/' + dd + ' ' + horaCierre;
    // dateBloque = yyyy + '/' + mm + '/' + dd + ' ' + formatHora;

    // let fecha1 = new Date(dateOpen);
    // let fecha2 = new Date(dateClose);
    // let fecha3 = new Date(dateBloque);

    // let resta = fecha1.getTime() + fecha3.getTime()
    // let differnce = Math.round(resta / 3600000);
    // console.log("horaApertura",new Date(horaApertura));

    // for (let i = 0; i < bloques; i++) {
    //   // this.bloques.push({
    //   //   horaI: this.horaI,
    //   //   horaF: this.horaF,
    //   //   cap: this.cap
    //   // });
    //   this.dataBloques = {
    //     inicio: "01:00",
    //     fin: "09:00",
    //     status: true
    //   }



    //   console.log("bloques", this.dataBloques);

    //   this.firestoreService.createBloque(this.dataBloques, key).then(() => {
    //   }, (error) => {
    //     console.error(error);
    //   });
    // }


  }

  deleteBloque(i) {
    delete this.bloques[i];
  }

  getBloques() {
    this.firestoreService.getBloquesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(bloques => {
      this.bloques = bloques;
      // console.log(bloques);
    });
  }

  getDifference(horaO, horaC) {
    var dateOpen;
    var dateClose;
    var hoy = new Date();
    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var yyyy = hoy.getFullYear();

    dateOpen = yyyy + '/' + mm + '/' + dd + ' ' + horaO;
    dateClose = yyyy + '/' + mm + '/' + dd + ' ' + horaC;

    let fecha1 = new Date(dateOpen);
    let fecha2 = new Date(dateClose);

    let resta = fecha2.getTime() - fecha1.getTime()
    this.differnce = Math.round(resta / 3600000);
  }

  getServices(nombre, bloques, horaCierre, horaApertura, key, bloquesSum) {

    // console.log("bloques", bloques);
    // console.log("horaApertura", horaApertura);

    var dateOpen;
    var dateClose;
    var hoy = new Date();
    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var yyyy = hoy.getFullYear();

    dateOpen = yyyy + '/' + mm + '/' + dd + ' ' + horaApertura;
    dateClose = yyyy + '/' + mm + '/' + dd + ' ' + horaCierre;

    var fecha1 = new Date(dateOpen);
    var fecha2 = new Date(dateClose);

    var minutosFecha1 = fecha1.getMinutes();

    // fecha1.setMinutes(fecha1.getMinutes() + 5);

    // let fechaP = new Date(dateOpen);
    // fechaP.setHours(fechaP.getHours() + horaApertura);
    // var fecha = fechaP.toISOString();
    // var fecha_date = fecha.split('T');
    // var fecha_time = fecha_date[1].split('.');
    // var fecha_time = fecha_date[1].split(':');
    // var fecha_dateT = fecha_date[0];

    // fecha = fecha_dateT + ' ' + fecha_time[0] + ':' + fecha_time[1];

    // console.log("fecha", fecha);


    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes', ref =>
      ref.where('nombre', '==', nombre))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));
    snapshotResult.subscribe(doc => {

      this.keyArea = doc.payload.doc.id

      for (let i = 0; i < bloques; i++) {

        if (i == 0) {

          // console.log("this.checkoutForm.bloquesHorario", bloquesSum);
          // console.log("moment(hora_escogida).add(1, 'hours').format('HH:mm');", moment(new Date(horaApertura)).add(1, 'hours').format('HH:mm'));
          var sumaHoras = parseInt(horaApertura) + parseInt(bloquesSum);

          this.horaI = sumaHoras.toString() + ':' + minutosFecha1;

          this.dataBloques = {
            inicio: horaApertura,
            fin: this.horaI,
            status: true
          }

        } else {


          // console.log("this.sumaHoras2", parseInt(this.horaI));
          this.sumaHoras2 = parseInt(this.horaI) + parseInt(bloquesSum);

          this.horaF = this.sumaHoras2.toString() + ':' + minutosFecha1;

          // console.log(bloques, i)
          if (bloques - 1 == i) {
            this.dataBloques = {
              inicio: this.horaI,
              fin: horaCierre,
              status: true
            }
          } else {
            this.dataBloques = {
              inicio: this.horaI,
              fin: this.horaF,
              status: true
            }
          }


          this.horaI = this.horaF;
        }

        // console.log("bloques", this.dataBloques);

        this.firestoreService.createBloque(this.dataBloques, doc.payload.doc.id).then(() => {
        }, (error) => {
          console.error(error);
        });
      }

    });
  }

  showAlert(status) {
    if (status == "INVALID")
      this.alert = true;
  }
}
