import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot, DocumentChangeAction, DocumentReference } from '@angular/fire/firestore';
import { map, flatMap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AdministracionSeguridadModalAreaComponent } from './administracion-seguridad-modal-area/administracion-seguridad-modal-area.component';
import { EditarSeguridadAreaModalComponent } from './editar-seguridad-area-modal/editar-seguridad-area-modal.component';

import { SecurityService } from '../../../services/security/security.service';
import { ToastService } from '../../../services/toast/toast.service';
import { firestore } from 'firebase';

@Component({
  selector: 'app-reporte-seguridad-area',
  templateUrl: './reporte-seguridad-area.component.html',
  styleUrls: ['./reporte-seguridad-area.component.css']
})
export class ReporteSeguridadAreaComponent implements OnInit {

  public areas: Array<any> = [];
  public idCodominium: String = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public loading = true;

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private securityService: SecurityService,
    public toastService: ToastService,
  ) { }

  // changeValueStatus($obj, area){
  //   console.log($obj);
  //   console.log(area);
  // }

  changeValueStatus(area){
    console.log(area);
    let ref = area.ref as DocumentReference
    ref.update({status: area.status, lastUpdate: firestore.Timestamp.now(),})
    .then(() => {
      this.toastService.show('¡Estatus actualizado exitosamente!', { classname: 'bg-success text-light'});
    })
    .catch((e) => {
      console.log(e);
      this.toastService.show('No se pudo actualizar estatus', { classname: 'bg-danger text-light'});
    })
  }

  deleteArea(area){
    if(window.confirm(`¿Desea borrar área ${area.name} de seguridad?`)){
      let ref = area.ref as DocumentReference;
      ref.delete()
      .then(() => {
        this.toastService.show('¡Área borrada exitosamente!', { classname: 'bg-success text-light'});
      })
      .catch((e) => {
        console.log(e);
        this.toastService.show('No se pudo borrar área', { classname: 'bg-danger text-light'});
      })
    }
  }

  getSecurityAreas(){
    this.areas = [];
    let ref = this.securityService.getSecurityAreas(this.idCodominium).ref;
    // ref.orderBy('name', 'asc')
    this.firestore.collection(ref.path, _ref => 
      _ref.orderBy('name', 'asc') 
    )
    .snapshotChanges()
    .pipe(map(areas =>
      areas.map(
        c => ({ 
          id: c.payload.doc.id, 
          ref: c.payload.doc.ref,
          ...c.payload.doc.data() as DocumentChangeAction<any> 
        })
      )
    ))
    .subscribe(areas => {
      console.log("all ", areas);
      this.areas = areas;
      this.loading = false;
    });
  }

  openModalCreateNewArea(){
    const modalRef = this.modalService.open(AdministracionSeguridadModalAreaComponent, {
      // size: 'lg'
    });

    // modalRef.componentInstance.text = 'HOLAAAAA';

    modalRef.result.then((result) => {
      console.log(result);
      // this.getProducts();
    })
    .catch((error) => {
      console.log(error);
      // this.getProducts();
    });
  }

  openModalEditArea(securityArea){
    const modalRef = this.modalService.open(EditarSeguridadAreaModalComponent, {

    });

    modalRef.componentInstance.securityArea = securityArea;

    modalRef.result.then((result) => {
      console.log(result);
      // this.getProducts();
    })
    .catch((error) => {
      console.log(error);
      // this.getProducts();
    });

  }

  ngOnInit(): void {
    this.getSecurityAreas();
  }

}
