<div class="wrapper d-flex align-items-stretch" style="height: 100vh;">
  <nav class="sidebar d-none d-lg-block" style="overflow-y: auto !important;">
    <div class="p-4 pt-5">
      <img routerLink="/home" src="../assets/images/condoclick.jpeg" class="img logo rounded-circle mb-5">
      <ul *ngIf="statusCondo || admin == '1'" class="list-unstyled components mb-5">
        
        <li *ngIf="admin == '1'">
          <a routerLink="/super-admin" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">person</i> Super Admin
          </a>
        </li>
        <!-- <li *ngIf="admin == '1'" class="active">
          <a routerLink="/admin" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">supervisor_account</i> Administradores</a>
        </li> -->
        <li *ngIf="admin == '1'">
          <a routerLink="/condominios" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">location_city</i> Condominios</a>
        </li>
        <!-- <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/usuarios" data-toggle="collapse" aria-expanded="false"> <i class="fa fa-users"
              aria-hidden="true"></i> Solicitudes de Usuarios</a>
        </li> -->
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/select-user" data-toggle="collapse" aria-expanded="false"> <i class="fa fa-user-plus"
              aria-hidden="true"></i> Usuarios</a>
        </li>

        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/areasComunes" data-toggle="collapse" aria-expanded="false"><i class="fa fa-smile-o"
              aria-hidden="true"></i> Areas Comunes</a>

        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/torres" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">domain</i> Torres</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/departamentos" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">apartment</i> Departamentos</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/servicios" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">room_service</i> Servicios</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/avisos"><i class="fa fa-bell" aria-hidden="true"></i> Avisos</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/soporte" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">support_agent</i> Soporte</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/icons" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">insert_photo</i> Iconos</a>
        </li>

        <li *ngIf="admin == '3' && isReservacion == 'true' || admin == '2' || admin == '1'">
          <a routerLink="/reservaciones"><i class="fa fa-envelope-open" aria-hidden="true"></i> Gestión de
            reservaciones </a>
        </li>

        <li *ngIf="admin == '3' && isServicio == 'true' || admin == '2' || admin == '1'">
          <a (click)="goServices()"><i class="fa fa-shower" aria-hidden="true"></i> Gestión de servicios</a>
        </li>

        <li *ngIf="admin == '4' || admin == '3' && isReservacion == 'true' || admin == '2' || admin == '1'">
          <a routerLink="/visitas"><i class="material-icons mr-1" aria-hidden="true">tour</i> Gestión de
            visitas </a>
        </li>

        <li *ngIf="admin <= '3'">
          <a aria-expanded="false" (click)="isCollapsedSecurity = !isCollapsedSecurity" [attr.aria-expanded]="!isCollapsedSecurity" data-toggle="collapseTest" aria-controls="collapseExample" class="d-flex align-items-center btn text-white pl-0 w-100"><i
              class="material-icons mr-1" aria-hidden="true">security</i>Seguridad
              <i class="material-icons ml-auto">{{isCollapsedSecurity ? 'expand_more' : 'expand_less'}}</i>
          </a>

          <ul #collapseTest="ngbCollapse" [(ngbCollapse)]="isCollapsedSecurity" class="list-unstyled ml-4">
            
            <li *ngIf="admin == '1' || admin == '2'">
              <a routerLink="/reporte-seguridad" class="d-flex align-items-center">
                <i class="material-icons mr-1">manage_accounts</i>Administración</a>
            </li>

            <li *ngIf="admin == '3' && isSeguridad == 'true' || admin == '2' || admin == '1'">
              <a routerLink="/gestion-seguridad" class="d-flex align-items-center">
                <i class="material-icons mr-1">assignment</i>Reportes</a> 
            </li>
            

          </ul>
        </li>

        <li *ngIf="admin == '3' && isReservacion == 'true' || admin == '2' || admin == '1'">
          <a routerLink="/boards" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">filter_frames</i>Pizarra</a>
        </li>
      </ul>

      <div class="footer">
        <p>
          Propiedad y derechos reservados Cleversec SAPI de CV
          <br>
          condoclick@cleversec.com.mx
        </p>
        <a routerLink="/privacy-terms"><small>Aviso de privacidad</small></a>
      </div>

    </div>
  </nav>
  <nav id="" *ngIf="isCollapsed" class="sidebar">
    <div class="p-4 pt-5">
      <img routerLink="/home" src="../assets/images/condoclick.jpeg" class="img logo rounded-circle mb-5">
      <ul class="list-unstyled components mb-5">
        <li *ngIf="admin == '1'">
          <a routerLink="/" class="d-flex align-items-center">
            <i class="material-icons mr-1">room_service</i> Test</a>
        </li>
        <li *ngIf="admin == '1'">
          <a routerLink="/super-admin" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">person</i> Super Admin</a>
        </li>
        <!-- <li *ngIf="admin == '1'" class="active">
          <a routerLink="/admin" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">supervisor_account</i> Administradores</a>
        </li> -->
        <li *ngIf="admin == '1'">
          <a routerLink="/condominios" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">location_city</i> Condominios</a>
        </li>
        <!-- <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/usuarios" data-toggle="collapse" aria-expanded="false"> <i class="fa fa-users"
              aria-hidden="true"></i> Solicitudes de Usuarios</a>
        </li> -->
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/select-user" data-toggle="collapse" aria-expanded="false"> <i class="fa fa-user-plus"
              aria-hidden="true"></i> Usuarios</a>
        </li>

        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/areasComunes" data-toggle="collapse" aria-expanded="false"><i class="fa fa-smile-o"
              aria-hidden="true"></i> Areas Comunes</a>

        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/torres" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">domain</i> Torres</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/departamentos" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">apartment</i> Departamentos</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/servicios" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">room_service</i> Servicios</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/reporte-seguridad" class="d-flex align-items-center"><i class="fa fa-shield fa-lg"
              aria-hidden="true"></i>&nbsp; Reportes de seguridad</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/notificaciones"><i class="fa fa-bell" aria-hidden="true"></i> Avisos</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/soporte" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">support_agent</i> Soporte</a>
        </li>
        <li *ngIf="admin == '1' || admin == '2'">
          <a routerLink="/icons" class="d-flex align-items-center"><i class="material-icons mr-1"
              aria-hidden="true">insert_photo</i> Iconos</a>
        </li>

        <li *ngIf="admin == '3' && isReservacion == 'true' || admin == '2' || admin == '1'">
          <a routerLink="/reservaciones"><i class="fa fa-envelope-open" aria-hidden="true"></i> Gestión de
            reservaciones </a>
        </li>

        <li *ngIf="admin == '3' && isServicio == 'true' || admin == '2' || admin == '1'">
          <a (click)="goServices()"><i class="fa fa-shower" aria-hidden="true"></i> Gestión de servicios</a>
        </li>

        <li *ngIf="admin == '4' || admin == '3' && isReservacion == 'true' || admin == '2' || admin == '1'">
          <a routerLink="/visitas"><i class="material-icons mr-1" aria-hidden="true">tour</i> Gestión de
            visitas </a>
        </li>

        <li *ngIf="admin == '3' && isReservacion == 'true' || admin == '2' || admin == '1'">
          <a routerLink="/boards" data-toggle="collapse" aria-expanded="false" class="d-flex align-items-center"><i
              class="material-icons mr-1" aria-hidden="true">filter_frames</i>Pizarra</a>
        </li>
      </ul>

      <div class="footer">
        <p>
          Propiedad y derechos reservados Cleversec SAPI de CV
          <br>
          condoclick@cleversec.com.mx
        </p>
        <a routerLink="/privacy-terms"><small>Términos y condiciones</small></a>
      </div>

    </div>
  </nav>

  <!-- Page Content  -->
  <div id="content" class="p-4 p-md-5" style="height: 100vh !important; overflow-y: auto !important">

    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">

        <button type="button" id="sidebarCollapse" class="btn btn-primary d-block d-lg-none"
          (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
          <i class="fa fa-bars"></i>
          <span class="sr-only">Toggle Menu</span>
        </button>
        &nbsp;
        &nbsp;
        &nbsp;
        <!-- <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fa fa-bars"></i>
        </button> -->

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- <i *ngIf="isLoged" class="color-noti fa fa-envelope-open fa-2x"> <button
              *ngIf="countReservations != 0 && isLoged" type="button"
              class="notification-align btn btn-danger btn-circle">{{countReservations}}</button></i> -->


          &nbsp;
          &nbsp;
          <!-- <i *ngIf="isLoged && admin == '3'" class="color-noti fa fa-shower fa-2x"> <button
              *ngIf="countServices != 0 && isLoged" type="button"
              class="notification-align btn btn-danger btn-circle">{{countServices - 1}}</button></i> -->
          <div *ngIf="this.admin == '3'">
            <app-icon-notifications [item]="currentItem"></app-icon-notifications>
          </div>


          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item">
              <a routerLink="/login" *ngIf="!isLoged" class="nav-link" href="#">Iniciar Sesión</a>
            </li>
            <li *ngIf="isLoged" class="nav-item">
              <a (click)="logOut()" class="nav-link" href="#">Cerrar Sesión</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <span
      class="version-prueba badge badge-pill px-3 mb-5 {{days >= 0 ? days >= 10 ? 'badge-info' : 'badge-warning' : 'badge-danger'}}"
      *ngIf="(admin == 3 || admin == 2) && condominio != null && condominioData[0].test && days >= 0">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Versión de prueba finaliza el:
      {{fechaExp.seconds * 1000 | date:'dd/MM/yyyy'}}
    </span>

    <span
      class="version-prueba badge badge-pill px-3 mb-5 {{days >= 0 ? days >= 10 ? 'badge-light' : 'badge-warning' : 'badge-danger'}}"
      *ngIf="(admin == 3 || admin == 2) && condominio != null && !condominioData[0].test && days >= 0">
      Licencia finaliza el: {{fechaExp.seconds * 1000 | date:'dd/MM/yyyy'}}
    </span>

    <span class="version-prueba color-licencia badge badge-pill px-3 mb-5 badge-danger"
      *ngIf="(admin == 3 || admin == 2) && condominio != null && days < 0">
      Licencia caducada, favor de contactar a provedor
    </span>

    <!-- <span class="version-prueba-expirada badge badge-pill badge-info px-3 mb-5" *ngIf="(admin == 3 || admin == 2) && !statusCondo">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Versión expirada, favor de contactar a cleversec
    </span> -->

    <!-- <h2 class="mb-4">Administración</h2>
    <i class="fas fa-swimming-pool"></i>
    <p>Sistema destinado a la operación de áreas y servicios para condominios afiliados.</p> -->

    <router-outlet></router-outlet>

  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>