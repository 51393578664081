<head>
  <title>Torres • CondoClick</title>
</head>
<ul ngbNav #nav="ngbNav" class="nav nav-pills mb-3 tab-material" id="pills-tab" role="tablist">
  <li [ngbNavItem]="1">
    <a ngbNavLink class="btn pills-tab bg-white text-black">Torres</a>
      <ng-template ngbNavContent>
        <div class="container-fluid">
          <div class="row">
        
            <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-5" *ngFor="let tower of towers; index as i; first as isFirst">
              <div class="card">
                <img routerLink="/departamento-tablas/{{tower.key}}/{{tower.nombre}}" src="{{tower.url}}" alt="Avatar" style="width:100%">
                <div class="container text-center pt-3">
                  <h4><b>{{tower.nombre}}</b></h4> 
                  <p>Torre del lado sur</p>
                </div>
              </div>
        
        
        
            </div>
        
          </div>
        </div>
      </ng-template>
    <!-- <a class="btn pills-tab active" id="pills-profile-tab" data-toggle="pill" href="reservaciones/{{idArea}}/todas" role="tab" aria-controls="pills-profile" aria-selected="true">Todas</a> -->
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink class="btn pills-tab bg-white text-black">Solicitudes de departamentos</a>
    <ng-template ngbNavContent>
      <app-solicitudes></app-solicitudes>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-4"></div>