import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-departamentos-tablas',
  templateUrl: './departamentos-tablas.component.html',
  styleUrls: ['./departamentos-tablas.component.css']
})
export class DepartamentosTablasComponent implements OnInit {

  public departments = [];
  public usersById = [];
  public torre: { idTorre: string, nombreTorre: string };
  public keyDepartment;
  public idCondominio;
  public user = [];
  public nameDept;
  public requests = [];
  pageSize: number = 20;
  page = 1;

  constructor(private firestore: AngularFirestore, private firestoreService: FirestoreService, private rutaActiva: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.torre = {
      idTorre: this.rutaActiva.snapshot.params.idTorre,
      nombreTorre: this.rutaActiva.snapshot.params.nombreTorre
    };
    this.getDepartmentsList();

  }

  getDepartmentsList() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + this.torre.idTorre + '/Departamentos', ref =>
      ref.orderBy("nombre", "asc"))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      this.departments = reser;
    });

  }

  delete(key, userKey) {
    const snapshotResult = this.firestore.collection('Departamentos', ref =>
      ref.where('usuario', '==', userKey))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      if (reser[0].id) {
        this.user = reser;
        this.firestoreService.deleteUsuarioRoot(reser[0].id)
          .catch(err => console.log(err));
        this.firestoreService.deleteUsuario(key, this.keyDepartment, this.torre.idTorre)
          .catch(err => console.log(err));
      }
    });
  }



  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateDepartmentUser(key, data, this.torre.idTorre, this.keyDepartment)
      .catch(err => console.log(err));
  }

  users(key, name) {
    this.keyDepartment = key;
    this.nameDept = name;
    this.firestoreService.getUsersById(this.torre.idTorre, key).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(users => {
      this.usersById = users;
    });
    this.getRequests(key);
  }

  newD() {
    this.router.navigate(['/departamentos-form/' + this.torre.idTorre + '/' + this.torre.nombreTorre + '/tTrx7ie28YeblzB6z9R1Cee7Iqv1']);
  }

  editDept(idDept, nombre) {
    this.router.navigate(['/edit-department/' + this.torre.idTorre + '/' + this.torre.nombreTorre + '/tTrx7ie28YeblzB6z9R1Cee7Iqv1/' + idDept + '/' + nombre]);
  }

  approved(flat) {
    this.firestore.collection('/Usuarios', ref =>
      ref.where('uid', '==', flat.usuario)
    )
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c => {
            // console.log(c.payload.doc.data());

            return ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
          }
        )
      ))
      .subscribe(user => {
        // this.reservations = reser;
        if (user.length > 0) {
          let u = user[0] as any;
          flat.status = true;
          this.firestore.collection('/Departamentos').doc(flat.id).set(flat).then(() => {

            this.firestore.collection(`/Condominios/${flat.idCondominio}/Torres/${flat.idTorre}/Departamentos/${flat.idDepartamento}/Usuarios`)
              .add({
                'usuario': flat.usuario,
                'status': true,
                'fechaAlta': new Date(),
                'apellido': '',
                'nombre': u.nombre,
              })
          });
        }
      });
  }

  rejected(flat) {
    // console.log(flat);
    if (confirm("¿Desea borrar solicitud?")) {
      this.firestore.collection('/Departamentos').doc(flat.id).delete();
    }
    // else {
    //   console.log("no");

    // }
  }

  getRequests(idDept) {
    this.firestore.collection(`/Departamentos`, ref =>
      ref.where('status', '==', false)
        .where('idCondominio', '==', this.idCondominio)
        .where('idDepartamento', '==', idDept)
        .orderBy('fechaAlta')
    )
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c => {
            // console.log(c.payload.doc.data());

            return ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
          }
        )
      ))
      .subscribe(req => {
        this.requests = [];
        req.map(user => {
          let _user: any = user;
          // console.log(_user.usuario);

          this.firestore.collection('/Usuarios', ref =>
            ref.where('uid', '==', _user.usuario)
          )
            .valueChanges()
            .subscribe(data => {
              data.forEach(e => {
                // console.log(e);
                let _auxe = e as any;

                // console.log(_auxe.email);
                this.requests.push({ ..._auxe, ...user });

              })
            })

        })
        // console.log("REQ: ", req);
      });
  }

}
