<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-6">
                <label>Torre</label>
                <select [(ngModel)]="opcionSeleccionadoTorre" (change)="capturar()" formControlName="torre" name="cars" id="cars">
                    <option *ngFor="let tower of towers; index as i; first as isFirst" value="{{tower.key}}">
                        {{tower.nombre}}</option>
                </select>
            </div>
            <div class="col-sm-6">
                <label>Departamento</label>
                <select [(ngModel)]="opcionSeleccionadoDept" formControlName="departamento" name="cars" id="cars">
                    <option *ngFor="let departament of departaments; index as i; first as isFirst"
                        value="{{departament.key}}">{{departament.nombre}}</option>
                </select>
            </div>

            <!-- <div class="col-sm-6">
                <label>Nombre</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span><span
                    class="bar"></span>

            </div>
            <div class="col-sm-6">
                <label>Apellido</label>
                <input id="apellido" type="text" formControlName="apellido"><span class="highlight"></span><span
                    class="bar"></span>
            </div>


            <div class="col-sm-12">
                <label>Fecha Alta  </label>
                <input formControlName="fechaAlta" type="date" id="birthday" name="birthday">
            </div> -->

            <div class="col-sm-12">
                <input type="submit" value="Guardar">

            </div>

           
        </div>
    </div>

</form>