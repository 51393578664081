import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuariosComponent } from './admin/usuarios/usuarios.component';
import { LoginComponent } from './admin/login/login.component';
import { AreasComunesComponent } from './admin/areas-comunes/areas-comunes.component';
import { TorresComponent } from './admin/torres/torres.component';
import { DepartamentosComponent } from './admin/departamentos/departamentos.component';
import { AgregarUsuarioComponent } from './admin/agregar-usuario/agregar-usuario.component';
import { AgregarAreaComponent } from './admin/agregar-area/agregar-area.component';
import { AgregarTorresComponent } from './admin/agregar-torres/agregar-torres.component';
import { DepartamentosTablasComponent } from './admin/departamentos-tablas/departamentos-tablas.component';
import { AgregarDepartamentosComponent } from './admin/agregar-departamentos/agregar-departamentos.component';
import { RegistroUsuarioComponent } from './admin/registro-usuario/registro-usuario.component';
import { ServiciosComponent } from './admin/servicios/servicios.component';
import { NotificacionesComponent } from './admin/notificaciones/notificaciones.component';
import { AgregarServicioComponent } from './admin/agregar-servicio/agregar-servicio.component';
import { CondominiosComponent } from './admin/condominios/condominios.component';
import { AdministradoresComponent } from './admin/administradores/administradores.component';
import { ReservacionesComponent } from './admin/reservaciones/reservaciones.component';
import { SoporteComponent } from './admin/soporte/soporte.component';
import { AgregarSoporteComponent } from './admin/agregar-soporte/agregar-soporte.component';
import { AgregarCondoComponent } from './admin/agregar-condo/agregar-condo.component';
import { AgregarAdminComponent } from './admin/agregar-admin/agregar-admin.component';
import { GestionServiciosComponent } from './admin/gestion-servicios/gestion-servicios.component';
import { AgregarGestionServiciosComponent } from './admin/agregar-gestion-servicios/agregar-gestion-servicios.component';
import { SeleccionarTorreComponent } from './admin/solicitudes/seleccionar-torre/seleccionar-torre.component';
import { ReservacionesPendientesComponent } from './admin/reservaciones/reservaciones-pendientes/reservaciones-pendientes.component';
import { ReservacionesHistorialComponent } from './admin/reservaciones/reservaciones-historial/reservaciones-historial.component';
import { UsuariosOperativosComponent } from './admin/usuarios-operativos/usuarios-operativos.component';
import { AgregarOperativoComponent } from './admin/agregar-operativo/agregar-operativo.component';
import { EditarUserComponent } from './admin/editar-user/editar-user.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { SuperAdminComponent } from './admin/super-admin/super-admin.component';
import { WelcomeComponent } from './admin/welcome/welcome.component';
import { VerAvisosComponent } from './admin/ver-avisos/ver-avisos.component';
import { EditarAreaComponent } from './admin/editar-area/editar-area.component';
import { EditarServicioComponent } from './admin/editar-servicio/editar-servicio.component';
import { EditarTorresComponent } from './admin/editar-torres/editar-torres.component';
import { EditarDepartamentosComponent } from './admin/editar-departamentos/editar-departamentos.component';
import { IconsComponent } from './admin/icons/icons.component';
import { ForgotPasswordComponent } from './admin/forgot-password/forgot-password.component';
import { BoardsComponent } from './admin/boards/boards.component';
import { SelectUsuarioComponent } from './admin/select-usuario/select-usuario.component';
import { VisitasComponent } from './admin/visitas/visitas.component';
import { ReporteSeguridadComponent } from './admin/reporte-seguridad/reporte-seguridad.component';
import { AgregarSeguridadComponent } from './admin/agregar-seguridad/agregar-seguridad.component';
import { EditarSeguridadComponent } from './admin/editar-seguridad/editar-seguridad.component';
import { GestionSeguridadComponent } from './admin/gestion-seguridad/gestion-seguridad.component';
import { AgregarGestionSeguridadComponent } from './admin/agregar-gestion-seguridad/agregar-gestion-seguridad.component';
import { DetalleAreaSeguridadComponent } from './admin/reporte-seguridad/reporte-seguridad-area/detalle-area-seguridad/detalle-area-seguridad.component';

const routes: Routes = [
  { path: '', component: WelcomeComponent },
  { path: 'usuarios', component: UsuariosComponent },
  { path: 'login', component: LoginComponent },
  { path: 'areasComunes', component: AreasComunesComponent },
  { path: 'torres', component: TorresComponent },
  { path: 'departamentos', component: DepartamentosComponent },
  { path: 'usuarios-form/:id/:nombre/:email/:fecha/:idU', component: AgregarUsuarioComponent },
  { path: 'areas-form', component: AgregarAreaComponent },
  { path: 'torres-form', component: AgregarTorresComponent },
  { path: 'departamento-tablas/:idTorre/:nombreTorre', component: DepartamentosTablasComponent },
  { path: 'departamentos-form/:idTorre/:nombreTorre/:idUsuario', component: AgregarDepartamentosComponent },
  { path: 'registro', component: RegistroUsuarioComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'notificaciones', component: NotificacionesComponent },
  { path: 'servicios-form', component: AgregarServicioComponent },
  { path: 'condominios', component: CondominiosComponent },
  { path: 'admin', component: AdministradoresComponent },
  { path: 'reservaciones', component: ReservacionesComponent },
  { path: 'reservaciones/:idArea/proximas', component: ReservacionesPendientesComponent },
  { path: 'reservaciones/:idArea/todas', component: ReservacionesHistorialComponent },
  { path: 'select-user', component: SelectUsuarioComponent },
  { path: 'soporte', component: SoporteComponent },
  { path: 'soporte-form', component: AgregarSoporteComponent },
  { path: 'condos-form', component: AgregarCondoComponent },
  { path: 'admin-form', component: AgregarAdminComponent },
  { path: 'gestionServicios', component: GestionServiciosComponent },
  { path: 'gestion-servicios-form', component: AgregarGestionServiciosComponent },
  { path: 'usuarios-operativos', component: UsuariosOperativosComponent },
  { path: 'oper-form', component: AgregarOperativoComponent },
  { path: 'update-oper/:email', component: EditarUserComponent },
  { path: 'privacy-terms', component: PrivacyTermsComponent },
  { path: 'super-admin', component: SuperAdminComponent },
  { path: 'home', component: WelcomeComponent },
  { path: 'avisos', component: VerAvisosComponent },
  { path: 'edit-area/:idArea/:nombre', component: EditarAreaComponent },
  { path: 'edit-service/:idServicio/:nombre', component: EditarServicioComponent },
  { path: 'edit-tower/:idTower/:nombre', component: EditarTorresComponent },
  { path: 'edit-department/:idTorre/:nombreTorre/:idUsuario/:idDepartment/:nombre', component: EditarDepartamentosComponent },
  { path: 'icons', component: IconsComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'boards', component: BoardsComponent},
  { path: 'visitas', component: VisitasComponent},
  { path: 'reporte-seguridad', component: ReporteSeguridadComponent},
  { path: 'seguridad-form', component: AgregarSeguridadComponent},
  { path: 'edit-security/:idServicio/:nombre', component: EditarSeguridadComponent },
  { path: 'gestion-seguridad', component: GestionSeguridadComponent },
  { path: 'gestion-seguridad/areas/:idArea', component: DetalleAreaSeguridadComponent },
  { path: 'gestion-seguridad-form', component: AgregarGestionSeguridadComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
