<head>
    <title>Agregar condomino • CondoClick</title>
</head>

<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-6">
                <label>Nombre del condomino</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Codigo postal</label>
                <input (change)="getAddress(checkoutForm.value.codigo)" id="codigo" type="text"
                    formControlName="codigo"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Colonia</label>
                <select [(ngModel)]="neighborhoodSelected" (ngModelChange)="onChange($event)" formControlName="auxColonia">
                    <option [ngValue]="" selected>Seleccionar colonia</option>
                    <option *ngFor="let colonia of colonias; index as i; first as isFirst" [ngValue]="colonia">
                        {{colonia.asentamiento}}
                    </option>
                </select>
            </div>

            <div class="col-sm-6">
                <!-- <label>Estado</label>
                <select formControlName="estado">
                    <option *ngFor="let estado of estados; index as i; first as isFirst" [value]="estado">
                        {{estado}}
                    </option>
                </select> -->

                <label>Estado</label>
                <input id="estado" type="text" formControlName="estado"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Ciudad/Delegación</label>
                <input id="ciudad" type="text" formControlName="ciudad"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Calle y número</label>
                <input id="calle" type="text" formControlName="calle"><span class="highlight"></span>
            </div>

            <div class="form-group col-6">
                <label>Tipo de Licencia</label>
                <select formControlName="test">
                    <option selected value="" disabled>Seleccionar tipo de licencia</option>
                    <option [value]="true">Prueba</option>
                    <option [value]="false">Licencia</option>
                </select>
            </div>

            <div class="form-group col-6">
                <label for="fechaCad">Fecha de vencimiento</label>
                <input type="date" id="fechaCad" class="form-control" placeholder="Fecha de reservación" name="fechaCad"
                    formControlName="fechaCad">
            </div>

            <!-- <div class="col-sm-12">
                <label>Fecha de vencimiento</label>
                <input id="fecha" type="date" formControlName="fecha"><span class="highlight"></span>
            </div> -->

            <div class="col-12">
                <label>Imagen</label>
                <input type="file" class="form-control material-design-primary"
                    accept=".png, .jpg" (change)="onUpload($event)" required>
            </div>

            <div class="col-sm-12">
                <!-- <button [disabled]="checkoutForm.invalid" type="submit" class="btn col-12 btn-info">Agregar condomino</button> -->
                <button [disabled]="checkoutForm.invalid || loading" type="submit" class="btn col-12 btn-info">Agregar condomino</button>
            </div>

        </div>
    </div>
</form>