import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-agregar-departamentos',
  templateUrl: './agregar-departamentos.component.html',
  styleUrls: ['./agregar-departamentos.component.css']
})
export class AgregarDepartamentosComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  checkoutForm;
  public torre: any;
  public departamento: any;
  public dataDepartment: { idTorre: string, nombreTorre: string, idUser: string };

  public userFlutter = [];

  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }

  constructor(private firestoreService: FirestoreService, private calendar: NgbCalendar, private formBuilder: FormBuilder, private router: Router, private rutaActiva: ActivatedRoute) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      status: '',
      fechaAlta: ''
    });
  }

  ngOnInit(): void {
    this.dataDepartment = {
      idTorre: this.rutaActiva.snapshot.params.idTorre,
      nombreTorre: this.rutaActiva.snapshot.params.nombreTorre,
      idUser: this.rutaActiva.snapshot.params.idUsuario

    };
  }

  onSubmit(departamento) {
  

    // departamento.departamento = "69F";
    // departamento.hora = new Date("31/8/2020").getTime();
    // departamento.idCodominio = "3g55YOabc7lzgUt8GiPH";
    // departamento.torre = this.dataDepartment.nombreTorre
    // departamento.usuario = this.dataDepartment.idUser;
    departamento.status = true;
    departamento.fechaAlta = new Date();
    // this.firestoreService.createDepartmentFlutter(departamento).then(() => {
    // }, (error) => {
    //   console.error(error);
    // });
    // this.router.navigate(['/departamentos']);
  }

  onSubmitDept(users) {
    users.nombre = users.nombre.trim();
    users.status = true;
    this.onSubmit(users);
    this.firestoreService.createDepartment(users, this.dataDepartment.idTorre).then(() => {
    }, (error) => {
      console.error(error);
    });
    this.router.navigate(['/departamento-tablas/' + this.dataDepartment.idTorre + '/' + this.dataDepartment.nombreTorre ]);
  }

}

