import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UsuariosComponent } from './admin/usuarios/usuarios.component';
import { LoginComponent } from './admin/login/login.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { HttpClientModule } from '@angular/common/http';
import { AreasComunesComponent } from './admin/areas-comunes/areas-comunes.component';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { TorresComponent } from './admin/torres/torres.component';
import { DepartamentosComponent } from './admin/departamentos/departamentos.component';
import { DepartamentosTablasComponent } from './admin/departamentos-tablas/departamentos-tablas.component';
import { AgregarUsuarioComponent } from './admin/agregar-usuario/agregar-usuario.component';
import { AngularDropdownModule } from 'angular-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap/';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgregarAreaComponent } from './admin/agregar-area/agregar-area.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgregarTorresComponent } from './admin/agregar-torres/agregar-torres.component';
import { AgregarDepartamentosComponent } from './admin/agregar-departamentos/agregar-departamentos.component';
import { ServiciosComponent } from './admin/servicios/servicios.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseAuthService } from 'src/app/services/firebase/firebase-auth.service';
import { RegistroUsuarioComponent } from './admin/registro-usuario/registro-usuario.component';
import { NotificacionesComponent } from './admin/notificaciones/notificaciones.component';
import { AgregarServicioComponent } from './admin/agregar-servicio/agregar-servicio.component';
import { CondominiosComponent } from './admin/condominios/condominios.component';
import { AdministradoresComponent } from './admin/administradores/administradores.component';
import { ReservacionesComponent } from './admin/reservaciones/reservaciones.component';
import { SoporteComponent } from './admin/soporte/soporte.component';
import { AgregarSoporteComponent } from './admin/agregar-soporte/agregar-soporte.component';
import { AgregarCondoComponent } from './admin/agregar-condo/agregar-condo.component';
import { AgregarAdminComponent } from './admin/agregar-admin/agregar-admin.component';
import { GestionServiciosComponent } from './admin/gestion-servicios/gestion-servicios.component';
import { AgregarGestionServiciosComponent } from './admin/agregar-gestion-servicios/agregar-gestion-servicios.component';
import { SeleccionarTorreComponent } from './admin/solicitudes/seleccionar-torre/seleccionar-torre.component';
import { ReservacionesPendientesComponent } from './admin/reservaciones/reservaciones-pendientes/reservaciones-pendientes.component';
import { ReservacionesHistorialComponent } from './admin/reservaciones/reservaciones-historial/reservaciones-historial.component';
import { AgregarOperativoComponent } from './admin/agregar-operativo/agregar-operativo.component';
import { UsuariosOperativosComponent } from './admin/usuarios-operativos/usuarios-operativos.component';
import { ModalReservacionComponent } from './admin/reservaciones/modal-reservacion/modal-reservacion.component';
import { EditarUserComponent } from './admin/editar-user/editar-user.component';
import { SolicitudesComponent } from './admin/departamentos/solicitudes/solicitudes.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { SuperAdminComponent } from './admin/super-admin/super-admin.component';
import { WelcomeComponent } from './admin/welcome/welcome.component';
import { VerAvisosComponent } from './admin/ver-avisos/ver-avisos.component';
import { EditarAreaComponent } from './admin/editar-area/editar-area.component';
import { EditarServicioComponent } from './admin/editar-servicio/editar-servicio.component';
import { EditarTorresComponent } from './admin/editar-torres/editar-torres.component';
import { EditarDepartamentosComponent } from './admin/editar-departamentos/editar-departamentos.component';
import { IconsComponent } from './admin/icons/icons.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ForgotPasswordComponent } from './admin/forgot-password/forgot-password.component';
import { IconNotificationsComponent } from './admin/icon-notifications/icon-notifications.component';
import { BoardsComponent } from './admin/boards/boards.component';
import { ReporteSeguridadComponent } from './admin/reporte-seguridad/reporte-seguridad.component';
import { SelectUsuarioComponent } from './admin/select-usuario/select-usuario.component';
import { UsuarioVigilanteComponent } from './admin/usuarios/usuario-vigilante/usuario-vigilante.component';
import { ModalAgregarVigilanteComponent } from './admin/usuarios/usuario-vigilante/modal-agregar-vigilante/modal-agregar-vigilante.component';
import { ModalEditarVigilanteComponent } from './modal-editar-vigilante/modal-editar-vigilante.component';
import { DetalleReservacionComponent } from './detalle-reservacion/detalle-reservacion.component';
import { VisitasComponent } from './admin/visitas/visitas.component';
import { ListaVisitasComponent } from './admin/visitas/lista-visitas/lista-visitas.component';
import { ListaVisitasHistorialComponent } from './admin/visitas/lista-visitas-historial/lista-visitas-historial.component';
// import { DetalleReservacionComponent } from './admin/reservaciones/detalle-reservacion/detalle-reservacion.component';
import { AgregarSeguridadComponent } from './admin/agregar-seguridad/agregar-seguridad.component';
import { EditarSeguridadComponent } from './admin/editar-seguridad/editar-seguridad.component';
import { GestionSeguridadComponent } from './admin/gestion-seguridad/gestion-seguridad.component';
import { AgregarGestionSeguridadComponent } from './admin/agregar-gestion-seguridad/agregar-gestion-seguridad.component';
import { ListaGestionSeguridadComponent } from './admin/gestion-seguridad/lista-gestion-seguridad/lista-gestion-seguridad.component';
import { ModalAgregarReporteComponent } from './admin/gestion-seguridad/modal-agregar-reporte/modal-agregar-reporte.component';
import { ModalDetalleReporteComponent } from './admin/gestion-seguridad/modal-detalle-reporte/modal-detalle-reporte.component';
import { ToastsContainer } from './services/toast/toast-container/toast-container.component';
import { ModalDescargarReporteComponent } from './admin/visitas/modal-descargar-reporte/modal-descargar-reporte.component';
import { ReporteSeguridadAreaComponent } from './admin/reporte-seguridad/reporte-seguridad-area/reporte-seguridad-area.component';
import { ReporteSeguridadDepartamentoComponent } from './admin/reporte-seguridad/reporte-seguridad-departamento/reporte-seguridad-departamento.component';
import { AdministracionSeguridadModalAreaComponent } from './admin/reporte-seguridad/reporte-seguridad-area/administracion-seguridad-modal-area/administracion-seguridad-modal-area.component';

/* -------------------------------------------------------------------------- */
/*                               ANCHOR SERVICES                              */
/* -------------------------------------------------------------------------- */

import {IconsService} from './services/icons/icons.service';
import {SecurityService} from './services/security/security.service';
import { AddressService } from './services/address/address.service';
import { FlatService } from './services/flat/flat.service';
import { UtilService } from './services/util/util.service';
import { IconService } from './services/icon/icon.service';

import { EditarSeguridadAreaModalComponent } from './admin/reporte-seguridad/reporte-seguridad-area/editar-seguridad-area-modal/editar-seguridad-area-modal.component';
import { AgregarSeguridadDepartamentoComponent } from './admin/reporte-seguridad/reporte-seguridad-departamento/agregar-seguridad-departamento/agregar-seguridad-departamento.component';
import { EditarSeguridadDepartamentoComponent } from './admin/reporte-seguridad/reporte-seguridad-departamento/editar-seguridad-departamento/editar-seguridad-departamento.component';
import { DetalleAreaSeguridadComponent } from './admin/reporte-seguridad/reporte-seguridad-area/detalle-area-seguridad/detalle-area-seguridad.component';
import { GeneralAreaSeguridadComponent } from './admin/reporte-seguridad/reporte-seguridad-area/detalle-area-seguridad/general-area-seguridad/general-area-seguridad.component';
import { ServiciosAreaSeguridadComponent } from './admin/reporte-seguridad/reporte-seguridad-area/detalle-area-seguridad/servicios-area-seguridad/servicios-area-seguridad.component';
import { CrearServicioAreaComponent } from './admin/reporte-seguridad/reporte-seguridad-area/detalle-area-seguridad/servicios-area-seguridad/crear-servicio-area/crear-servicio-area.component';
import { EditarServicioAreaComponent } from './admin/reporte-seguridad/reporte-seguridad-area/detalle-area-seguridad/servicios-area-seguridad/editar-servicio-area/editar-servicio-area.component';

import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { EditUserWatcherModalComponent } from './admin/usuarios/usuario-vigilante/edit-user-watcher-modal/edit-user-watcher-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    UsuariosComponent,
    LoginComponent,
    AreasComunesComponent,
    TorresComponent,
    DepartamentosComponent,
    DepartamentosTablasComponent,
    AgregarUsuarioComponent,
    AgregarAreaComponent,
    AgregarTorresComponent,
    AgregarDepartamentosComponent,
    ServiciosComponent,
    RegistroUsuarioComponent,
    NotificacionesComponent,
    AgregarServicioComponent,
    CondominiosComponent,
    AdministradoresComponent,
    ReservacionesComponent,
    SoporteComponent,
    AgregarSoporteComponent,
    AgregarCondoComponent,
    AgregarAdminComponent,
    GestionServiciosComponent,
    AgregarGestionServiciosComponent,
    SeleccionarTorreComponent,
    ReservacionesPendientesComponent,
    ReservacionesHistorialComponent,
    AgregarOperativoComponent,
    UsuariosOperativosComponent,
    ModalReservacionComponent,
    EditarUserComponent,
    SolicitudesComponent,
    PrivacyTermsComponent,
    SuperAdminComponent,
    WelcomeComponent,
    VerAvisosComponent,
    EditarAreaComponent,
    EditarServicioComponent,
    EditarTorresComponent,
    EditarDepartamentosComponent,
    IconsComponent,
    ForgotPasswordComponent,
    IconNotificationsComponent,
    BoardsComponent,
    ReporteSeguridadComponent,
    SelectUsuarioComponent,
    UsuarioVigilanteComponent,
    ModalAgregarVigilanteComponent,
    ModalEditarVigilanteComponent,
    DetalleReservacionComponent,
    VisitasComponent,
    ListaVisitasComponent,
    ListaVisitasHistorialComponent,
    AgregarSeguridadComponent,
    EditarSeguridadComponent,
    GestionSeguridadComponent,
    AgregarGestionSeguridadComponent,
    ListaGestionSeguridadComponent,
    ModalAgregarReporteComponent,
    ModalDetalleReporteComponent,
    ToastsContainer,
    ModalDescargarReporteComponent,
    ReporteSeguridadAreaComponent,
    ReporteSeguridadDepartamentoComponent,
    AdministracionSeguridadModalAreaComponent,
    EditarSeguridadAreaModalComponent,
    AgregarSeguridadDepartamentoComponent,
    EditarSeguridadDepartamentoComponent,
    DetalleAreaSeguridadComponent,
    GeneralAreaSeguridadComponent,
    ServiciosAreaSeguridadComponent,
    CrearServicioAreaComponent,
    EditarServicioAreaComponent,
    EditUserWatcherModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    AngularFireStorageModule,
    NgSelectModule,
    AngularFireMessagingModule,
  ],
  providers: [
    AngularFirestore, 
    AngularFireAuth, 
    FirebaseAuthService,
    FirestoreService, 
    IconsService,
    NgbActiveModal,
    SecurityService,
    ScreenTrackingService,
    AddressService,
    FlatService,
    UtilService,
    IconService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
