import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  checkoutForm;
  public url;
  public downloadURL;
  public urlImagen;
  public register = false;
  public imageReference;
  public typeFile: any;

  public currentFile;

  public idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');

  constructor(
    private firestoreService: FirestoreService, 
    private router: Router, 
    private formBuilder: FormBuilder, 
    private storage: AngularFireStorage,
  ) {
    this.checkoutForm = this.formBuilder.group({
      titulo: '',
      url: '',
      fechaAlta: '',
      descripcion: ''
    });
  }

  ngOnInit(): void {

  }

  setFile(e) {
    this.currentFile = e.target.files[0];
  }

  onSubmit(data) {

    try{
      const id = moment().valueOf();
      let _aux = this.currentFile.name.split('.');
      let format = _aux[_aux.length - 1];
      this.typeFile = format;
      const filePath = `condominios/${this.idCondominio}/images/avisos/notificacion_${id}.${format}`;
      console.log(filePath);
      const ref = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.currentFile);
      this.url = filePath;
      task.snapshotChanges()
      .pipe(
        finalize(() => {
          this.imageReference = filePath;
          console.log(filePath);
          
          this.downloadURL = ref.getDownloadURL();
          this.downloadURL.subscribe(downloadURLResponse => {
            this.urlImagen = downloadURLResponse;

            data.imageRef = this.imageReference;
            data.url = this.urlImagen;
            data.fechaAlta = new Date();
            data.formato = this.typeFile;
            this.firestoreService.createAviso(data).then(() => {
              this.router.navigate(['/avisos']);
            }, (error) => {
              console.error(error);
            });
            this.register = true;
          });
        }),
      )
      .subscribe();
    } catch{
      console.log(console.error());
    }
    
  }

}
