import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';
import { FlatService } from 'src/app/services/flat/flat.service';
import * as moment from 'moment';


@Component({
  selector: 'app-agregar-torres',
  templateUrl: './agregar-torres.component.html',
  styleUrls: ['./agregar-torres.component.css']
})
export class AgregarTorresComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  checkoutForm;
  public torre: any;
  public departamento: any;
  public downloadURL;
  public urlImagen: string;
  public fileSelected: any;
  public loading: boolean = false;
  public idCondominium = localStorage.getItem('condominio').replace(/['"]+/g, '');

  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }

  constructor(
    private firestoreService: FirestoreService, 
    private calendar: NgbCalendar, 
    private formBuilder: FormBuilder, 
    private router: Router, 
    private storage: AngularFireStorage,
    private flatService: FlatService,
  ) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      status: '',
      imagen: ['']

    });
  }

  ngOnInit(): void {
  }

  async onSubmit() {
    // customerData.url = this.urlImagen;
    // customerData.status = true;
    // this.firestoreService.createTower(customerData).then(() => {
    // }, (error) => {
    //   console.error(error);
    // });
    // this.router.navigate(['/torres']);

    console.log(this.checkoutForm);

    if(this.fileSelected == null){
      console.log('no se pudo');
      return;
    }

    console.log('si se pudo');

    this.loading = true;

    try{
      let docFlat = await this.flatService.createFlat({
        nombre: this.checkoutForm.value.nombre.trim(), 
        url: '',
      });

      console.log('segun se subio');

      console.log(docFlat.id);

      let split = this.fileSelected.name.split('.');
      let fileName = `${docFlat.id}.${split[split.length - 1].toLowerCase()}`;
      let filePath = `images/condominiums/${this.idCondominium}/flats/${docFlat.id}/${fileName}`;
      let ref = this.storage.ref(filePath);
      let task = this.storage.upload(filePath, this.fileSelected);
      task.snapshotChanges()
        .pipe(
            finalize(() => {
              this.downloadURL = ref.getDownloadURL();
              this.downloadURL.subscribe( async downloadURLResponse => {
                this.urlImagen = downloadURLResponse;
                await docFlat.update({
                  'url': downloadURLResponse,
                  'filePath': filePath
                });

                this.loading = false;

                this.router.navigate(['/torres']);
              });
            }),
          )
        .subscribe();
      
      this.loading = false;
    }

    catch(error) {
      console.log(console.error());
      this.loading = false;
    }
  }

  onUpload(e) {
    // try {
    //   const id = Math.random().toString(36).substring(2);
    //   const file = e.target.files[0];
    //   const filePath = `images/notificacion_${id}`;
    //   const ref = this.storage.ref(filePath);
    //   const task = this.storage.upload(filePath, file);
    //   task.snapshotChanges()
    //     .pipe(
    //       finalize(() => {
    //         this.downloadURL = ref.getDownloadURL();
    //         this.downloadURL.subscribe(downloadURLResponse => {
    //           this.urlImagen = downloadURLResponse;
    //         });
    //       }),
    //     )
    //     .subscribe();
    // } catch {
    //   console.log(console.error());
    // }

    this.fileSelected = e.target.files[0];

  }

}

