<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <table class="table table-striped text-center">
              <thead>
                <tr class="bg-secondary">
                  <th>Nombre</th>
                  <th>Estatus</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let service of area.services; index as i; first as isFirst">
                    <td class="align-middle">
                        <img src="{{service.icon}}" width="5%">
                      {{service.name}}
                    </td>
                    <td class="align-middle">
                        <div class="d-flex justify-content-center">
                            <div class="checkbox checkbox-slider--b-flat checkbox-slider-md">
                                <label>
                                    <input type="checkbox" class="" [(ngModel)]="service.status" (change)="changeValueStatus(service, i)" [checked]="service.status"><span class="m-0"></span>
                                </label>
                            </div>
                        </div>
                    </td>
                    <td class="align-middle">
                        <ng-template #tipContentEdit>Editar</ng-template>
                        <button class="btn" [ngbTooltip]="tipContentEdit"><i
                            class="material-icons">edit</i>
                        </button>

                        <ng-template #tipContentDelete>Borrar</ng-template>
                        <button class="btn" [ngbTooltip]="tipContentDelete" (click)="deleteService(service, i)"><i
                            class="material-icons text-danger">delete</i>
                        </button>
                  </td>
                </tr>
                <tr *ngIf="loading">
                    <td colspan="3">Cargando...</td>
                  </tr>
                <tr *ngIf="area.services.length == 0 && !loading">
                  <td colspan="3">No hay servicios para esta área</td>
                </tr>
              </tbody>

            </table>
          </div>
    </div>
</div>
<button class="btn btn-lg position-fixed m-5 rounded-circle bg-success shadow" style="bottom: 0px; right: 0px;" (click)="openModalCreateNewService()">
    <i class="material-icons text-white mt-2">add</i>
</button>
