<div class="table100">
    <h1>Departamentos</h1>
    <table id="customers">
        <tr>
            <th>Departamento</th>
            <th>Fecha Alta</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr
            *ngFor="let department of departments | slice: (page-1) * pageSize : page * pageSize; index as i; first as isFirst">
            <td>{{department.nombre}}</td>
            <td>{{department.fechaAlta.seconds*1000 | date:'dd/MM/yyyy'}}</td>
            <td>{{department.status ? "Activo" : "Inactivo"}}</td>
            <td class="align-icon">
                <ng-template #edit>Editar</ng-template>
                <ng-template #editE>Activar/Desactivar</ng-template>
                <ng-template #deleteU>Borrar</ng-template>
                <ng-template #ver>Ver usuarios</ng-template>
                <i [ngbTooltip]="edit" (click)="editDept(department.id, department.nombre)" class="fa fa-pencil-square"
                    aria-hidden="true"></i>
                <i [ngbTooltip]="editE" (click)="update(department.id,departments[i])" class="fa fa-pencil-square-o"
                    aria-hidden="true"></i>
                <i [ngbTooltip]="deleteU" (click)="delete(department.id)" class="fa fa-trash" aria-hidden="true"></i>
                <i [ngbTooltip]="ver" (click)="users(department.id, department.nombre)" class="fa fa-users"
                    aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <br>
    <div class="col-12 d-flex justify-content-center">
        <ngb-pagination [(page)]="page" [pageSize]="pageSize"
            [collectionSize]="departments.length">
        </ngb-pagination>
    </div>
    <br>
    <div class="col-sm-12">
        <button (click)="newD()" class="button">
            Nuevo departamento
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
    </div>
</div>


<div class="table100">
    <h1>Usuarios de la torre {{torre.nombreTorre}} del departamento {{nameDept}} </h1>
    <table id="customers">
        <tr>
            <th>Nombre completo</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr *ngFor="let user of usersById; index as i; first as isFirst">
            <!-- <label *ngIf="usersById[i] =! null ">No hay usuarios en el departamento</label> -->
            <td>{{user.nombre}}</td>
            <td *ngIf="user.status">Activo</td>
            <td *ngIf="!user.status">Inactivo</td>
            <td class="align-icon">
                <ng-template #baja>Desvincular usuario</ng-template>
                <ng-template #inactivo>Activar/Desactivar</ng-template>
                <i [ngbTooltip]="inactivo" (click)="update(user.key,usersById[i])" class="fa fa-pencil-square-o"
                    aria-hidden="true"></i>
                <i [ngbTooltip]="baja" (click)="delete(user.key,user.usuario)" class="fa fa-ban" aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <hr>
</div>

<div class="table100">
    <h1>Solicitudes Pendientes </h1>
    <table id="customers">
        <tr>
            <th>Torre</th>
            <th>Departamento</th>
            <th>E-mail</th>
            <th>Nombre</th>
            <th>Fecha Alta</th>
            <th>Acción</th>

        </tr>
        <tr *ngFor="let request of requests; index as i; first as isFirst">
            <td>{{request.torre}}</td>
            <td>{{request.departamento}}</td>
            <td>{{request.correo}}</td>
            <td>{{request.nombre}}</td>
            <td>{{request.fechaAlta.seconds*1000 | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td class="align-icon">
                <ng-template #baja>Desvincular usuario</ng-template>
                <ng-template #inactivo>Activar/Desactivar</ng-template>
                <button class="btn btn-danger mr-1 mb-1" (click)="rejected(request)" type="button">Rechazar</button>
                <button class="btn btn-success mb-1" (click)="approved(request)" type="button">Aceptar</button>
            </td>
        </tr>

    </table>
</div>