<head>
    <title>CondoClick • Reportes de Seguridad</title>
</head>
<div class="container-fluid">
    <div class="row">
      <div class="col p-0">
        <ul ngbNav #nav="ngbNav" class="nav nav-pills mb-3 tab-material" id="pills-tab" role="tablist">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="btn pills-tab bg-white text-black">Áreas</a>
              <ng-template ngbNavContent>
                  <app-reporte-seguridad-area></app-reporte-seguridad-area>
              </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="btn pills-tab bg-white text-black">Departamento</a>
            <ng-template ngbNavContent>
                <app-reporte-seguridad-departamento></app-reporte-seguridad-departamento>
            </ng-template>
          </li>
        </ul>
      </div>
  
    </div>
</div>
    
<div [ngbNavOutlet]="nav" class="mt-4"></div>