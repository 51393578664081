import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map, finalize } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorage } from '@angular/fire/storage';

import { IconService } from 'src/app/services/icon/icon.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';

import { Icon } from 'src/app/models/icon/icon.model';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.css']
})
export class IconsComponent implements OnInit {

  public icons: Array<Icon> = [];
  closeResult = '';
  public downloadURL;
  public urlImagen: string;
  public checkoutForm: FormGroup;

  public fileSelected: any;
  public loading: boolean = false;

  constructor(
    private firestoreService: FirestoreService, 
    private router: Router, 
    private formBuilder: FormBuilder, 
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private storage: AngularFireStorage,
    private storageService: StorageService,
    private iconService: IconService,
    private toastService: ToastService,
  ) {
    this.checkoutForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      icon: [''],
    });
  }

  ngOnInit(): void {
    this.getIcons();
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION deleteIcon                            */
  /* -------------------------------------------------------------------------- */

  async deleteIcon(icon){
    console.log(icon);

    try{
      await this.iconService.deleteIcon(icon);
      this.toastService.show(`Icono borrado exitosamente`, { classname: 'bg-success text-light'});
    }

    catch(error){
      this.toastService.show(`${error}`, { classname: 'bg-danger text-light'});
    }

  }

  async onSubmit() {

    if(this.checkoutForm.invalid || this.fileSelected == null){

      this.toastService.show(`Faltan datos`, { classname: 'bg-danger text-light'});
      
      return;
    }

    try{
      this.loading = true;

      let docIcon = await this.iconService.createIcon(
        this.checkoutForm.value.name.trim()
      );

      console.log(docIcon.id);
      
      let split = this.fileSelected.name.split('.');
      let fileName = `${docIcon.id}.${split[split.length - 1].toLowerCase()}`;
      let filePath = `images/icons/${fileName}`;

      this.storageService.uploadImage(
        {
          filePath: filePath, 
          file: this.fileSelected
        }, async (data) => {
          
          await this.iconService.updateIcon(
            docIcon.id,
            {
              "image": data,
              "filePath": filePath
            }
          );

          this.toastService.show(`Icono agregado exitosamente`, { classname: 'bg-success text-light'});

          this.checkoutForm.reset();
          this.getDismissReason(null);
          this.modalService.dismissAll();

          this.loading = false;
          
        }
      );
    }

    catch(error){

      console.log(error);
      

      this.toastService.show(`${error}`, { classname: 'bg-danger text-light'});
    }

    // this.firestore.collection('/Iconos').add({
    //   "image": this.urlImagen,
    //   "icon": areaData.name,
    //   "name": areaData.name,
    // }).then(() => {
    //   this.checkoutForm.reset();
    //   this.getDismissReason(null);
    //   this.modalService.dismissAll();
    // })
  }

  onUpload(e) {

    this.fileSelected = e.target.files[0];

    // this.storageService.uploadImage({filePath: '', file: ''}, (data) => {
    //   console.log(data);
      
    // })

    // try {
    //   const id = Math.random().toString(36).substring(2);
    //   const file = e.target.files[0];

    //   let _aux = e.target.files[0].name.split('.');

    //   // console.log(_aux.length);

    //   // console.log(_aux[_aux.length - 1]);

    //   let format = _aux[_aux.length - 1];
    //   this.checkoutForm.name = _aux[0];
       
    //   // return;
    //   const filePath = `images/icons/icon_${id}.${format}`;
    //   const ref = this.storage.ref(filePath);
    //   const task = this.storage.upload(filePath, file);
    //   task.snapshotChanges()
    //     .pipe(
    //       finalize(() => {
    //         this.downloadURL = ref.getDownloadURL();
    //         this.downloadURL.subscribe(downloadURLResponse => {
    //           this.urlImagen = downloadURLResponse;
    //         });
    //       }),
    //     )
    //     .subscribe();
    // } catch {
    //   console.log(console.error());
    // }

  }

  getIcons(){
    this.firestore.collection('/Iconos', ref => 
      ref.orderBy('name')
    )
    .snapshotChanges()
    .pipe(
      map(
        icons => icons.map(
          // c => ({key: c.payload.doc.id, ... c.payload.doc.data() as DocumentChangeAction<any>})
          // c => new Icon(c.payload.doc)
          c => new Icon(
            c.payload.doc.id, c.payload.doc.data()['name'],
            c.payload.doc.data()['image'], c.payload.doc.data()['icon'],
            c.payload.doc.data()['filePath'], c.payload.doc.ref
          )
        )
      )
    ).subscribe(icons => {
      this.icons = icons;
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
