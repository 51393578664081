import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {

  private user: Observable<firebase.User | null>;
  public ruta = environment.logged.isLogedIn;

  constructor(
    private afAuth: AngularFireAuth, 
    private _http: HttpClient
  ) { 
    this.user = this.afAuth.authState; 
  }

  async loginGoogle(): Promise<any> {
    try {
      const { user } = await this.afAuth.signInWithPopup(
        new auth.GoogleAuthProvider()
      );
      return user;
    } catch (error) {
      console.log(error);
    }
  }

  async loginFace(): Promise<any> {
    try {
      var provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider);
      const { user } = await this.afAuth.signInWithPopup(
        new auth.FacebookAuthProvider()
      );
      return user;
    } catch (error) {
      console.log(error);
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  async login(email: string, password: string): Promise<any> {
    try {
      const { user } = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      return user;
    } catch (error) {
      console.log(error);
    }
  }

  async register(email: string, password: string): Promise<any> {
    try {
      console.log(email, password)
      const { user } = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      await this.sendVerificationEmail();
      return user;
    } catch (error) {
      console.log("error", error);
    }
  }

  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
    } catch (error) {
      console.log(error);
    }
  }

  // private updateUserData(user: User) {
  //   const userRef: AngularFirestoreDocument<User> = this.afs.doc(
  //     `users/${user.uid}`
  //   );

  //   const data: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     emailVerified: user.emailVerified,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     role: 'ADMIN',
  //   };

  //   return userRef.set(data, { merge: true });
  // }

  sendMail(obj): Observable<any> {
    let params = JSON.stringify(obj);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post("https://us-central1-condb-b725c.cloudfunctions.net/mailer", params, { headers: headers });
  };

  getColonias(cp): Observable<any> {
    return this._http.get('https://api-sepomex.hckdrk.mx/query/get_colonia_por_cp/' + cp);
  };

  getEstados(): Observable<any> {
    return this._http.get('https://api-sepomex.hckdrk.mx/query/get_estados');
  };

  getMunicipios(estado): Observable<any> {
    return this._http.get('https://api-sepomex.hckdrk.mx/query/get_municipio_por_estado/' + estado);
  };

  sendNotification(obj): Observable<any> {
    let params = JSON.stringify(obj);
    let headers = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'key=AAAANHpxeDE:APA91bGmxiAJ2Kk1wAQGPqPGOTsB39Fburh2XbnonPvOoIJArMZXXNeBt7nvxhWEGJFyaZz-tO4P4AySRJA02UBl8HGQR9AvFcImbbIt1ekNGl2e0_JbnPqMxUL7u3haJa9OpHI-O7E9'
    });
    return this._http.post("https://fcm.googleapis.com/fcm/send", params, { headers: headers });
  };
}
