import { 
    QueryDocumentSnapshot,
    DocumentReference
} from "@angular/fire/firestore";

export class Icon {
    public id: string;
    public name: string;
    public image: string;
    public icon: string;
    public filePath: string;
    public ref: DocumentReference;

    // constructor(doc: QueryDocumentSnapshot<unknown>){
    //     // console.log(doc.data());
        
    //     this.id = doc.id;
    //     this.name = doc.data()['name'];
    //     this.icon = doc.data()['icon'];
    //     this.image = doc.data()['image'];
    //     this.filePath = doc.data()['filePath'];
    //     this.ref = doc.ref;
        
    // }

    constructor(
        id: string,
        name: string,
        image: string,
        icon: string,
        filePath: string,
        ref: DocumentReference,
    ){
        this.id = id;
        this.name = name;
        this.image = image;
        this.icon = icon;
        this.filePath = filePath;
        this.ref = ref;

        // console.log(this);
    }

    toFirebase(): any {
        return {
          "image": this.image,
          "icon": this.icon,
          "name": this.name,
          "filePath": this.filePath,
        }
      }
}
