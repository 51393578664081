import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { analytics } from 'firebase';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css']
})
export class SolicitudesComponent implements OnInit {

  public idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public requests = [];

  constructor(
    private firestore: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.getRequests();
  }

  getRequests() {
    this.firestore.collection(`/Departamentos`, ref =>
      ref.where('status', '==', false)
        .where('idCondominio', '==', this.idCondominio)
        .orderBy('fechaAlta')
    )
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c => {
            // console.log(c.payload.doc.data());

            return ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
          }
        )
      ))
      .subscribe(req => {
        this.requests = [];
        // let r = c.payload.doc.data() as any;

        //     this.firestore.collection('/Usuarios', ref => 
        //       ref.where('uid', '==', r.usuario)
        //     )
        //     .snapshotChanges()
        //     .pipe(map(docs => 
        //       docs.map(
        //         u => {
        //           console.log(u.payload.doc.data());

        //           // return ({id: u.payload.doc.id, ...u.payload.doc.data() as DocumentChangeAction<any>})
        //         }
        //       )
        //     ))
        req.map(user => {
          let _user: any = user;

          this.firestore.collection('/Usuarios', ref =>
            ref.where('uid', '==', _user.usuario)
          )
            .valueChanges()
            .subscribe(data => {
              data.forEach(e => {
                // console.log(e);
                let _auxe = e as any;

                // console.log(_auxe.email);
                this.requests.push({ ..._auxe, ...user });

              })
            })
          // .get().subscribe(data => {
          //   console.log("data", data);

          //   data.docChanges().forEach(e => {
          //     console.log(e.doc.data());

          //     let _auxe = e.doc.data();

          //     user = ({_auxe.email, ...user});
          //   })
          // })



          // .(map(docs => 
          //   docs.map(
          //     c => {
          //       // console.log('Usuario: ', c.payload.doc.data());

          //       return ({id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any>})
          //     }
          //   )
          // ))
          // .subscribe(requser => {
          //   // console.log(requser);

          //   let _requser: any = requser;

          //   if(requser.length > 0){
          //     return ({email: _requser[0].email, ...user});
          //   }

          // })

        })
      });
  }

  approved(flat) {

    this.firestore.collection('/Usuarios', ref =>
      ref.where('uid', '==', flat.usuario)
    )
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c => {
            return ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
          }
        )
      ))
      .subscribe(user => {
        // console.log(user);
        // this.reservations = reser;
        if (user.length > 0) {
          let u = user[0] as any;
          flat.status = true;
          this.firestore.collection('/Departamentos').doc(flat.id).set(flat).then(() => {

            this.firestore.collection(`/Condominios/${flat.idCondominio}/Torres/${flat.idTorre}/Departamentos/${flat.idDepartamento}/Usuarios`)
              .add({
                'usuario': flat.usuario,
                'status': true,
                'fechaAlta': new Date(),
                'apellido': '',
                'nombre': u.nombre,
              })

            // this.firestore.collection('/Usuarios', ref => 
            //   ref.where('uid', '==', flat.usuario)
            // )
            // .snapshotChanges()
            // .pipe(map(docs => 
            //   docs.map(
            //     c => ({id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any>})
            //   )
            // ))
            // .subscribe(req =>
            // {
            //   console.log(req);
            //   // this.reservations = reser;
            //   if(re)
            // });
          });
        }
      });
  }

  rejected(flat) {
    // console.log(flat);
    if (confirm("¿Desea borrar solicitud?")) {
      this.firestore.collection('/Departamentos').doc(flat.id).delete();
    }
    // else {
    //   console.log("No");

    // }
  }

}