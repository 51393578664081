import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, CollectionReference, DocumentReference } from '@angular/fire/firestore';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firestore } from 'firebase';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'app-modal-detalle-reporte',
  templateUrl: './modal-detalle-reporte.component.html',
  styleUrls: ['./modal-detalle-reporte.component.css']
})
export class ModalDetalleReporteComponent implements OnInit {

  @Input() report: any;

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore, 
    private modalService: NgbModal,
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
    // console.log(this.report);
    
  }

  changeStatus(){
    let ref = this.report.ref as CollectionReference;

    console.log(ref.path);
    
    this.firestore.doc(ref.path).update({
      "status": this.report.status == 0 ? 1 : 2,
      "fechaCierre": this.report.status == 1 ? firestore.Timestamp.now() : null,
    }).then((result) => {
      this.toastService.show('Reporte cambió de estado', { classname: 'bg-success text-light'});
      this.close('');
    })
  }

  close(content){
    this.activeModal.close(content);
  }

  deleteReport(){
    if(window.confirm(`¿Desea borrar reporte?`)){
      let ref = this.report.ref as DocumentReference;
      ref.delete()
      .then(() => {
        this.close('');
        this.toastService.show('¡Reporte borrado exitosamente!', { classname: 'bg-success text-light'});
      })
      .catch((e) => {
        console.log(e);
        this.toastService.show('No se pudo borrar reporte', { classname: 'bg-danger text-light'});
      })
    }
  }

}
