  <head>
    <title>CondoClick • Gestión de seguridad</title>
  </head>
  
  <div class="container-fluid">
    <ul ngbNav #nav="ngbNav" class="nav nav-pills mb-3 tab-material" id="pills-tab" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink class="btn pills-tab bg-white text-black">Pendientes</a>
          <ng-template ngbNavContent>
              <!-- <app-lista-visitas [(visitors)]="soonVisitors" [(loading)]="loading" [history]="history"></app-lista-visitas> -->
              <app-lista-gestion-seguridad [(reports)]="pendingReports" [(loading)]="loading"></app-lista-gestion-seguridad>
          </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="btn pills-tab bg-white text-black">En procesos</a>
        <ng-template ngbNavContent>
          <!-- <app-lista-visitas-historial [(visitors)]="historyVisitors" [(loading)]="loading"></app-lista-visitas-historial> -->
          <app-lista-gestion-seguridad [(reports)]="inProgressReport" [(loading)]="loading"></app-lista-gestion-seguridad>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink class="btn pills-tab bg-white text-black">Concluidos</a>
        <ng-template ngbNavContent>
          <!-- <app-lista-visitas-historial [(visitors)]="historyVisitors" [(loading)]="loading"></app-lista-visitas-historial> -->
          <app-lista-gestion-seguridad [(reports)]="doneReports" [(loading)]="loading"></app-lista-gestion-seguridad>
        </ng-template>
      </li>
    </ul>
    
    <div [ngbNavOutlet]="nav" class="mt-4"></div>

    <button *ngIf="isEscribir == 'true'" class="btn btn-lg position-fixed m-5 rounded-circle bg-success shadow" style="bottom: 0px; right: 0px;" (click)="openModalCreateReport()">
      <i class="material-icons text-white mt-2">add</i>
    </button>
  </div>
  
  <div class="tab" *ngIf="isService">
    <button class="tablinks" (click)="pending()">Pendientes</button>
    <button class="tablinks" (click)="process()">En proceso</button>
    <button class="tablinks" (click)="finish()">Concluidos</button>
    <i (click)="exportTemplateAsExcel()" class="fa fa-file-excel-o fa-3x style-icon" aria-hidden="true"></i>
  </div>
  
  <div class="row" style="width: 50%;">
    <div class="w3-container">
      <div id="id01" class="w3-modal" style="width: 122%; padding-top: 96px;">
        <div class="w3-modal-content">
          <header class="w3-container w3-teal" style="background-color: #008591 !important;">
            <span onclick="document.getElementById('id01').style.display='none'"
              class="w3-button w3-display-topright">&times;</span>
            <br>
  
            <h2> <i class="fa fa-info-circle" aria-hidden="true"></i> Detalle del servicio solicitado</h2>
  
  
          </header>
          <div class="w3-container">
            <hr>
            <p><i class="fa fa-asterisk" aria-hidden="true"></i><strong> Torre: </strong>{{torreS}}</p>
            <p><i class="fa fa-asterisk" aria-hidden="true"></i><strong> Departamento: </strong>{{departamentoS}}</p>
            <p><i class="fa fa-asterisk" aria-hidden="true"></i><strong> Comentarios del cliente: </strong>{{comentarioS}}
            </p>
            <hr>
          </div>
          <!-- <footer class="w3-container w3-teal">
            <p>Modal Footer</p>
          </footer> -->
        </div>
      </div>
    </div>
  
  </div>