import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { FirebaseAuthService } from '../../services/firebase/firebase-auth.service';

@Component({
  selector: 'app-agregar-operativo',
  templateUrl: './agregar-operativo.component.html',
  styleUrls: ['./agregar-operativo.component.css']
})
export class AgregarOperativoComponent implements OnInit {

  public condos = [];
  checkoutForm;
  public downloadURL;
  public urlImagen: string;
  public nameCondo;
  public condominio = [];
  public towers = [];
  public towerKeys = [];
  public idCondominio;
  public dataMail;

  constructor(private firestoreService: FirestoreService, private router: Router, private formBuilder: FormBuilder, private storage: AngularFireStorage,  private firebaseAuth: FirebaseAuthService) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]],
      isReservacion: false,
      isServicio: false,
      isSeguridad: false,
      isEscribir: false,
      isEditar: false,
      isEliminar: false,
      torre: '',
      torresKey: []
    });
  }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getCondos();
    this.getTowersList();
    this.firestoreService.query();
  }

  selectedFileFrontal = null;

  onSubmit(form) {
    form.descripcion = "Usuario Operativo";
    form.status = true;
    form.idTipo = 3;
    form.idCondominio = this.idCondominio;
    form.torresKey = this.towerKeys;

    this.firestoreService.createAdminRootOper(form).then(() => {
      this.dataMail= {
        to: form.email,
        message: "Buenas tardes, bienvenido a condoclick, usted ha sido dado de alta por un administrador, favor de crear una cuenta, para que pueda tener acceso.",
        subject: "condoclick.com.mx",
        html:
        "<html>" +
        "<body>" +  
        "<h1>CondoClick</h1>"+
        "<p>Buenas tardes, bienvenido a condoclick, usted ha sido dado de alta por un administrador, favor de crear una cuenta, para que pueda tener acceso.<p>" +
        "<a href='condoclick.com.mx/registro'>Debe dar click aqui.</a>" +
        "</body>" +
        "</html>",

        }
        this.sendMail(this.dataMail);
        this.router.navigate(['/select-user']);
     
    }, (error) => {
      console.error(error);
    });

   
  }

  getCondos() {
    this.firestoreService.getCondosList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(condos => {
      this.condos = condos;
    });
  }

  getCondo(key) {
    this.firestoreService.getCondominio(key).get().subscribe((condo) => {
      this.condominio.push({
        nombre: condo.data()['nombre'],
        status: condo.data()['status'],
        url: condo.data()['url']
      });
    });
  }

  getTowersList() {
    this.firestoreService.getTowers().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  torres(tower, isNew) {
    if (isNew) {
      this.towerKeys.push(
        tower
      )
    } else {
      var indice = this.towerKeys.indexOf(tower);
      this.towerKeys.splice(indice, 1);
    }
  }

  sendMail(data) {
    this.firebaseAuth.sendMail(data).subscribe(
      response => {
      },
      error => {
        console.log(error);
      }
    );
  }

}
