import { Component, OnInit, Input } from '@angular/core';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  DocumentChangeAction, 
  DocumentReference
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from '../../../../services/toast/toast.service';

@Component({
  selector: 'app-edit-user-watcher-modal',
  templateUrl: './edit-user-watcher-modal.component.html',
  styleUrls: ['./edit-user-watcher-modal.component.css']
})
export class EditUserWatcherModalComponent implements OnInit {

  @Input() public id;

  public formWatcher: FormGroup;
  public submitted: boolean = false;
  public watchman: any;

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    public toastService: ToastService,
  ) { 
    this.formWatcher = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getInfoWatchman();
  }

  close(content){
    this.activeModal.close(content);
  }

  get f(){ 
    return this.formWatcher.controls; 
  }

  getInfoWatchman(){
    this.firestore.collection('/Administradores')
    .doc(this.id).get().subscribe(data => {
      this.watchman = {
        'id': data.id,
        'ref': data.ref,
        ...data.data()
      }

      this.formWatcher.controls['nombre'].setValue(this.watchman.nombre);
      this.formWatcher.controls['email'].setValue(this.watchman.email);
      console.log(this.watchman);
      
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.formWatcher.invalid) {
        return;
    }

    this.firestore.collection('/Administradores').doc(this.id)
    .update({
      'lastUpdate': new Date(),
      ...this.formWatcher.value
    }).then(vaalue => {
      this.toastService.show(`Vigilante actualizado exitosamente`, { classname: 'bg-success text-light'});
      this.close('');
    });

  }

}
