<head>
    <title>CondoClick • Inicio</title>
</head>

<div class="w3-container">
    <div class="row">
        <div class="col-sm-6">
            <img style="width: 100%; height: 100%;" src="../../../assets/images/condoclick.jpeg">
        </div>
        <div class="col-sm-6">
            <h1 *ngIf="isLogged"><strong>Bienvenido a CondoClick estimado {{nombre}}.</strong></h1>
            <h3 *ngIf="isLogged">Usted ha ingeresado como un {{descripcion}}.</h3>
            <h4>Aplicación web para el control, manejo, configuración y administración del condominio para el personal operativo.</h4>

            <div class="w3-container w3-padding-64 w3-theme-l5" id="contact">
                <div class="w3-row">
                    <div *ngIf="showAlert" class="alert">
                        <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
                        <strong><i class="fa fa-flag" aria-hidden="true"></i></strong> Se ha enviado su mensaje, lo
                        contactaremos a la brevedad.
                    </div>

                    <div class="w3-col m5">
                        <div class="w3-padding-16"><span
                                class="w3-xlarge w3-border-teal w3-bottombar">Contactanos</span></div>
                        <p><i class="fa fa-map-marker w3-text-teal w3-xlarge"></i>  Avenida Adolfo Ruiz Cortines,
                            Fraccionamiento Las Margaritas Mz 1 Lt 20 Atizapan de Zaragoza, 52977 Ciudad de México</p>
                        <!-- <p><i class="fa fa-phone w3-text-teal w3-xlarge"></i> +52 (55) 5084 2255</p> -->
                        <p><i class="fa fa-envelope-o w3-text-teal w3-xlarge"></i> condoclick@cleversec.com.mx</p>
                    </div>
                    <div class="w3-col m7">
                        <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm)" novalidate
                            class="w3-container w3-card-4 w3-padding-16 w3-white">
                            <div class="w3-section">
                                <label>Nombre</label>
                                <input formControlName="nombre" class="w3-input" type="text" name="Name" required>
                            </div>
                            <div class="w3-section">
                                <label>Email</label>
                                <input formControlName="email" class="w3-input" type="text" name="Email" required>
                            </div>
                            <div class="w3-section">
                                <label>Mensaje</label>
                                <input formControlName="mensaje" class="w3-input" type="text" name="Message" required>
                            </div>
                            <!-- <input formControlName="like" class="w3-check" type="checkbox" checked name="Like">
                            <label> Me gusta</label>
                            <button type="submit" [disabled]="checkoutForm.invalid"
                                class="w3-button w3-right w3-theme">Enviar</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="row">
            <div class="col-6">
                <div class="w3-display-container w3-animate-opacity">
                    <img src="../../../assets/images/condoclick.jpeg" alt="boat" style="min-height:350px;max-height:600px;">
                    <div class="w3-container w3-display-bottomleft w3-margin-bottom">
                        <button onclick="document.getElementById('id01').style.display='block'"
                            class="w3-button w3-xlarge w3-theme w3-hover-teal" title="Go To W3.CSS">Contancto Cleversec</button>
                    </div> 
                </div>
            </div>
    
            <div class="col-6">
    
    
             
                <div class="w3-row-padding w3-padding-64" id="work">
    
                    <div class="w3-quarter">
                        <h2>Bienvenido a CondoClick</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip
                            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                            dolore eu
                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.</p>
                    </div>
    
                    <div class="w3-quarter">
                        <div class="w3-card w3-white">
                            <img src="../../../assets/images/Condominio.jpg" alt="Snow" style="width:100%">
                            <div class="w3-container">
                                <h3>Customer 1</h3>
                                <h4>Trade</h4>
                                <p>Blablabla</p>
                                <p>Blablabla</p>
                                <p>Blablabla</p>
                                <p>Blablabla</p>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
    
        
            <div id="id01" class="w3-modal">
                <div class="w3-modal-content w3-card-4 w3-animate-top">
                    <header class="w3-container w3-teal w3-display-container">
                        <span onclick="document.getElementById('id01').style.display='none'"
                            class="w3-button w3-teal w3-display-topright"><i class="fa fa-remove"></i></span>
                        <h4>Oh snap! We just showed you a modal..</h4>
                        <h5>Because we can <i class="fa fa-smile-o"></i></h5>
                    </header>
                    <div class="w3-container">
                        <p>Cool huh? Ok, enough teasing around..</p>
                        <p>Go to our <a class="w3-text-teal" href="/w3css/default.asp">W3.CSS Tutorial</a> to learn more!
                        </p>
                    </div>
                    <footer class="w3-container w3-teal">
                        <p>Modal footer</p>
                    </footer>
                </div>
            </div>
    
    
        </div> -->


    <!-- 
    <div class="w3-container w3-padding-64 w3-center" id="team">
        <h2>Cleversec</h2>

        <div class="w3-row"><br>

            <div class="">
                <img src="/w3images/avatar.jpg" alt="Boss" style="width:45%" class="w3-circle w3-hover-opacity">
                <h3>Johnny Walker</h3>
                <p>Web Designer</p>
            </div>

        </div>
    </div> -->

    <!-- Footer -->
    <div class="row">
        <div class="col-12">
            <div class="color-footer">
                <footer style="background-color: #1d1919 !important; color: #fff !important;"
                    class="w3-container w3-padding-32 w3-center">
                    <!-- <h4>Síguenos</h4>
                    <div class="col-12">
                        <a (click)="done()" style="background-color: #008591 !important; color: #fff !important;"
                            class="w3-button w3-large w3-teal" href="javascript:void(0)" title="Facebook"><i
                                class="fa fa-facebook"></i></a>
                        <a style="background-color: #008591 !important; color: #fff !important;"
                            class="w3-button w3-large w3-teal" href="javascript:void(0)" title="Twitter"><i
                                class="fa fa-twitter"></i></a>
                        <a style="background-color: #008591 !important; color: #fff !important;"
                            class="w3-button w3-large w3-teal" href="javascript:void(0)" title="Google +"><i
                                class="fa fa-google-plus"></i></a>
                        <a style="background-color: #008591 !important; color: #fff !important;"
                            class="w3-button w3-large w3-teal" href="javascript:void(0)" title="Google +"><i
                                class="fa fa-instagram"></i></a>
                        <a style="background-color: #008591 !important; color: #fff !important;"
                            class="w3-button w3-large w3-teal w3-hide-small" href="javascript:void(0)"
                            title="Linkedin"><i class="fa fa-linkedin"></i></a>
                    </div> -->

                </footer>
            </div>
        </div>

    </div>



    <!-- <div class="w3-container" style="position:relative">
        <a onclick="w3_open()" class="w3-button w3-xlarge w3-circle w3-teal"
            style="position:absolute;top:-28px;right:24px">+</a>
    </div>
    
    <div class="w3-row-padding w3-center w3-padding-64" id="pricing">
        <h2>PRICING</h2>
        <p>Choose a pricing plan that fits your needs.</p><br>
        <div class="w3-third w3-margin-bottom">
            <ul class="w3-ul w3-border w3-hover-shadow">
                <li class="w3-theme">
                    <p class="w3-xlarge">Basic</p>
                </li>
                <li class="w3-padding-16"><b>10GB</b> Storage</li>
                <li class="w3-padding-16"><b>10</b> Emails</li>
                <li class="w3-padding-16"><b>10</b> Domains</li>
                <li class="w3-padding-16"><b>Endless</b> Support</li>
                <li class="w3-padding-16">
                    <h2 class="w3-wide"><i class="fa fa-usd"></i> 10</h2>
                    <span class="w3-opacity">per month</span>
                </li>
                <li class="w3-theme-l5 w3-padding-24">
                    <button class="w3-button w3-teal w3-padding-large"><i class="fa fa-check"></i> Sign Up</button>
                </li>
            </ul>
        </div>
    
        <div class="w3-third w3-margin-bottom">
            <ul class="w3-ul w3-border w3-hover-shadow">
                <li class="w3-theme-l2">
                    <p class="w3-xlarge">Pro</p>
                </li>
                <li class="w3-padding-16"><b>25GB</b> Storage</li>
                <li class="w3-padding-16"><b>25</b> Emails</li>
                <li class="w3-padding-16"><b>25</b> Domains</li>
                <li class="w3-padding-16"><b>Endless</b> Support</li>
                <li class="w3-padding-16">
                    <h2 class="w3-wide"><i class="fa fa-usd"></i> 25</h2>
                    <span class="w3-opacity">per month</span>
                </li>
                <li class="w3-theme-l5 w3-padding-24">
                    <button class="w3-button w3-teal w3-padding-large"><i class="fa fa-check"></i> Sign Up</button>
                </li>
            </ul>
        </div>
    
        <div class="w3-third w3-margin-bottom">
            <ul class="w3-ul w3-border w3-hover-shadow">
                <li class="w3-theme">
                    <p class="w3-xlarge">Premium</p>
                </li>
                <li class="w3-padding-16"><b>50GB</b> Storage</li>
                <li class="w3-padding-16"><b>50</b> Emails</li>
                <li class="w3-padding-16"><b>50</b> Domains</li>
                <li class="w3-padding-16"><b>Endless</b> Support</li>
                <li class="w3-padding-16">
                    <h2 class="w3-wide"><i class="fa fa-usd"></i> 50</h2>
                    <span class="w3-opacity">per month</span>
                </li>
                <li class="w3-theme-l5 w3-padding-24">
                    <button class="w3-button w3-teal w3-padding-large"><i class="fa fa-check"></i> Sign Up</button>
                </li>
            </ul>
        </div>
    </div> -->

</div>