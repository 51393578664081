<div class="table100">
    <table id="customers">
        <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Descripción</th>
            <th>Reservaciones</th>
            <th>Servicios</th>
            <th>Editar</th>
            <th>Escribir</th>
            <th>Eliminar</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr *ngFor="let user of users; index as i; first as isFirst">
            <td>{{user.nombre}}</td>
            <td>{{user.email}}</td>
            <td>{{user.descripcion}}</td>
            <td *ngIf="user.isReservacion == true">Activo</td>
            <td *ngIf="user.isReservacion == false">Inactivo</td>
            <td *ngIf="user.isServicio == true">Activo</td>
            <td *ngIf="user.isServicio == false">Inactivo</td>
            <td *ngIf="user.isEditar == true">Activo</td>
            <td *ngIf="user.isEditar == false">Inactivo</td>
            <td *ngIf="user.isEscribir == true">Activo</td>
            <td *ngIf="user.isEscribir == false">Inactivo</td>
            <td *ngIf="user.isEliminar == true">Activo</td>
            <td *ngIf="user.isEliminar == false">Inactivo</td>
            <td *ngIf="user.status == true">Activo</td>
            <td *ngIf="user.status == false">Inactivo</td>
            <td class="align-icon">
                <ng-template #editar>Editar usuario</ng-template>
                <ng-template #borrar>Eliminar usuario</ng-template>
                <i [ngbTooltip]="editar" [routerLink]="['/update-oper', user.email ]" class="fa fa-pencil-square-o"
                    aria-hidden="true"></i>
                <i [ngbTooltip]="borrar" (click)="delete(user.id, user)" class="fa fa-trash" aria-hidden="true"></i>

            </td>
        </tr>

    </table>
    <br>
    <div class="col-sm-12">
        <button routerLink="/oper-form" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
    </div>
</div>