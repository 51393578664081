<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-12">
                <label>Nombre</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>
            <!-- <div class="col-sm-12">
                <label>Fecha Alta  </label>
                <input formControlName="fechaAlta" type="date" id="birthday" name="birthday">
            </div> -->

            <!-- <div class="col-sm-6 form-group">
                <label>Icono</label>
                <ng-select [items]="icons" [searchable]="false" [clearable]="true" (change)="setIcon($event)" name="icon">
                    <ng-template ng-label-tmp let-item="item">
                        <span class="mx-1">{{item.name}}</span>
                        <img src="{{item.image}}" width="20px" height="20px"/>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <img src="{{item.image}}" width="20px" height="20px"/>
                        <span class="mx-1">{{item.name}}</span>
                    </ng-template>
                </ng-select>
            </div> -->


            <div class="col-sm-12">
                <input [disabled]="checkoutForm.invalid" type="submit" value="Guardar" class="w-100">

            </div>

           
        </div>
    </div>

</form>