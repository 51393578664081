import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-agregar-soporte',
  templateUrl: './agregar-soporte.component.html',
  styleUrls: ['./agregar-soporte.component.css']
})
export class AgregarSoporteComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: {year: number, month: number};
  checkoutForm;
  public torre : any;
  public departamento: any;
  
  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }
  constructor(private firestoreService: FirestoreService, private calendar: NgbCalendar, private formBuilder: FormBuilder, private router: Router) { 
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      descripcion:  ['', [Validators.required]],
      status: true
    });
  }

  ngOnInit(): void {
  }

  onSubmit(customerData) {
    this.firestoreService.createSupport(customerData).then(() => {
    }, (error) => {
      console.error(error);
    });
    this.router.navigate(['/soporte']);
  }

}
