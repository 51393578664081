<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12">
                <label>Condominio</label>
                <select (change)="condoKey(checkoutForm.value)" formControlName="idCondominio">
                    <option *ngFor="let condo of condos; index as i; first as isFirst" [value]="condo.key" >
                        {{condo.nombre}}
                    </option>
                </select>
            </div>

            <div class="col-sm-6">
                <label>Nombre del administrador</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Correo electronico</label>
                <input id="nombre" type="text" formControlName="email"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Torres</label>
                <label *ngFor="let tower of towers; index as i; first as isFirst" class="container">{{tower.nombre}}
                    <input (change)="torres(tower.key,checkoutForm.value.torre)" formControlName="torre" type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-sm-12">
                <input [disabled]="checkoutForm.invalid" type="submit" value="Guardar">
            </div>

        </div>
    </div>
</form>