
import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { Router } from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-usuarios-operativos',
  templateUrl: './usuarios-operativos.component.html',
  styleUrls: ['./usuarios-operativos.component.css']
})
export class UsuariosOperativosComponent implements OnInit {

  public users = [];
  public towers = [];
  public condos = [];
  public isCondo = false;
  public idCondo;

  constructor(private firestoreService: FirestoreService, private router: Router, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.idCondo = localStorage.getItem('condominio');
    this.getUsers();
    this.getTowersList();
    this.getCondos();
  }

  getServices() {
    this.firestoreService.getUsersOper().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(users => {
      this.users = users;
      console.log(users);
    });
  }

  delete(key, data) {
    this.firestoreService
      .deleteAdminRoot(key)
      .catch(err => console.log(err));
  }

  update(data) {
    this.router.navigate(['/update-oper']);
  }

  getTowersList() {
    this.firestoreService.getTowersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
      console.log(towers);
    });
  }

  getCondos() {
    this.firestoreService.getCondosList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(condos => {
      this.condos = condos;
      console.log(this.condos);
    });
  }

  condoKey(key) {
    console.log(key);
    this.isCondo = true;
  }

  getAdmin(email) {
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));

    snapshotResult.subscribe(doc => {
      this.firestoreService
        .deleteAdminRoot(doc.payload.doc.id)
        .catch(err => console.log(err));
    });

  }

  getUsers() {
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('idTipo', '==', 3).where('idCondominio','==', this.idCondo))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      snapshotResult.subscribe(reser => {
        console.log("reser", reser);
        this.users = reser;
      });
  }

  // getUsers(idCondo) {
  //   console.log(idCondo);
  //   const snapshotResult = this.firestore.collection('/Administradores', ref =>
  //     ref.where('idTipo', '==', 2).where('idCondominio', '==', idCondo))
  //     .snapshotChanges()
  //     .pipe(map(docs =>
  //       docs.map(
  //         c =>
  //           ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
  //       )
  //     ))
  //   snapshotResult.subscribe(reser => {
  //     this.users = reser;
  //   });
  // }
}
