import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.css']
})
export class SuperAdminComponent implements OnInit {

  public condos = [];
  public condoChoose;

  constructor(private firestoreService: FirestoreService) { }

  ngOnInit(): void {
    this.getCondos();
    this.condoChoose = localStorage.getItem('condominio').replace(/['"]+/g, '');
  }

  getCondos() {
    this.firestoreService.getCondosList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(condos => {
      this.condos = condos;
    });
  }

  delete(key) {
    this.firestoreService
      .deleteCondos(key)
      .catch(err => console.log(err));
  }

  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateCondos(key, data)
      // .catch(err => console.log(err));
  }

  changeCondo(key){
    localStorage.setItem('condominio', key);
    this.condoChoose = localStorage.getItem('condominio').replace(/['"]+/g, '');
    console.log(key);
    window.location.reload();
  }
}
