import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  public users = [];
  public cats = [];
  public customers = [];
  public customer = [];
  public towersUsers;

  constructor(private firestoreService: FirestoreService, private router: Router, private rutaActiva: ActivatedRoute) { }

  ngOnInit(): void {
    this.getCustomersList();
  }


  getCustomersList() {
    this.firestoreService.getCustomersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(users => {
      this.users = users;
    });
  }

  deleteCustomer(key) {
    console.log(key);
    this.firestoreService
      .deleteCustomer(key)
      .catch(err => console.log(err));
  }

  agregarData(user) {
    this.router.navigate(['/usuarios-form/' + user.key + '/' + user.nombre + '/' + user.email + '/' + user.fechaAlta + '/' + user.uid]);
  } 

  activateUser(key, data){
  this.towersUsers.fechaAlta = new Date();
  this.towersUsers.status = true;
  this.towersUsers.nombre = true;
    console.log('data', data);
    // this.firestoreService.createUserOper(data).then(() => {
    // }, (error) => {
    //   console.error(error);
    // });

  //   this.firestoreService
  //     .updateUser(key, this.data)
  //     .catch(err => console.log(err));
  //   this.router.navigate(['/usuarios']);
  // }
  }

}
