export interface IReservation {
   id: string;
   area: string;
   bloque: string;
   departamento: string;
   fechaAlta: Date;
   fechaFin: Date;
   fechaInicio: Date;
   icon: string;
   idArea: string;
   idDepartamento: string;
   idTorre: string;
   os: string;
   status: boolean;
   torre: string;
   usuario: string;
}

export class Reservation {
   public id: string;
   public area: string;
   public bloque: string;
   public departamento: string;
   public fechaAlta: Date;
   public fechaFin: Date;
   public fechaInicio: Date;
   public icon: string;
   public idArea: string;
   public idDepartamento: string;
   public idTorre: string;
   public os: string;
   public status: boolean;
   public torre: string;
   public usuario: string;

   constructor(data: IReservation){
      this.id = data.id;
      this.area = data.area;
      this.bloque = data.bloque;
      this.departamento = data.departamento;
      this.fechaAlta = data.fechaAlta;
      this.fechaFin = data.fechaFin;
      this.fechaInicio = data.fechaInicio;
      this.icon = data.icon;
      this.idArea = data.idArea;
      this.idDepartamento = data.idDepartamento;
      this.idTorre = data.idTorre;
      this.os = data.os;
      this.status = data.status;
      this.torre = data.torre;
      this.usuario = data.usuario;
   }
}
