import { Component, ComponentFactoryResolver, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { FirebaseAuthService } from '../../services/firebase/firebase-auth.service';
import { ModalAgregarReporteComponent } from './modal-agregar-reporte/modal-agregar-reporte.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-gestion-seguridad',
  templateUrl: './gestion-seguridad.component.html',
  styleUrls: ['./gestion-seguridad.component.css']
})
export class GestionSeguridadComponent implements OnInit {

  public excelHeaders: string[] = ["Servicio", "Departamento", "Torre", "Fecha de alta", "Estatus"];

  public EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public EXCEL_EXTENSION = '.xlsx';

  public towers = [];
  public isService = false;
  public services = [];
  public clientRef;
  public idSolicitud;
  public keyService = [];
  public keyTower;

  public isEditar;
  public isEscribir;
  public isEliminar;
  public isPending;
  public isFinish;
  public email;
  public userData = [];
  public torresKey = [];
  public servicesFinish = [];
  public isProcess = false;
  public servicesProcess = [];
  public idCondominio;
  public comentarioS;
  public departamentoS;
  public torreS;
  public idTipo;
  public notification;
  templateToExcel: string[][] = [];
  data = [];
  public allS = [];
  pageSize: number = 20;
  page = 1;

  public allReports: Array<any>;
  public doneReports: Array<any>;
  public inProgressReport: Array<any>;
  public loading: boolean = true;
  public pendingReports: Array<any>;

  constructor(
    private firestoreService: FirestoreService, 
    private firestore: AngularFirestore, 
    private firebaseAuth: FirebaseAuthService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.isEditar = localStorage.getItem('isEditar');
    this.isEscribir = localStorage.getItem('isEscribir');
    this.isEliminar = localStorage.getItem('isEliminar');
    this.email = localStorage.getItem('email');
    this.idTipo = localStorage.getItem('userOper');

    this.getAllReports();

    // if (this.idTipo == 2 || this.idTipo == 1)
    //   this.getTowersList();
    // else
    //   this.towersUser(this.email);
  }

  allServices() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Reportes', ref =>
      ref.where('idTorre', '==', this.keyTower).orderBy('fechaAlta', 'desc'))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({
              id: c.payload.doc.id, 
              ref: c.payload.doc.ref,
              ...c.payload.doc.data() as DocumentChangeAction<any> 
            })
        )
      ))
    snapshotResult.subscribe(reser => {
      this.allS = reser;
    });
  }

  getAllReports(){
    this.firestore.collection(`/Condominios/${this.idCondominio}/Reportes`, ref => 
      ref.orderBy('fechaAlta', 'desc')
    )
    .snapshotChanges()
    .pipe(map(docs =>
      docs.map(
        c => ({ id: c.payload.doc.id, ref: c.payload.doc.ref, ...c.payload.doc.data() as DocumentChangeAction<any> })
      )
    ))
    .subscribe(reports => {
      // console.log("Reports ",reports);

      this.pendingReports = [];
      this.doneReports = [];
      this.inProgressReport = [];

      this.loading = false;

      this.allReports = reports;
      this.allReports.forEach(report => {
        switch(report.status){
          case 0:
            this.pendingReports.push(report);
          break;

          case 1:
            this.inProgressReport.push(report);
          break;

          case 2:
            this.doneReports.push(report);
          break;
        }
      })
    });
  }

  getServices() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Reportes', ref =>
      ref.where('idTorre', '==', this.keyTower).where('status', '==', 0).orderBy('fechaAlta', 'desc'))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      .subscribe(reser => {
        if(window.localStorage.getItem("nToken") != ""){
          console.log("ntoken");
          try{
            this.clearCount();
          }
    
          catch(e){
            console.log(e);
            
          }
        }
        this.services = reser;
        // this.templateToExcel= [this.excelHeaders, this.services];
        // this.collectionSizePending = (Math.trunc(this.services.length / this.pageSizePending) + 1) * 10;
      });
  }

  getServicesRecives() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Reportes', ref =>
      ref.where('idTorre', '==', this.keyTower).where('status', '==', 1).orderBy('fechaAlta', 'desc'))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      .subscribe(reser => {
        this.servicesProcess = reser;
      });


  }

  getTowersList() {
    this.firestoreService.getTowersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  serviceFinish() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Reportes', ref =>
      ref.where('idTorre', '==', this.keyTower).where('status', '==', 2).orderBy('fechaAlta', 'desc'))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      .subscribe(reser => {
        this.servicesFinish = reser;
      });
  }

  openModalCreateReport(){
    this.modalService.open(ModalAgregarReporteComponent, {
      // size: 'lg'
    }).result.then((result) => {
      console.log(result);
      // this.getProducts();
    })
    .catch((error) => {
      console.log(error);
      // this.getProducts();
    });
  }


  deptKey(key) {
    const snapshotResult = this.firestore.collection('/Departamentos', ref =>
      ref.where('idDepartamento', '==', key))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));

    snapshotResult.subscribe(doc => {
      this.services.push(
        doc.payload.doc.data()
      );
    });

    this.isService = true;
  }

  inProcess(i, data) {
    data.status = 1;
    const key = data.id;
    this.firestoreService
      .updateOperateReport(key, data)
      .catch(err => console.log(err));
    this.services.splice(i, 1);
    // this.getServicesRecives( this.keyTower);
  }


  delete(i, data) {
    const key = data.id;
    this.firestoreService
      .deleteReportRequest(key)
      .catch(err => console.log(err));
    this.services = [];
    // this.getServices(this.keyTower);
  }

  update(i, data) {
    data.fechaCierre = new Date();
    const key = data.id;
    data.status = 2;
    this.firestoreService
      .updateOperateReport(key, data)
      .catch(err => console.log(err));
    this.servicesProcess.splice(i, 1);
  }

  pending() {
    this.isPending = true;
    this.isFinish = false;
    this.isProcess = false;
    this.getServices();
    if(window.localStorage.getItem("nToken") != ""){
      console.log("ntoken");
      try{
        this.clearCount();
      }

      catch(e){
        console.log(e);
        
      }
    }
  }

  finish() {
    this.isPending = false;
    this.isProcess = false;
    this.isFinish = true;
    this.serviceFinish();
  }

  process() {
    this.isPending = false;
    this.isFinish = false;
    this.isProcess = true;
    this.getServicesRecives();
  }

  showServices(key) {
    this.keyTower = key;
    this.isService = true;
    this.isPending = true;
    this.getServices();
    this.allServices();
  }

  showInfo(data) {
    this.torreS = data.torre;
    this.departamentoS = data.departamento;
    this.comentarioS = data.comentario;
  }

  clearCount() {
    // console.log(localStorage.getItem('nToken').replace(/['"]+/g, ''));
    let json = {
      "notification": {
        "title": "clear",
        "body": "",
        "icon": "https://firebasestorage.googleapis.com/v0/b/condb-b725c.appspot.com/o/logo%2Fcondoclick.jpeg?alt=media&token=35a27bfc-576e-4363-bd5c-bf1ab4209074"
      },
      "to": localStorage.getItem('nToken').replace(/['"]+/g, '')
    }
    // obj.notification = this.notification;
    // obj.to = "c5IKx8xOtG2jT83SJ-EWXC:APA91bHy2zztpue9NnamsmJQCefBfNeBOn5dyd1fPaFUFNEPwFHNaIstWZETv2nUb60PpmJUvlgvGgWoOntyTF1LYNVGqfYNENBJoik0lKvwrTeG_Wtyt0iiUmtLdhD583IMYv5Y5O_R";

    this.firebaseAuth.sendNotification(json).subscribe(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );
  }

  public arraryT = [];
  exportTemplateAsExcel() {
    for (var i = 0; i < this.allS.length; i++) {
      if (i == 0) {
        this.templateToExcel.push(
          this.excelHeaders
        )
      }

      this.data = [
        this.allS[i].servicio,
        this.allS[i].departamento,
        this.allS[i].torre,
        new Date(this.allS[i].fechaAlta.seconds * 1000),
        this.allS[i].status == 0 ? 'Pendientes' : 'Concluidos'
      ]

      this.templateToExcel.push(
        this.data
      )

    }
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Servicios');
    XLSX.writeFile(wb, "test" + ".xlsx");
  }

  towersUser(email) {
    email = this.email.replace(/['"]+/g, '');
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));
    snapshotResult.subscribe(doc => {
      this.userData.push(
        doc.payload.doc.data()
      );
      this.torresKey = this.userData[0].torresKey;

      for (var i = 0; i <= this.torresKey.length; i++) {
        if (this.torresKey[i] != null) {
          this.firestoreService.getTowerServices(this.torresKey[i]).subscribe((user) => {
            this.towers.push({
              key: user.payload.id,
              fechaAlta: user.payload.data()['fechaAlta'],
              nombre: user.payload.data()['nombre'],
              status: user.payload.data()['status'],
              url: user.payload.data()['url'],
            });
          });
        }
      }
    });

  }
}

