import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { catchError, finalize, flatMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';

import { ToastService } from '../../services/toast/toast.service';


@Component({
  selector: 'app-editar-user',
  templateUrl: './editar-user.component.html',
  styleUrls: ['./editar-user.component.css']
})
export class EditarUserComponent implements OnInit {


  public condos = [];
  checkoutForm;
  public downloadURL;
  public urlImagen: string;
  public nameCondo;
  public condominio = [];
  public user: any;
  public keyService = [];
  public keyServiceUser = [];
  public submitted: boolean = false;
  public towers = [];
  public towerKeys = [];
  public idCondominio;
  dataUser: { email: string };
  public email;

  public buildings: Array<any> = [];

  constructor(
    private firestoreService: FirestoreService, 
    private router: Router, 
    private formBuilder: FormBuilder, 
    private storage: AngularFireStorage, 
    private firestore: AngularFirestore, 
    private rutaActiva: ActivatedRoute,
    public toastService: ToastService,
  ) {
    this.checkoutForm = this.formBuilder.group({
      nombre: '',
      email: '',
      isReservacion: false,
      isServicio: false,
      isEscribir: false,
      isEditar: false,
      isEliminar: false,
      // torre: '',
      torresKey: []
    });
  }

  ngOnInit(): void {
    this.dataUser = {
      email: this.rutaActiva.snapshot.params.email
    };

    if (localStorage.getItem('condominio') != null)
      this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');

    if (localStorage.getItem('email') != null)
      this.email = localStorage.getItem('email').replace(/['"]+/g, '');

    // this.getUserAdmin();
    this.getTowersList();
    this.getUser();
  }

  selectedFileFrontal = null;

  onSubmit() {

    this.submitted = true;
    if (this.checkoutForm.invalid) {
        return;
    }

    this.checkoutForm.controls['torresKey'].setValue([]);
    let _auxIDBuildings = [];
    this.buildings.forEach(building => {
      if(building.status){
        _auxIDBuildings.push(building.building.key);
      }
    });

    this.checkoutForm.controls['torresKey'].setValue(_auxIDBuildings);

    console.log(this.checkoutForm);
    let ref = this.user.ref as DocumentReference;
    ref.update({
      'lastUpdate': new Date(),
      ...this.checkoutForm.value
    }).then((value) => {
      this.toastService.show(`${this.user.descripcion} actualizado exitosamente`, { classname: 'bg-success text-light'});
    });

    // form.idTipo = this.user.idTipo;
    // form.idCondominio = this.user.idCondominio;
    // form.torresKey = this.towerKeys;
    // this.firestoreService
    //   .updateUserOper(key, form)
    //   .catch(err => console.log(err));

    // this.firestoreService
    //   .updateAdminOper(keyAdmin, form)
    //   .catch(err => console.log(err));
    // this.router.navigate(['/usuarios-operativos']);
  }

  getUser() {

    let snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('email', '==', this.dataUser.email))
      .get().subscribe((users) => {
        this.user = {
          'id': users.docs[0].id, 
          'ref': users.docs[0].ref,
          ...users.docs[0].data()
        };

        // console.log(this.user);
        

        this.checkoutForm.controls['nombre'].setValue(this.user.nombre);
        this.checkoutForm.controls['email'].setValue(this.user.email);
        this.checkoutForm.controls['isReservacion'].setValue(this.user.isReservacion);
        this.checkoutForm.controls['isServicio'].setValue(this.user.isServicio);
        this.checkoutForm.controls['isEscribir'].setValue(this.user.isEscribir);
        this.checkoutForm.controls['isEditar'].setValue(this.user.isEditar);
        this.checkoutForm.controls['isEliminar'].setValue(this.user.isEliminar);
        this.checkoutForm.controls['torresKey'].setValue(this.user.torresKey);

        // console.log('torres: ', this.towers);
        this.buildings.forEach(building => {
          console.log(building);
          
          this.user.torresKey.forEach(idBuilding => {
            if(building.building.key == idBuilding){
              building.status = true;
            }
          })
        })
        
      });
  }

  // getUserAdmin() {
  //   const snapshotResult = this.firestore.collection('/Administradores', ref =>
  //     ref.where('email', '==', 'alyz_v@yahoo.com.mx'))
  //     .snapshotChanges()
  //     .pipe(flatMap(clients => clients));
  //   snapshotResult.subscribe(doc => {
  //     this.keyServiceUser.push(
  //       doc.payload.doc.id,
  //     );
  //   });

  // }

  update(i, data) {
    const key = data.key = this.keyService[0]
    data.idTipo = 3;
    data.status = true;

    this.firestoreService
      .updateUserOper(key, data)
      .catch(err => console.log(err));

    this.firestoreService
      .updateAdminOper(key, data)
      .catch(err => console.log(err));
    this.router.navigate(['/usuarios-operativos']);
  }

  getTowersList() {
    this.firestoreService.getTowers().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ref: c.payload.doc.ref, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
      this.towers.forEach((building) => {
        this.buildings.push({building: building, status: false});
      })

      console.log(this.buildings);
      
    });
  }

  torres(building) {

    // let result = this.buildings.filter(tower => tower.building.key == building.building.key);
    // console.log(result);
    // if(result.length > 0) {
    //   let exist = this.user.torresKey.filter(tower => tower == result[0].building.key);
    //   if(exist.length > 0) {

    //   }
    // }
    
    // if (isNew) {
    //   this.towerKeys.push(
    //     tower
    //   )
    // } else {
    //   var indice = this.towerKeys.indexOf(tower);
    //   this.towerKeys.splice(indice, 1);
    // }
  }

  get f(){ 
    return this.checkoutForm.controls; 
  }

}
