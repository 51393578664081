import { Component, OnInit, Input } from '@angular/core';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  DocumentChangeAction, 
  DocumentReference
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { flatMap, map, mergeMapTo } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from '../../../../../../services/toast/toast.service';
import { IconsService } from '../../../../../../services/icons/icons.service';
import { SecurityService } from '../../../../../../services/security/security.service';
import { firestore } from 'firebase';

@Component({
  selector: 'app-crear-servicio-area',
  templateUrl: './crear-servicio-area.component.html',
  styleUrls: ['./crear-servicio-area.component.css']
})
export class CrearServicioAreaComponent implements OnInit {

  @Input() public area;

  public formReport: FormGroup;

  public icons: Array<any> = [];
  public iconSelected = '';
  public idCondominium: String = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore, 
    private formBuilder: FormBuilder,
    public iconsService: IconsService,
    private modalService: NgbModal,
    private securityService: SecurityService,
    public toastService: ToastService,
  ) { }

  close(content){
    this.activeModal.close(content);
  }

  get f(){ 
    return this.formReport.controls; 
  }

  getIcons(){
    this.icons = [];
    let ref = this.iconsService.getIcons().ref;
    ref.orderBy('name', 'asc')
    .get().then((icons) => {
      this.icons = icons.docs.map((icon) => icon.data());
    });
  }

  setIcon($obj){
    console.log($obj);
    this.iconSelected = (typeof $obj === 'undefined') ? '' : $obj.image;
    // console.log(this.formReport.value);
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.formReport);
    if (this.formReport.invalid || this.iconSelected == '') {
        return;
    }
    
    let data = {
      recordDate: firestore.Timestamp.now(),
      lastUpdate: firestore.Timestamp.now(),
      status: true,
      icon: this.iconSelected,
      ...this.formReport.value,
    };

    console.log(data);

    let services = this.area.services || [];
    services.push(data);
    console.log(services);
    
    let ref = this.area.ref as DocumentReference;
    ref.update({"services": services})
    .then(() => {
      this.toastService.show('¡Agregado exitosamente!', { classname: 'bg-success text-light'});
      this.close('');
    })
    .catch((e) => {
      this.toastService.show('No se pudo agregar', { classname: 'bg-danger text-light'});
      this.close('');
    })
  }

  ngOnInit(): void {
    this.formReport = this.formBuilder.group({
      name: ['', Validators.required],
    });

    this.getIcons();
  }

}
