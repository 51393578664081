<head>
    <title>CondoClick • Área común {{area ? area.name : ''}} para reportes de seguridad</title>
</head>
<div class="container-fluid">
    <div class="row">
      <div class="col p-0">
        <ul ngbNav #nav="ngbNav" class="nav nav-pills mb-3 tab-material" id="pills-tab" role="tablist">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="btn pills-tab bg-white text-black">General</a>
              <ng-template ngbNavContent>
                  <app-general-area-seguridad></app-general-area-seguridad>
              </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="btn pills-tab bg-white text-black">Servicios</a>
            <ng-template ngbNavContent>
                <app-servicios-area-seguridad></app-servicios-area-seguridad>
            </ng-template>
          </li>
        </ul>
      </div>
  
    </div>
</div>

<div [ngbNavOutlet]="nav" class="mt-4"></div>