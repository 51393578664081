<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-6">
                <label>Torre</label>
                <select [(ngModel)]="idTorreNg" formControlName="idTorre" (change)="getDepartmentsList()">
                    <option *ngFor="let tower of towers; index as i; first as isFirst" [value]="tower.key">
                        {{tower.nombre}}
                    </option>
                </select>
            </div>

            <div class="col-sm-6">
                <label>Departamentos</label>
                <select (change)="setIdDept(checkoutForm.value)" formControlName="idDepartamento">
                    <option *ngFor="let department of departments; index as i; first as isFirst"
                        [value]="department.id">
                        {{department.nombre}}
                    </option>
                </select>
            </div>

            <!-- <div class="col-sm-6">
                <label>Usuarios</label>
                <select formControlName="usuario">
                    <option *ngFor="let user of users; index as i; first as isFirst" [value]="user.uid">
                        {{user.nombre}}
                    </option>
                </select>
            </div> -->

            <div class="col-sm-6">
                <label>Comentario</label>
                <input id="nombre" type="text" formControlName="comentario"><span class="highlight"></span>
            </div>


            <div class="col-sm-6">
                <label>Servicios</label>
                <select formControlName="servicio">
                    <option *ngFor="let service of servicios; index as i; first as isFirst" [value]="service.nombre">
                        {{service.nombre}}
                    </option>
                </select>
            </div>
            <!-- 
            <div class="col-sm-6">
                <label>Nombre del administrador</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Correo electronico</label>
                <input id="nombre" type="text" formControlName="email"><span class="highlight"></span>
            </div> -->

            <div class="col-sm-12">
                <input [disabled]="checkoutForm.invalid" type="submit" value="Guardar">
            </div>
        </div>
    </div>
</form>