import { Injectable } from '@angular/core';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  DocumentReference, 
  QuerySnapshot 
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlatService {

  public idCondominium;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { 

    if (localStorage.getItem('condominio') != null) {
      this.idCondominium = localStorage.getItem('condominio').replace(/['"]+/g, '');
    }

  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION createFlat                            */
  /* -------------------------------------------------------------------------- */

  public createFlat(data: any): Promise<DocumentReference>{
    data.status = false;
    data.fechaAlta = moment().toDate();
    data.lastUpdate = moment().toDate();

    return this.firestore.collection('Condominios')
      .doc(this.idCondominium).collection('Torres')
      .add(data);
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION deleteFlat                            */
  /* -------------------------------------------------------------------------- */

  public async deleteFlat(idFlat){
    let doc = await this.getFlat(idFlat).toPromise();

    if(doc.data().filePath != null){
      let refImg = this.storage.ref(doc.data().filePath);
      await refImg.delete().toPromise();
    }

    await doc.ref.delete();
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION getFlat                              */
  /* -------------------------------------------------------------------------- */

  public getFlat(idFlat):Observable<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>{
    return this.firestore.collection('Condominios')
      .doc(this.idCondominium).collection('Torres')
      .doc(idFlat).get();
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION updateFlat                            */
  /* -------------------------------------------------------------------------- */

  public updateFlat(idFlat: string, flat: any): Promise<any>{
    return this.firestore.collection('Condominios')
    .doc(this.idCondominium).collection('Torres')
    .doc(idFlat).update({
      ...flat,
      'lastUpdate': moment().toDate()
    });
  }

}
