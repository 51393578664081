import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  DocumentChangeAction,
  DocumentReference
} from '@angular/fire/firestore';

import { FlatService } from 'src/app/services/flat/flat.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-editar-torres',
  templateUrl: './editar-torres.component.html',
  styleUrls: ['./editar-torres.component.css']
})
export class EditarTorresComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  public checkoutForm: FormGroup;
  
  public departamento: any;
  public downloadURL;
  public urlImagen: string;
  public idCondominio: string;
  public torreD: { idTower: string, nombre: string };

  public flat: any;


  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string };

  constructor(
    private rutaActiva: ActivatedRoute, 
    private firestore: AngularFirestore, 
    private firestoreService: FirestoreService, 
    private calendar: NgbCalendar, 
    private formBuilder: FormBuilder, 
    private router: Router, 
    private storage: AngularFireStorage,
    private flatService: FlatService,
    private toastService: ToastService,
  ) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      status: [''],
    });

    this.torreD = {
      idTower: this.rutaActiva.snapshot.params.idTower,
      nombre: this.rutaActiva.snapshot.params.nombre
    };

  }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getTower();
  }

  async onSubmit() {

    if(this.checkoutForm.invalid){
      return;
    }

    await this.flatService.updateFlat(
      this.flat.id, {
      'nombre': this.checkoutForm.value.nombre,
    });

    this.toastService.show(`${this.checkoutForm.value.nombre} actualizado exitosamente`, { classname: 'bg-success text-light'});

    // customerData.url = this.urlImagen;
    // customerData.status = true;
    // this.firestoreService.updateTowers(this.torreD.idTower, customerData).then(() => {
    //   this.router.navigate(['/torres']);
    // }, (error) => {
    //   console.error(error);
    // });

  }

  /* -------------------------------------------------------------------------- */
  /*                         FUNCTION changeValueStatus                         */
  /* -------------------------------------------------------------------------- */

  changeValueStatus(){

  }

  onUpload(e) {
    // try {
    //   const id = Math.random().toString(36).substring(2);
    //   const file = e.target.files[0];
    //   const filePath = `images/notificacion_${id}`;
    //   const ref = this.storage.ref(filePath);
    //   const task = this.storage.upload(filePath, file);
    //   task.snapshotChanges()
    //     .pipe(
    //       finalize(() => {
    //         this.downloadURL = ref.getDownloadURL();
    //         this.downloadURL.subscribe(downloadURLResponse => {
    //           this.urlImagen = downloadURLResponse;
    //         });
    //       }),
    //     )
    //     .subscribe();
    // } catch {
    //   console.log(console.error());
    // }

  }

  async getTower() {
    // const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres', ref =>
    //   ref.where('nombre', '==', this.torreD.nombre))
    //   .snapshotChanges()
    //   .pipe(flatMap(clients => clients));
    // snapshotResult.subscribe(doc => {
    //   this.dataTorre.push(
    //     doc.payload.doc.data(),
    //   );

    //   this.checkoutForm.controls['nombre'].setValue(this.dataTorre[0].nombre);

    // });

    let flatDoc = await this.flatService.getFlat(this.torreD.idTower)
      .toPromise();

    this.flat = {
      id: flatDoc.id,
      ...flatDoc.data()
    };

    console.log(this.flat);

    this.checkoutForm.controls['nombre'].setValue(this.flat.nombre);
    
  }

}

