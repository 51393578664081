import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  QuerySnapshot, 
  DocumentChangeAction, 
  DocumentReference 
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, flatMap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IconsService } from '../../../../../services/icons/icons.service';
import { ToastService } from '../../../../../services/toast/toast.service';
import { firestore } from 'firebase';

@Component({
  selector: 'app-general-area-seguridad',
  templateUrl: './general-area-seguridad.component.html',
  styleUrls: ['./general-area-seguridad.component.css']
})
export class GeneralAreaSeguridadComponent implements OnInit {

  public area: any;
  public formReport: FormGroup;
  public idArea = this.currentRoute.snapshot.params.idArea;
  public idCondominium: String = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public icons: Array<any> = [];
  public iconSelected = null;
  public loading: boolean = true;
  public submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private currentRoute: ActivatedRoute,
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    public iconsService: IconsService,
    private modalService: NgbModal,
    public toastService: ToastService,
  ) { }

  getDataArea(){
    this.firestore.collection(`/Condominios/${this.idCondominium}/AreasSeguridad`)
    .doc(this.idArea).snapshotChanges()
    .subscribe((area) => {
      this.area = {
        ref: area.payload.ref,
        id: area.payload.id,
        ...area.payload.data() as DocumentChangeAction<any>,
      };
      console.log(this.area);
      this.loading = false;
      this.formReport = this.formBuilder.group({
        name: [this.area.name, Validators.required],
      });
      this.getIcons();
    })
  }

  get f(){ 
    return this.formReport.controls; 
  }

  getIcons(){
    this.icons = [];
    let ref = this.iconsService.getIcons().ref;
    ref.orderBy('name', 'asc')
    .get().then((icons) => {
      this.icons = icons.docs.map((icon) => icon.data());
      // console.log(this.icons);
      this.searchIconSelected();
      
    });
  }

  searchIconSelected(){
    this.icons.forEach((icon, index) => {
      if(icon.image == this.area.icon){
        this.iconSelected = icon;
      }
    });
    // console.log(this.iconSelected);
    
  }

  setIcon($obj){
    console.log($obj);
    this.iconSelected = (typeof $obj === 'undefined') ? null : $obj;
    // console.log(this.formReport.value);
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.formReport);
    if (this.formReport.invalid || this.iconSelected == null) {
        return;
    }

    let newData = {
      lastUpdate: firestore.Timestamp.now(),
      icon: this.iconSelected.image,
      name: this.formReport.value.name,
    }

    // console.log(newData);

    let ref = this.area.ref as DocumentReference;
    ref.update(newData)
    .then(() => {
      this.toastService.show(`¡Área ${newData.name} actualizada exitosamente!`, { classname: 'bg-success text-light'});
    })
    .catch((e) => {
      console.log(e);
      this.toastService.show('No se pudo actualizar area', { classname: 'bg-danger text-light'});
    })
    
  }

  ngOnInit(): void {
    this.getDataArea();
  }

}
