import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { catchError, finalize, flatMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-agregar-gestion-seguridad',
  templateUrl: './agregar-gestion-seguridad.component.html',
  styleUrls: ['./agregar-gestion-seguridad.component.css']
})
export class AgregarGestionSeguridadComponent implements OnInit {

  public condos = [];
  checkoutForm;
  public downloadURL;
  public urlImagen: string;
  public nameCondo;
  public condominio = [];
  public towers = [];
  public departments = [];
  public servicios = [];
  public idTorre;
  public idTorreNg;
  public idDepartamento;
  public users = [];
  public depts;
  public idDept;
  public idCondominio;

  constructor(private firestoreService: FirestoreService, private router: Router, private formBuilder: FormBuilder, private storage: AngularFireStorage, private firestore: AngularFirestore) {
    this.checkoutForm = this.formBuilder.group({
      idTorre: ['', [Validators.required]],
      idDepartamento: ['', [Validators.required]],
      servicio: ['', [Validators.required]],
      comentario: [''],
      departamento: ['']
    });
  }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getCondos();
    this.firestoreService.query();
    this.getTowersList();
    this.getServices();
  }

  selectedFileFrontal = null;

  onSubmit(form) {
    this.firestoreService.getTorre(form.idTorre).subscribe((tower) => {
      form.nombreTorre = tower.payload.data()['nombre'];
      form.torre = tower.payload.data()['nombre'];
      // this.getDepartamentoNombre(form.idDepartamento, dataTower[1]);
      this.firestoreService.getDept(form.idDepartamento, this.idTorreNg).subscribe((user) => {
        form.departamento = user.payload.data()['nombre'];
        form.status = 0;
        form.nombre = tower.payload.data()['nombre'];
        this.idTorre = form.torre;
        form.fechaAlta = new Date();
        form.fechaCierre = new Date();
        this.firestoreService.createReport(form).then(() => {
        }, (error) => {
          console.error(error);
        });
        this.router.navigate(['/gestion-seguridad']);

      });

    });

  }

  getCondos() {
    this.firestoreService.getCondosList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(condos => {
      this.condos = condos;
    });
  }

  getCondo(key) {
    this.firestoreService.getCondominio(key).snapshotChanges().subscribe((condo) => {
      this.condominio.push({
        nombre: condo.payload.data()['nombre'],
        status: condo.payload.data()['status'],
        url: condo.payload.data()['url']
      });
    });
  }

  getTowersList() {
    this.firestoreService.getTowersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  getDepartmentsList() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres/' + this.idTorreNg + '/Departamentos', ref =>
      ref.orderBy('nombre'))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      this.departments = reser;
    });
  }

  getServices() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Seguridad', ref =>
      ref.where('status', '==', true))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));

    snapshotResult.subscribe(doc => {
      this.servicios.push(
        doc.payload.doc.data()
      );
    });

  }

  condoName(key) {
    this.idDepartamento = key;
  }

  getUsers() {
    this.firestoreService.getUsersServices(this.idTorreNg, this.idDept).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(users => {
      this.users = users;
    });
  }

  getDepartamentoNombre(key, keyTower) {
    this.firestoreService.getDept(key, keyTower).subscribe((user) => {
      this.checkoutForm.nombreDept = user.payload.data()['nombre'];
    });


  }

  setIdDept(data) {
    this.idDept = data.idDepartamento;
  }
}

