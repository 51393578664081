import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'app-lista-visitas',
  templateUrl: './lista-visitas.component.html',
  styleUrls: ['./lista-visitas.component.css']
})
export class ListaVisitasComponent implements OnInit {

  @Input() visitors: Array<any>;
  @Input() loading: boolean;
  @Input() history: boolean;

  public auxVisitors: Array<any>;

  public colSpan: number;
  templateToExcel: string[][] = [];
  data = [];
  public allS = [];
  public EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public EXCEL_EXTENSION = '.xlsx';

  public wordSearched: string;

  pageSize: number = 20;
  page = 1;

  ngOnChanges(changes: SimpleChanges) {
    this.auxVisitors = this.visitors;
  }

  constructor() { }

  ngOnInit(): void {
    console.log(`HISTORY: ${this.history}`);
    this.colSpan = this.history ? 5 : 4;
  }

  getNames(names: Array<any>): String {
    let namesInLine = '';

    names.forEach((name, index) => {
      if (index == names.length - 1) {
        namesInLine += `${name}`;
      }

      else {
        namesInLine += `${name}, `;
      }
    });

    return namesInLine;
  }

  lookingFor(event){
    this.wordSearched = event.target.value.trim();
    this.wordSearched.trim();

    this.auxVisitors = [];

    if(this.wordSearched != ''){
      let words = this.wordSearched.split(' ');
      // console.log(words);
      words.forEach((word, index) => {
        this.visitors.forEach((visitor) => {
          if(visitor.name){
            if(visitor.name.toLowerCase().includes(word.toLowerCase()) || visitor.flat.toLowerCase().includes(word.toLowerCase()) || visitor.building.toLowerCase().includes(word.toLowerCase())){
              if(!this.auxVisitors.includes(visitor)){
                this.auxVisitors.push(visitor);
              }
            }
          }

          else if(visitor.names){
            visitor.names.forEach((name) => {
              if(name.toLowerCase().includes(word.toLowerCase()) || visitor.flat.toLowerCase().includes(word.toLowerCase()) || visitor.building.toLowerCase().includes(word.toLowerCase())){
                if(!this.auxVisitors.includes(visitor)){
                  this.auxVisitors.push(visitor);
                }
              }
            });
          }


        });
      });
    }

  }

  public excelHeaders: string[] = ["Nombres", "Departamento", "Torre", "Fecha de alta", "Fecha de salida", "Estatus"];

  public arraryT = [];
  exportTemplateAsExcel() {
    let reportDate: string = moment().format('DDMMYYYYHHmm');;
    console.log("this.visitors",this.visitors)
    for (var i = 0; i < this.visitors.length; i++) {
      if (i == 0) {
        this.templateToExcel.push(
          this.excelHeaders
        )
      }

      //console.log("this.visitors2",this.visitors)

      this.data = [
        this.visitors[i].name ?? this.getNames(this.visitors[i].names),
        this.visitors[i].flat,
        this.visitors[i].building,
        this.visitors[i].dateArrive 
          ? new Date(this.visitors[i].dateArrive.seconds * 1000)
          : 'No se presento',
        this.visitors[i].dateExit
          ? new Date(this.visitors[i].dateCreated.seconds * 1000)
          : '-',
        this.visitors[i].isNew
          ? this.visitors[i].dateExit
            ? 'Ya salió'
            : 'No ha salido'
          : 'No hay fecha de salida registrada'
      ]



      this.templateToExcel.push(
        this.data
      )

    }
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Visitas');
    //console.log(fecha_reporte);
    XLSX.writeFile(
      wb, 
      `visits-report_${reportDate}.xlsx`
    );
  }

}
