<div *ngIf="isAlert" class="alert">
	<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
	<strong><i class="fa fa-flag" aria-hidden="true"></i></strong> El correo electronico con el que intenta registrarse
	no ha sido dado de alta.
</div>

<div *ngIf="showAlert" class="alert">
	<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
	<strong><i class="fa fa-flag" aria-hidden="true"></i></strong> Su contraseña debe contar con al menos 6 caracteres.
</div>


<div class="all">
	<form [formGroup]="loginForm" method="get" id="login-form" class="login-form" autocomplete="off" role="main">
		<img src="../assets/images/condoclick.jpeg" alt="image" class="logo">
		<h1 class="a11y-hidden">Login Form</h1>
		<div>
			<label class="label-email">
				<input (change)="onCheckRegister()" formControlName="email" type="email" class="text" name="email"
					placeholder="Email" tabindex="1" required />
				<span class="required">Email</span>
			</label>
		</div>
		<input type="checkbox" name="show-password" class="show-password a11y-hidden" id="show-password" tabindex="3" />
		<label class="label-show-password" for="show-password">
			<span>Show Password</span>
		</label>
		<div>
			<label class="label-password">
				<input formControlName="password" type="text" class="text" name="password" placeholder="Password"
					tabindex="2" required />
				<span class="required">Password</span>
			</label>
		</div>
		<input (click)="onRegister()" type="submit" value="Registrar" />
	</form>

</div>