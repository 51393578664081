<div class="modal-header">
    <h1 class="modal-title">Descargar reporte</h1>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger col-12" (click)="activeModal.close('Close click')">Cancelar</button>
    <button type="button" class="btn btn-success col-12">Descargar</button>
</div>