<head>
    <title>Torres • CondoClick</title>
</head>
<div class="table100">
    <table id="customers">
        <tr>
            <th>Torre</th>
            <th>Imagen</th>
            <th>Fecha Alta</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr *ngFor="let tower of towers; index as i; first as isFirst">
            <td>{{tower.nombre}}</td>
            <td><img style="width:50px;height:50px;" src="{{tower.url}}"></td>
            <td>{{utilService.getDate(tower.fechaAlta)}}</td>
            <td>{{tower.status ? "Activo" : "Inactivo"}}</td>
            <td class="align-icon">
                <ng-template #editar>Editar torre</ng-template>
                <ng-template #editarE>Activo/Inactivo</ng-template>
                <ng-template #borrarE>Eliminar torre</ng-template>
                <i [ngbTooltip]="editar"[routerLink]="['/edit-tower', tower.id, tower.nombre]" class="fa fa-pencil-square" aria-hidden="true"></i>
                <i [ngbTooltip]="editarE"(click)="update(tower.id,towers[i])" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                <i [ngbTooltip]="borrarE"(click)="delete(tower)" class="fa fa-trash" aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <br>
    <div class="col-sm-12">
        <button routerLink="/torres-form" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
        <!-- <i routerLink="/areas-form" class="fa fa-plus-square fa-2x"></i> -->
    </div>
</div>