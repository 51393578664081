<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-6">
                <label>Nombre</label>
                <input id="nombre" type="text" formControlName="nombre"><span class="highlight"></span>
            </div>
            <div class="col-sm-6">
                <label>Telefono</label>
                <input id="Descripcion" type="text" formControlName="descripcion"><span class="highlight"></span>
            </div>

            <div class="col-sm-12">
                <input [disabled]="checkoutForm.invalid" type="submit" value="Guardar">

            </div>

           
        </div>
    </div>

</form>