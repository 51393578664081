<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-0 mb-5">
            <h1><b>Seleccionar Área Común</b></h1>
        </div>

        <div class="col-12 p-0">
            <div class="row">
                <a *ngFor="let tower of towers" class="col-sm-12 col-md-4 text-decoration-none text-black" routerLink="/reservaciones/{{tower.id}}/proximas">
                <div class="col-12 shadow rounded-lg mb-4 py-4">
                    <div class="row">
                        <div class="col-12 text-center text-black">
                            <h2><b>{{tower.nombre}}</b></h2>
                        </div>
                    </div>
                </div>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- <div class="table100">
    <table id="customers">
        <tr>
            <th>Usuario</th>
            <th>Area</th>
            <th>Fecha Inicio</th>
            <th>Fecha Fin</th>
            <th>Accion</th>

        </tr>
        <tr *ngFor="let reserv of reservaciones; index as i; first as isFirst">
            <td>{{reserv.usuario}}</td>
            <td>{{reserv.area}}</td>
            <td>{{reserv.fechaInicio.seconds*1000 | date: 'dd/MM/yyyy HH:mm'}}</td> 
            <td>{{reserv.fechaFin.seconds*1000 | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td> 
                <i (click)="update(user.key)" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                <i (click)="delete(reserv.key)" class="fa fa-trash" aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <br>
    <div class="col-sm-12">
    <button routerLink="/servicios-form" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
    </div>
</div> -->