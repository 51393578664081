<div class="container-fluid py-3">
    <div class="row">
        <div class="col-12">
            <div class="table100">
                <table id="customers" class="table table-striped text-center">
                    <thead>
                        <tr class="bg-secondary">
                            <th>Torre</th>
                            <th>Departamento</th>
                            <th>E-mail</th>
                            <th>Nombre</th>
                            <th>Fecha Alta</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let request of requests; index as i; first as isFirst">
                            <td class="align-middle">{{request.torre}}</td>
                            <td class="align-middle">{{request.departamento}}</td>
                            <td class="align-middle">{{request.email}}</td>
                            <td class="align-middle">{{request.nombre}}</td>
                            <td class="align-middle">{{request.fechaAlta.seconds*1000 | date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td class="align-middle">
                                <button class="btn btn-danger mr-1 mb-1" (click)="rejected(request)" type="button">Rechazar</button>
                                <button class="btn btn-success mb-1" (click)="approved(request)" type="button">Aceptar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>