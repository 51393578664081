<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Crear Reservación</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-row">

          <div class="form-group col-12">
            <label for="txtArea">Torres</label>
            <select id="txtArea" class="custom-select" [(ngModel)]="tower" (ngModelChange)="setTower($event)" name="tower">
              <option value="" selected disabled>Seleccionar torre</option>
              <option [ngValue]="tower" *ngFor="let tower of towers">{{tower.nombre}}</option>
            </select>
          </div>

          <!-- <ng-template #rt let-r="result" let-t="term">
            <ngb-highlight [result]="r.email" [term]="t"></ngb-highlight>
            (<ngb-highlight [result]="r.nombre" [term]="t"></ngb-highlight>)
          </ng-template>

          <div class="form-group col-12">
            <label for="txtUser">Usuario Condominio</label>
            <input id="txtUser" class="form-control" placeholder="Email" name="user"
            [ngbTypeahead]="search" [resultTemplate]="rt" [(ngModel)]="user" [inputFormatter]="formatter">
          </div> -->

          <div class="form-group col-12">
            <label for="txtArea">Departamento</label>
            <select id="txtArea" class="custom-select" [(ngModel)]="flat" (ngModelChange)="setFlat($event)" name="flat">
              <option value="" selected disabled>Seleccionar departamento</option>
              <option [ngValue]="flat" *ngFor="let flat of flats">{{flat.nombre}}</option>
            </select>
          </div>
<!-- 
          <div class="form-group col-12">
            <label for="txtArea">Área común</label>
            <input id="txtArea" [(ngModel)]="area" class="form-control" [inputFormatter]="showAreas">
          </div> -->

          <div class="form-group col-12">
            <label for="dateReservation">Fecha de reservación</label>
            <input 
              id="dateReservation" 
              class="form-control" 
              placeholder="Fecha de reservación" 
              name="dateReservation" 
              (click)="dp.toggle()" 
              ngbDatepicker 
              #dp="ngbDatepicker" 
              [(ngModel)]="reservationDate"
              (blur)="checkAreaAvailable($event)"
              [disabled]="!flat && !tower">
          </div>

          <div class="form-group col-12" *ngIf="blocks.length > 0">
            <label for="txtReservation">Horarios</label>
            <select id="txtReservation" class="custom-select" [(ngModel)]="block" (ngModelChange)="setBlock($event)"  name="block">
              <option value="" selected disabled>Seleccionar hora</option>
              <option [ngValue]="block" *ngFor="let block of blocks">{{block.inicio}} - {{block.fin}}</option>
            </select>
          </div>

          <div class="col-12 text-center" *ngIf="blocks.length == 0">
            {{messageBlocks}}
          </div>

          <!-- <div class="col-12">
            <button class="btn" (click)="checkAreaAvailable()">Checar horarios</button>
          </div> -->

        </div>
      </form>
    </div>
    <div class="container-fluid px-4 py-3">
      <div class="row">
        <button type="button" class="btn btn-danger col-12 mb-3" (click)="modal.close('Save click')">Cancelar</button>
        <button 
          type="button" 
          *ngIf="!disabled" 
          class="btn btn-success col-12" 
          (click)="book()"
          [disabled]="buttonBookDisabled">
          {{ buttonBookLabel }}
        </button>

      </div>
    </div>
  </ng-template>
  
<button *ngIf="isEscribir == 'true'" class="btn btn-lg position-fixed m-5 rounded-circle bg-success shadow" style="bottom: 0px; right: 0px;" (click)="open(content)">
    <i class="material-icons text-white mt-2">add</i>
</button>