<div class="table100">
    <table id="customers">
        <tr>
            <th>Servicio</th>
            <th>Fecha Alta</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr *ngFor="let service of servicios; index as i; first as isFirst">
            <td>{{service.nombre}}</td>
            <td>{{service.fechaAlta | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td>{{service.status ? "Activo" : "Inactivo"}}</td>
            <td class="align-icon">
                <ng-template #editar>Editar servicio</ng-template>
                <ng-template #editarE>Activo/Inactivo</ng-template>
                <ng-template #borrarE>Eliminar servicio</ng-template>
                <i [ngbTooltip]="editar" [routerLink]="['/edit-service', service.key, service.nombre]"
                    class="fa fa-pencil-square" aria-hidden="true"></i>
                <i [ngbTooltip]="editarE" (click)="update(service.key,servicios[i])" class="fa fa-pencil-square-o"
                    aria-hidden="true"></i>
                <i [ngbTooltip]="borrarE" (click)="delete(service.key)" class="fa fa-trash" aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <br>
    <div class="col-sm-12">
        <button routerLink="/servicios-form" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
    </div>
</div>