<head>
  <title>Gestión de visitas • CondoClick</title>
</head>
<div class="container-fluid">
  <div class="row">
    <div class="col p-0">
      <ul ngbNav #nav="ngbNav" class="nav nav-pills mb-3 tab-material" id="pills-tab" role="tablist">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="btn pills-tab bg-white text-black">Pendientes</a>
            <ng-template ngbNavContent>
                <app-lista-visitas [(visitors)]="soonVisitors" [(loading)]="loading" [history]="history"></app-lista-visitas>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="btn pills-tab bg-white text-black">Historial</a>
          <ng-template ngbNavContent>
            <app-lista-visitas-historial [(visitors)]="historyVisitors" [(loading)]="loading"></app-lista-visitas-historial>
          </ng-template>
        </li>
      </ul>
    </div>

    <!-- <div class="ml-auto d-flex align-items-center">
      <button class="btn btn-outline-dark d-flex align-items-center"><i class="material-icons mr-1">download</i> Descargar reporte</button>
    </div> -->

  </div>
</div>
  
<div [ngbNavOutlet]="nav" class="mt-4"></div>