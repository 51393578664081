import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.css']
})
export class SoporteComponent implements OnInit {

  public supports = [];

  constructor(private firestoreService: FirestoreService) { }

  ngOnInit(): void {
    this.getServices();

  }

  getServices() {
    this.firestoreService.getSupportList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(support => {
      this.supports = support;
      console.log(support);
    });
  }

  delete(key) {
    console.log(key);
    this.firestoreService
      .deleteSupport(key)
      .catch(err => console.log(err));
  }

  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateSoporte(key, data)
      .catch(err => console.log(err));
  }
}
