import { Component, OnInit, Input } from '@angular/core';
import { GestionServiciosComponent } from "../../admin/gestion-servicios/gestion-servicios.component";

@Component({
  selector: 'app-icon-notifications',
  templateUrl: './icon-notifications.component.html',
  styleUrls: ['./icon-notifications.component.css']
})
export class IconNotificationsComponent implements OnInit {

  @Input() item: number;

  constructor() { }

  message:string;

  ngOnInit(): void {
  }

}
