import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-reservaciones',
  templateUrl: './reservaciones.component.html',
  styleUrls: ['./reservaciones.component.css']
})
export class ReservacionesComponent implements OnInit {
  public reservaciones = [];
  public towers = [];
  public idCondominio;
  public idArea = [];

  constructor(private firestoreService: FirestoreService, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getTowersList();
  }

  getServices() {
    this.firestoreService.getReservaciones().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(reservaciones => {
      this.reservaciones = reservaciones;
      console.log(reservaciones);
    });
  }

  // getTowersList() {
  //   this.firestoreService.getAreasList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c =>
  //         ({ id: c.payload.doc.id, ...c.payload.doc.data() })
  //       )
  //     )
  //   ).subscribe(towers => {
  //     this.towers = towers;
  //     console.log(towers);
  //   });
  // }

  // getTowersList(){
  //   const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes', ref =>
  //   ref.where('status', '==', true))
  //   .snapshotChanges()
  //   .pipe(flatMap(clients => clients));

  // snapshotResult.subscribe(doc => {
  //   this.towers.push(
  //     doc.payload.doc.data(),
  //     doc.payload.doc.id
  //   );

  //   // this.idArea.push(

  //   // )
  //   console.log("//**//", this.towers  )
  // });

  // }

  getTowersList() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/AreasComunes', ref =>
      ref.where('status', '==', true))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      console.log("reser", reser);
      this.towers = reser;
    });
  }

  delete(key) {
    console.log(key);
    this.firestoreService
      .deleteReservation(key)
      .catch(err => console.log(err));
  }

  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateService(key, data)
      .catch(err => console.log(err));
  }
}
