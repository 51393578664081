<header>
  <title>Iconos • CondoClick</title>
</header>

<div class="container-fluid py-3">
    <div class="row">
        <div class="col-12">
            <table id="customers" class="table table-striped text-center">
                <thead>
                  <tr class="bg-secondary">
                    <th>Icono</th>
                    <th>Imagen</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let icon of icons; index as i; first as isFirst">
                    <td class="align-middle">{{icon.name}}</td>
                    <td class="align-middle">
                        <img src="{{icon.image}}" width="20px" height="20px"/>
                    </td>
                    <td>
                        <button class="btn">
                            <i class="material-icons text-primary">edit</i>
                        </button>

                        <button class="btn" (click)="deleteIcon(icon)">
                            <i class="material-icons text-danger">delete</i>
                        </button>
                    </td>
                  </tr>
                  <tr *ngIf="icons.length == 0">
                    <td colspan="3">Cargando</td>
                  </tr>
                </tbody>
  
              </table>
        </div>
    </div>
</div>
<button class="btn btn-lg position-fixed m-5 rounded-circle bg-success shadow" style="bottom: 0px; right: 0px;" (click)="open(content)">
  <i class="material-icons text-white mt-2">add</i>
</button>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Guardar Icono</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form id="formIcons" [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
      <div class="form-row">

        <div class="form-group col-12 mb-4">
          <label for="txtName">Nombre de Icono</label>
          <input [disabled]="loading" id="txtName" class="form-control material-design-primary" formControlName="name" placeholder="Nombre de Icono" name="name" required>
        </div>

        <div class="col-12">
          <label>Imagen</label>
          <input [disabled]="loading" type="file" class="form-control"
              accept="image/*" (change)="onUpload($event)" required>
        </div>

      </div>
    </form>
  </div>
  <div class="container-fluid px-4 py-3">
    <div class="row">
      <button type="button" [disabled]="loading" class="btn btn-danger col-12 mb-3" (click)="modal.close('Save click')">Cancelar</button>
      <button type="submit" [disabled]="loading" form="formIcons" class="btn btn-success col-12">Guardar Icono</button>

    </div>
  </div>
</ng-template>
