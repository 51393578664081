import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class IconsService {

    constructor(
        private firestore: AngularFirestore,
    ) {

    }

    getIcons(): AngularFirestoreCollection<any[]>{
        return this.firestore.collection('/Iconos');
    }

}