import { Component, OnInit } from '@angular/core';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  DocumentChangeAction 
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { flatMap, map, mergeMapTo } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from '../../../../services/toast/toast.service';
import { IconsService } from '../../../../services/icons/icons.service';
import { SecurityService } from '../../../../services/security/security.service';
import { DateTime } from 'luxon';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { firestore } from 'firebase';

@Component({
  selector: 'app-agregar-seguridad-departamento',
  templateUrl: './agregar-seguridad-departamento.component.html',
  styleUrls: ['./agregar-seguridad-departamento.component.css']
})
export class AgregarSeguridadDepartamentoComponent implements OnInit {

  public formReport: FormGroup;

  public icons: Array<any> = [];
  public iconSelected = '';
  public idCodominium: String = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore, 
    private formBuilder: FormBuilder,
    public iconsService: IconsService,
    private modalService: NgbModal,
    private securityService: SecurityService,
    public toastService: ToastService,
  ) { }

  close(content){
    this.activeModal.close(content);
  }

  get f(){ 
    return this.formReport.controls; 
  }

  getIcons(){
    this.icons = [];
    let ref = this.iconsService.getIcons().ref;
    ref.orderBy('name', 'asc')
    .get().then((icons) => {
      this.icons = icons.docs.map((icon) => icon.data());
    });
  }

  setIcon($obj){
    console.log($obj);
    this.iconSelected = (typeof $obj === 'undefined') ? '' : $obj.image;
    // console.log(this.formReport.value);
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.formReport);
    if (this.formReport.invalid || this.iconSelected == '') {
        return;
    }

    console.log('Si paso');
    console.log(this.idCodominium,);
    
    let data = {
      recordDate: firestore.Timestamp.now(),
      lastUpdate: firestore.Timestamp.now(),
      status: true,
      icon: this.iconSelected,
      ...this.formReport.value,
    };

    this.securityService.createSecurityFlat(this.idCodominium, data).then(() => {
      this.toastService.show(`¡Servicio ${data.name} de departamento para seguridad agrergada exitosamente!`, { classname: 'bg-success text-light'});
      console.log('Listo');
      this.close('');
      
    }, (error) => {
      console.error(error);
      this.toastService.show(`Servicio ${data.name} de departamento para seguridad no se pudo agregar`, { classname: 'bg-danger text-light'});
      this.close('');
    });
  }

  ngOnInit(): void {
    this.formReport = this.formBuilder.group({
      name: ['', Validators.required],
    });

    this.getIcons();
  }

}
