<div class="table100">
  <table id="customers">
    <tr>
      <th>Condominio</th>
      <th>Url</th>
      <th>Estatus</th>
      <th>Tipo de licencia</th>
      <th>Fecha de Vencimiento</th>
      <th>Acción</th>
    </tr>
    <tr *ngFor="let condo of condos; index as i; first as isFirst">
      <td>{{condo.nombre}}</td>
      <td>
        <img style="width:50px;height:50px;" src="{{condo.url}}">
      </td>
      <td *ngIf="condo.status == true">Activo</td>
      <td *ngIf="condo.status == false">Inactivo</td>
      <td *ngIf="condo.test == true">Prueba</td>
      <td *ngIf="condo.test == false">Licencia</td>
      <td>{{condo.fechaCad.seconds*1000 | date:'dd/MM/yyyy'}}</td>
      <td class="align-icon">

        <i (click)="getUsers(condo.id)" onclick="document.getElementById('id01').style.display='block'"
          class="fa fa-user fa-2x" aria-hidden="true"></i>
        <i (click)="update(condo.id,condos[i])" class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
        <i (click)="setCondo(condo)" onclick="document.getElementById('id02').style.display='block'"
          class="fa fa-calendar fa-2x" aria-hidden="true"></i>
        <i (click)="setDeleteId(condo.id)" onclick="document.getElementById('id03').style.display='block'" class="fa fa-trash fa-2x"
          aria-hidden="true"></i>
      </td>
    </tr>

  </table>
  <br>
  <div class="col-sm-12">
    <button routerLink="/condos-form" class="button">
      Nuevo
      <div class="button__horizontal"></div>
      <div class="button__vertical"></div>
    </button>
  </div>
</div>

<div class="row" style="width: 50%;">
  <div class="w3-container">
    <div id="id01" class="w3-modal" style="width: 110%; padding-top: 96px;">
      <div class="w3-modal-content">
        <header class="w3-container w3-teal" style="background-color: #008591 !important;">
          <span onclick="document.getElementById('id01').style.display='none'"
            class="w3-button w3-display-topright">&times;</span>
          <br>

          <h2> <i class="fa fa fa-users fa-2x" aria-hidden="true"></i> Administradores de este condominio</h2>


        </header>
        <div class="w3-container">
          <hr>

          <div class="table100">
            <table id="customers">
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Descripción</th>
                <th>Estatus</th>
                <th>Acción</th>

              </tr>
              <tr *ngFor="let user of users; index as i; first as isFirst">
                <td>{{user.nombre}}</td>
                <td>{{user.email}}</td>
                <td>{{user.descripcion}}</td>
                <td *ngIf="user.status == true">Activo</td>
                <td *ngIf="user.status == false">Inactivo</td>
                <td class="align-icon">
                  <i (click)="updateAdmin(user.id, users[i])" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  <i (click)="deleteAdmin(user.id)" class="fa fa-trash" aria-hidden="true"></i>
                </td>
              </tr>

            </table>
            <br>
            <div class="col-sm-12">
              <button routerLink="/admin-form" class="button">
                Nuevo
                <div class="button__horizontal"></div>
                <div class="button__vertical"></div>
              </button>
              <br>
              <br>
            </div>
          </div>
          <hr>
        </div>
        <!-- <footer class="w3-container w3-teal">
            <p>Modal Footer</p>
          </footer> -->
      </div>
    </div>
  </div>

</div>

<div class="row" style="width: 50%;">
  <div class="w3-container">
    <div id="id02" class="w3-modal" style="width: 110%; padding-top: 96px;">
      <div class="w3-modal-content">
        <header class="w3-container w3-teal" style="background-color: #008591 !important;">
          <span onclick="document.getElementById('id02').style.display='none'"
            class="w3-button w3-display-topright">&times;</span>
          <br>

          <h2> <i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i> Cambio de fehca de vencimiento para
            condominios.</h2>


        </header>
        <div class="w3-container">
          <br>
          <div *ngIf="showAlert" class="alert">
            <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
            <strong><i class="fa fa-flag" aria-hidden="true"></i></strong> Se ha cambiado la fecha de vencimiento.
          </div>

          <hr>

          <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>

            <div class="row">

              <div class="form-group col-6">
                <label>Tipo de Licencia</label>
                <select formControlName="test">
                  <option selected value="" disabled>Seleccionar tipo de licencia</option>
                  <option [value]="true">Prueba</option>
                  <option [value]="false">Licencia</option>
                </select>
              </div>

              <div class="form-group col-6">
                <label for="fechaCad">Fecha de vencimiento</label>
                <input formControlName="fechaCad" type="date" id="fechaCad" class="form-control"
                  placeholder="Fecha de reservación" name="fechaCad">
              </div>

            </div>


            <div class="col-6">
              <input [disabled]="checkoutForm.invalid" type="submit" value="Cambiar fecha de vencimiento">
            </div>
          </form>


          <hr>
        </div>
        <!-- <footer class="w3-container w3-teal">
            <p>Modal Footer</p>
          </footer> -->
      </div>
    </div>
  </div>

</div>

<div class="row" style="width: 30%;">
  <div class="w3-container">
    <div id="id03" class="w3-modal">
      <div class="w3-modal-content" style="width:387px;">
        <header class="w3-container w3-teal" style="background-color: #008591 !important;">
          <span onclick="document.getElementById('id03').style.display='none'"
            class="w3-button w3-display-topright">&times;</span>
          <br>

          <h2> <i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i> ¿Esta seguro que quiere eliminar este
            condominio permanentemente?</h2>


        </header>
        <!-- <div class="w3-container">
          <br>
          <div *ngIf="showAlert" class="alert">
            <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
            <strong><i class="fa fa-flag" aria-hidden="true"></i></strong> Se ha cambiado la fecha de vencimiento.
          </div>

          <hr> -->

        <!-- <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>

            <div class="row">

              <div class="form-group col-6">
                <label>Tipo de Licencia</label>
                <select formControlName="test">
                    <option selected value="" disabled>Seleccionar tipo de licencia</option>
                    <option [value]="true">Prueba</option>
                    <option [value]="false">Licencia</option>
                </select>
            </div>

              <div class="form-group col-6">
                <label for="fechaCad">Fecha de vencimiento</label>
                <input formControlName="fechaCad" type="date" id="fechaCad" class="form-control"
                  placeholder="Fecha de reservación" name="fechaCad">
              </div>             

            </div>
          -->

        <div class="row" style="padding-left: 78px;">
          <div class="col-sm-6">
            <input (click)="delete(deleteId)" type="submit" value="Si">
          </div>


          <div class="col-sm-6">
            <input onclick="document.getElementById('id03').style.display='none'" type="submit" value="No">
          </div>
        </div>


        <!-- </form> -->


        <!-- <hr>
        </div> -->
        <!-- <footer class="w3-container w3-teal">
            <p>Modal Footer</p>
          </footer> -->
      </div>
    </div>
  </div>

</div>