import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-agregar-servicio',
  templateUrl: './agregar-servicio.component.html',
  styleUrls: ['./agregar-servicio.component.css']
})
export class AgregarServicioComponent implements OnInit {

  public towers = [];
  public departaments = [];
  public model: NgbDateStruct;
  date: { year: number, month: number };
  checkoutForm;
  public torre: any;
  public departamento: any;
  public icons = [];
  public icon: any;

  data: { nombre: string, apellido: string, departamento: string, torre: string, fecha: string }
  constructor(
    private firestoreService: FirestoreService, 
    private calendar: NgbCalendar, 
    private formBuilder: FormBuilder, 
    private router: Router, 
    private firestore: AngularFirestore
  ) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      icon:[''],
      status: ''
    });
  }

  ngOnInit(): void {
    this.getIcons();
  }

  setIcon($obj){
    this.icon = $obj;
    // console.log($obj);
  }

  getIcons(){
    this.firestore.collection('/Iconos').snapshotChanges()
    .pipe(
      map(
        icons => icons.map(
          c => ({key: c.payload.doc.id, ... c.payload.doc.data() as DocumentChangeAction<any>})
        )
      )
    ).subscribe(icons => {
      this.icons = icons;  
    })
  }

  onSubmit(customerData) {
    var date = new Date();
    customerData.icon = this.icon.image;
    customerData.status = true;
    customerData.fechaAlta = date.getTime();
    this.firestoreService.createService(customerData).then(() => {
    }, (error) => {
      console.error(error);
    });
    this.router.navigate(['/servicios']);
  }

}


