import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  public servicios = [];

  constructor(private firestoreService: FirestoreService) { }

  ngOnInit(): void {
    this.getServices();

  }

  getServices() {
    this.firestoreService.getServicesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(departaments => {
      this.servicios = departaments;
      console.log(departaments);
    });
  }

  delete(key) {
    this.firestoreService
      .deleteService(key)
      .catch(err => console.log(err));
  }

  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateService(key, data)
      .catch(err => console.log(err));
  }

}
