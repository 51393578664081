import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ModalDetalleReporteComponent } from '../modal-detalle-reporte/modal-detalle-reporte.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast/toast.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'app-lista-gestion-seguridad',
  templateUrl: './lista-gestion-seguridad.component.html',
  styleUrls: ['./lista-gestion-seguridad.component.css']
})
export class ListaGestionSeguridadComponent implements OnInit {

  @Input() reports: Array<any>;
  @Input() loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal, 
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  /*
  ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗
  ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║
  ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║
  ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║
  ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  */
  /**
   * FUNCTION downloadReport
   */
  downloadReport() {

    let reportDate: string = moment().format('DDMMYYYYHHmm');

    let headers = [
      "Reporte",
      "Tipo de reporte",
      "Departamento / Área",
      "Fecha de alta",
      "Fecha de cierre",
      "Estatus"
    ];

    let templateToExcel: any[][] = [];

    console.log(this.reports);
    

    this.reports.forEach(
      (report, index) => {
        if(index === 0) {
          templateToExcel.push(headers);
        }

        let data = [
          report.servicio,
          report.isArea ? 'Área' : 'Departamento',
          report.departamento,
          new Date(report.fechaAlta.seconds * 1000),
          report.fechaCierre
            ? report.fechaCierre.seconds
              ? new Date(report.fechaCierre.seconds * 1000)
              : new Date(report.fechaCierre)
            : '',
          report.status == 0 
            ? 'Pendiente' 
            : report.status == 1 
              ? 'En proceso' 
              : 'Concluido'
        ];

        templateToExcel.push(data);

      }
    );

    console.log(templateToExcel);
    

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(templateToExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reportes de seguridad');
    //console.log(fecha_reporte);
    XLSX.writeFile(wb, `security-reports_${reportDate}.xlsx`);

  }

  openReportDetail(report){
    console.log(report);
    
    const modalRef = this.modalService.open(ModalDetalleReporteComponent, {
      // size: 'lg'
    })
    
    modalRef.componentInstance.report = report;
  }

}
