<head>
    <title>Avisos • CondoClick</title>
</head>

<div class="table100">
    <table id="customers" class="text-center">
        <thead>
            <th class="align-middle">Torre</th>
            <th class="align-middle">Imagen</th>
            <th class="align-middle">Descripción</th>
            <th class="align-middle">Fecha Alta</th>
            <th class="align-middle">Acción</th>

        </thead>
        <tr *ngFor="let aviso of avisos; index as i; first as isFirst">
            <td class="align-middle">{{aviso.titulo}}</td>
            <td *ngIf="aviso.formato != 'pdf'" class="align-middle">
                <a target="_blank" href="{{aviso.url}}"><img style="width:50px;height:50px;" src="{{aviso.url}}"></a>
            </td>
            <td *ngIf="aviso.formato == 'pdf'" class="align-middle">
                <a target="_blank" href="{{aviso.url}}" class="text-black">
                    <i class="material-icons">description</i>
                    <br>
                    Ver archivo
                </a>
            </td>
            <td class="align-middle">{{aviso.descripcion}}</td>
            <td class="align-middle">{{aviso.fechaAlta.seconds*1000 | date:'dd/MM/yyyy'}}</td>
            <td class="align-icon">
                <ng-template #borrar>Borrar aviso</ng-template>
                <i [ngbTooltip]="borrar" (click)="delete(aviso)" class="fa fa-trash" aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <br>
    <div class="col-sm-12">
        <button routerLink="/notificaciones" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
        <!-- <i routerLink="/areas-form" class="fa fa-plus-square fa-2x"></i> -->
    </div>
</div>