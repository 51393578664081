<ul class="nav nav-pills mb-3 tab-material" id="pills-tab" role="tablist">
  <li>
    <a class="btn pills-tab active" id="pills-home-tab" data-toggle="pill" routerLink="/reservaciones/{{idArea}}/proximas"
      role="tab" aria-controls="pills-home" aria-selected="false">Pendientes</a>
    <a class="btn pills-tab" id="pills-profile-tab" data-toggle="pill" routerLink="/reservaciones/{{idArea}}/todas" role="tab"
      aria-controls="pills-profile" aria-selected="true">Todas</a>
  </li>
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    <div class="container-fluid py-3">
      <div class="row">
        <div class="col-12">
          <div class="table100">
            <table id="customers" class="table table-striped text-center">
              <thead>
                <tr class="bg-secondary">
                  <th>Torre</th>
                  <th>Departamento</th>
                  <th>Area</th>
                  <th>Fecha</th>
                  <th>Inicio</th>
                  <th>Fin</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let reserv of reservations | slice: (page-1) * pageSize : page * pageSize; index as i; first as isFirst" [ngClass]="{'table-danger': !reserv.status}">
                  <td class="align-middle">{{reserv.departamento}}</td>
                  <td class="align-middle">{{reserv.torre}}</td>
                  <td class="align-middle">{{reserv.area}}</td>
                  <td class="align-middle">{{reserv.fechaInicio.seconds*1000 | date: 'EEEE, MMMM d, y'}}</td>
                  <td class="align-middle">{{reserv.fechaInicio.seconds*1000 | date: 'HH:mm'}}</td>
                  <td class="align-middle">{{reserv.fechaFin.seconds*1000 | date: 'HH:mm'}}</td>
                  <td class="align-middle">
                    <!-- <button class="btn" (click)="update(user.id)"><i class="material-icons">edit</i></button> -->
                    <ng-template #tipContentOption>Detalles</ng-template>
                    <button *ngIf="kindOfUser == '1'" (click)="showDetails(reserv, showDetailsBook)" class="btn" [ngbTooltip]="tipContentOption"><i
                        class="material-icons">more_vert</i></button>

                    <ng-template #tipContentCancel>Cancelar</ng-template>
                    <button *ngIf="isEliminar == 'true' && reserv.status" (click)="cancel(reserv.id)" class="btn" [ngbTooltip]="tipContentCancel"><i
                        class="material-icons">clear</i></button>

                    <ng-template #tipContent>Borrar</ng-template>
                    <button *ngIf="isEliminar == 'true'" (click)="delete(reserv.id)" class="btn" [ngbTooltip]="tipContent"><i
                        class="material-icons">delete</i></button>
                  </td>
                </tr>
                <tr *ngIf="reservations.length == 0">
                  <td colspan="7">No hay reservaciones</td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-center mt-5">
          <ngb-pagination [(page)]="page" [maxSize]="5" [pageSize]="pageSize" [collectionSize]="reservations.length">
          </ngb-pagination>
        </div>

      </div>
    </div>

  </div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

  </div>
</div>

<ng-template #showDetailsBook let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><b>Reservación #{{currentBook.id}}</b></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 mb-2">
            <span class="text-secondary">General</span>
          </div>

          <div class="col-12 mb-2">
            <div class="row">

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Área</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.area}}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Departamento</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.departamento}}
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="row">

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Torre</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.torre}}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Usuario</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.usuario}}
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <div class="col-12">
            <div class="row">

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Fecha de inicio</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.fechaInicio.seconds*1000 | date: 'dd/MM/yyyy HH:mm'}}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Fecha fin</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.fechaFin.seconds*1000 | date: 'dd/MM/yyyy HH:mm'}}
                  </div>
                </div>
              </div>
              
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 mb-2">
            <span class="text-secondary">Dispositivo</span>
          </div>

          <div class="col-12">
            <div class="row">

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">SO</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.os}}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Modelo</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.model}}
                  </div>
                </div>
              </div>
              
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 mb-2">
            <span class="text-secondary">Versión</span>
          </div>

          <div class="col-12">
            <div class="row">

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Versión</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.version}}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <small class="text-secondary">Build</small>
                  </div>
                  <div class="col-12">
                    {{currentBook.build}}
                  </div>
                </div>
              </div>
              
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark col" (click)="modal.close('Save click')">OK</button>
  </div>
</ng-template>

<app-modal-reservacion></app-modal-reservacion>