import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot, DocumentReference } from '@angular/fire/firestore';
import { FirebaseAuthService } from '../../services/firebase/firebase-auth.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

import { AddressService } from 'src/app/services/address/address.service';

import { Neighborhood } from 'src/app/models/neighborhood/neighborhood.model';


@Component({
  selector: 'app-agregar-condo',
  templateUrl: './agregar-condo.component.html',
  styleUrls: ['./agregar-condo.component.css']
})
export class AgregarCondoComponent implements OnInit {

  public checkoutForm: FormGroup;
  public downloadURL;
  public urlImagen: string;
  public colonias: Array<Neighborhood> = [];
  public loading: boolean = false;
  public neighborhoodSelected: Neighborhood = null;
  public estados = [];
  public municipios = [];
  public fileSelected: any;

  constructor(
    private firestoreService: FirestoreService, 
    private router: Router, 
    private formBuilder: FormBuilder, 
    private firestore: AngularFirestore,
    private storage: AngularFireStorage, 
    private firebaseAuth: FirebaseAuthService,
    private addressService: AddressService
  ) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      codigo: ['', [Validators.required]],
      auxColonia: [''],
      colonia: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      estado: ['', [Validators.required]],
      calle: ['', [Validators.required]],
      fechaCad: ['', [Validators.required]],
      imagen: [''],
      test: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getEstados();
  }

  selectedFileFrontal = null;

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION onUpload                             */
  /* -------------------------------------------------------------------------- */

  onUpload(e) {
    // console.log(e);
    // console.log(e.target.files[0]);
    

    // this.checkoutForm.patchValue({
    //   'imagen': e.target.files[0].name,
    // });

    this.fileSelected = e.target.files[0];

    // console.log(this.fileSelected);
    
    // try {
    //   const id = Math.random().toString(36).substring(2);
    //   const file = e.target.files[0];
    //   const filePath = `images/condos_${id}`;
    //   const ref = this.storage.ref(filePath);
    //   const task = this.storage.upload(filePath, file);
    //   task.snapshotChanges()
    //     .pipe(
    //       finalize(() => {
    //         this.downloadURL = ref.getDownloadURL();
    //         this.downloadURL.subscribe(downloadURLResponse => {
    //           this.urlImagen = downloadURLResponse;
    //         });
    //       }),
    //     )
    //     .subscribe();
    // } catch {
    //   console.log(console.error());
    // }

  }

  async onSubmit() { 
    console.log(this.checkoutForm);

    if(this.fileSelected == null){
      console.log('no se pudo');
      return;
    }

    console.log('si se pudo');
    

    try {
      this.loading = true;
      // const id = Math.random().toString(36).substring(2);
      // const file = this.fileSelected;
      // const filePath = `images/condominio/${id}`;
      // const ref = this.storage.ref(filePath);
      // const task = this.storage.upload(filePath, file);
      // task.snapshotChanges()
      //   .pipe(
      //     finalize(() => {
      //       this.downloadURL = ref.getDownloadURL();
      //       this.downloadURL.subscribe(downloadURLResponse => {
      //         this.urlImagen = downloadURLResponse;
      //       });
      //     }),
      //   )
      //   .subscribe();

      let docCondo = await this.firestore.collection('/Condominios')
      .add({
        dueDate: moment().add({ months: 1 }).toDate(),
        url: '',
        fechaCad: moment(this.checkoutForm.value.fechaCad).toDate(),
        status: true,
        test: this.checkoutForm.value.test == 'true' ? true : false,
        nombre: this.checkoutForm.value.nombre.trim(),
        codigo: this.checkoutForm.value.codigo.trim(),
        colonia: this.checkoutForm.value.colonia.trim(),
        ciudad: this.checkoutForm.value.ciudad.trim(),
        estado: this.checkoutForm.value.estado.trim(),
        calle: this.checkoutForm.value.calle.trim(),
      });

      console.log('segun se subio');

      console.log(docCondo.id);
      
      
      let split = this.fileSelected.name.split('.');
      let fileName = `${docCondo.id}.${split[split.length - 1].toLowerCase()}`;
      let filePath = `images/condominiums/${docCondo.id}/${fileName}`;
      let ref = this.storage.ref(filePath);
      let task = this.storage.upload(filePath, this.fileSelected);
      task.snapshotChanges()
        .pipe(
            finalize(() => {
              this.downloadURL = ref.getDownloadURL();
              this.downloadURL.subscribe( async downloadURLResponse => {
                this.urlImagen = downloadURLResponse;
                await docCondo.update({
                  'url': downloadURLResponse,
                  'filePath': filePath
                });

                this.loading = false;

                this.router.navigate(['/condominios']);
              });
            }),
          )
        .subscribe();
      
      this.loading = false;

    } catch {
      console.log(console.error());
      this.loading = false;
    }
    
    // areaData.url = this.urlImagen;
    // areaData.status = true;
    // areaData.fechaCad = moment(areaData.fechaCad).toDate();
    // areaData.test = areaData.test == 'true' ? true : false;

    // this.firestoreService.createCondo(areaData).then(() => {
    // }, (error) => {
    //   console.error(error);
    // });
    // this.router.navigate(['/condominios']);
  }

  getAddress(data: string) {
    if(data.length == 5){
      console.log(data);
      
      this.addressService.getNeighborhoods(data).subscribe(
        response => {
          // console.log(response);

          response.forEach(res => {
            // console.log(res);

            this.colonias.push(res.response);
            
          });
          
          // this.colonias = response;
          this.colonias.sort((a,b) => a.asentamiento.localeCompare(b.asentamiento))
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  getEstados() {
    // this.firebaseAuth.getEstados().subscribe(
    //   response => {
    //     this.estados = response.response.estado;
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );
  }

  onChange(newValue) {
    console.log(this.neighborhoodSelected);

    // this.checkoutForm.value.estado = this.neighborhoodSelected.estado;
    this.checkoutForm.patchValue({
      'estado': this.neighborhoodSelected.estado,
      'ciudad': this.neighborhoodSelected.municipio,
      'colonia': this.neighborhoodSelected.asentamiento
    });

    console.log(this.checkoutForm);
    
  }

}
