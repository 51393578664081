<div class="container-fluid padding-bottom-3x pt-4 m-t-4r">
    <div class="row">
  
      <div class="col-12 mb-4">
        <h1>Aviso de Privacidad</h1>
      </div>
  
      <div class="col-12">
        <p class="text-justify">
          Este documento tiene por objeto, informarle sobre el tratamiento y la protección de los datos
          personales, que nos proporcione por cualquiera de los conceptos que más adelante se señalan.
          Es primordial para nosotros mantener la privacidad de sus datos personales, conforme a lo
          dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
          <br><br>
          La información que se le solicitará incluye datos personales necesarios para: <b>ofrecerle los diferentes
          productos y servicios de CondoClick, así como para fines de identificación, operación,
          administración y comercialización.</b> Sin embargo los datos que nos proporcione, no son de los
          clasificados como datos personales sensibles.
          <br><br>
          Los datos personales que nos proporcione se resguardarán en nuestras bases de datos y únicamente
          tendrán acceso a los mismos personas autorizadas, por lo que no serán utilizados para ningún fin que
          no esté autorizado por usted.
          <br><br>
          Usted como titular, o su representante legal, tiene en todo momento, derecho a Acceder a sus datos
          personales y a Rectificarlos cuando sean inexactos o incompletos. Asimismo, puede solicitar la
          Cancelación de sus datos personales de nuestra base de datos u Oposición a su tratamiento, conforme
          lo dispone la Ley aplicable.
          <br><br>
          Para lo anterior, usted deberá entregar un escrito, dirigido al <b>RESPONSABLE DE PROTECCIÓN DE
          DATOS PERSONALES,</b> en la sucursal donde ha efectuado sus operaciones, indicando la acción
          conforme a derecho que desea realizar con sus datos - Acceso, Rectificación, Cancelación, Oposición
          - (A partir del 06 de Enero de 2012).
          <br><br>
          Usted autoriza a Cleversec SAPI de CV, con domicilio en Av. Ruiz Cortinez Mz 1 Lt 20 Of 22, 
          Lomas de Atizapán 2a sección, Atizapán de Zaragoza, Estado de México, C.P. 52977, a administrar
          sus datos personales y a transferir a terceros, exclusivamente para los propósitos antes mencionados.
          <br><br>
          <b>Cambios al Aviso de Privacidad:</b> en caso de que resulte necesario modificar el presente Aviso, usted
          podrá consultarlo en este establecimiento.
          <br><br>
          <b>ATENTAMENTE.
          <br><br>
          DEPARTAMENTO DE PROTECCIÓN DE DATOS PERSONALES
          Cleversec SAPI de CV </b>
        </p>
      </div>
  
    </div>
  </div>