import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-lista-visitas-historial',
  templateUrl: './lista-visitas-historial.component.html',
  styleUrls: ['./lista-visitas-historial.component.css']
})
export class ListaVisitasHistorialComponent implements OnInit {

  @Input() visitors: Array<any> = [];
  @Input() loading: boolean;

  public rangeVisitors: Array<any> = [];
  public history = true;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  constructor(
    private calendar: NgbCalendar
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }

  ngOnInit(): void {
    // this.rangeVisitors = [...this.visitors];
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else if (this.fromDate && !this.toDate && date.equals(this.fromDate)) {
      this.toDate = date;
    }
     else {
      this.toDate = null;
      this.fromDate = date;
    }

    if(this.fromDate && this.toDate){
      // console.log("SIIII");
      
      this.rangeVisitors = [];

      let fromTime = moment([this.fromDate.year, this.fromDate.month, this.fromDate.day]);
      let toTime = moment([this.toDate.year, this.toDate.month, this.toDate.day]);
      // console.log(moment([this.fromDate.year, this.fromDate.month, this.fromDate.day]).format());
      // console.log(moment([this.toDate.year, this.toDate.month, this.toDate.day]).format());

      this.visitors.forEach((visitor, index) => {
        
        let timeB = moment(visitor.dateCreated.toDate());

        // console.log(timeB.isAfter(fromTime));
        // console.log(timeB.format());
        // console.log(fromTime.format());
        // console.log(toTime.add(1, 'd').format());

        timeB = timeB.add(1, 'M');
        
        if(timeB.isSameOrAfter(fromTime,'day') && timeB.isSameOrBefore(toTime,'day')){
          this.rangeVisitors.push(visitor);
        }

        this.rangeVisitors.sort((a,b) => {
          return b.dateCreated - a.dateCreated;
        });
      });
    }

  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

}
