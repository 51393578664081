import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FirebaseAuthService } from '../../services/firebase/firebase-auth.service';
import { FirestoreService } from '../../services/firestore/firestore.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  checkoutForm;
  public dataMail;
  public nombre;
  public idCondomino;
  public descripcion;
  public showAlert;
  public isLogged;

  constructor(private formBuilder: FormBuilder, private firebaseAuth: FirebaseAuthService, private firestoreService: FirestoreService) {
    this.checkoutForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]],
      mensaje: ['', [Validators.required]],
      like: ['']
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('descripcion') != null)
      this.descripcion = localStorage.getItem("descripcion").replace(/['"]+/g, '');
    if (localStorage.getItem('nombre') != null) {
      this.nombre = localStorage.getItem("nombre").replace(/['"]+/g, '');
      this.isLogged = true;
    }
    else {
      this.isLogged = false;
    }

    this.idCondomino = localStorage.getItem("userOper");
  }

  onSubmit(data) {
    this.dataMail = {
      to: "condoclick@cleversec.com.mx",
      message: data.value.mensaje,
      subject: data.value.email,
    }

    this.sendMail(this.dataMail);

    if (data.valid) {
      data.reset();
    }
  }

  sendMail(data) {
    this.firebaseAuth.sendMail(data).subscribe(
      response => {
        console.log(response);
        this.showAlert = true;
      },
      error => {
        console.log(error);
      }
    );
  }

  done() {
    this.firestoreService.receiveMessage();
  }

}
