<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <table class="table table-striped text-center">
              <thead>
                <tr class="bg-secondary">
                  <th>Nombre</th>
                  <th>Estatus</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let area of areas; index as i; first as isFirst">
                    <td class="align-middle">
                        <img src="{{area.icon}}" width="5%">
                      {{area.name}}
                    </td>
                    <td class="align-middle">
                        <div class="d-flex justify-content-center">
                            <div class="checkbox checkbox-slider--b-flat checkbox-slider-md">
                                <label>
                                    <input type="checkbox" class="" [(ngModel)]="area.status" (change)="changeValueStatus(area)" [checked]="area.status"><span class="m-0"></span>
                                </label>
                            </div>
                        </div>
                    </td>
                  <!-- <td class="align-middle">{{reserv.torre}}</td>
                  <td class="align-middle">{{reserv.area}}</td>
                  <td class="align-middle">{{reserv.fechaInicio.seconds*1000 | date: 'EEEE, MMMM d, y'}}</td>
                  <td class="align-middle">{{reserv.fechaInicio.seconds*1000 | date: 'HH:mm'}}</td>
                  <td class="align-middle">{{reserv.fechaFin.seconds*1000 | date: 'HH:mm'}}</td> -->
                    <td class="align-middle">
                        <ng-template #tipContentEdit>Detalles</ng-template>
                        <a [routerLink]="['/gestion-seguridad/areas', area.id]" class="btn" [ngbTooltip]="tipContentEdit"><i
                            class="material-icons">more_vert</i>
                        </a>

                        <ng-template #tipContentDelete>Borrar</ng-template>
                        <button class="btn" [ngbTooltip]="tipContentDelete" (click)="deleteArea(area)"><i
                            class="material-icons text-danger">delete</i>
                        </button>
                  </td>
                </tr>
                <tr *ngIf="loading">
                    <td colspan="3">Cargando...</td>
                  </tr>
                <tr *ngIf="areas.length == 0 && !loading">
                  <td colspan="3">No hay áreas para seguridad creadas</td>
                </tr>
              </tbody>

            </table>
          </div>
    </div>
</div>
<button class="btn btn-lg position-fixed m-5 rounded-circle bg-success shadow" style="bottom: 0px; right: 0px;" (click)="openModalCreateNewArea()">
    <i class="material-icons text-white mt-2">add</i>
</button>
