import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators'; 
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-areas-comunes',
  templateUrl: './areas-comunes.component.html',
  styleUrls: ['./areas-comunes.component.css']
})
export class AreasComunesComponent implements OnInit {

public areas = [];

  constructor(
    private firestoreService: FirestoreService,
    private firestore: AngularFirestore,
    ) { }

  ngOnInit(): void {
    this.getAreasList();
  }

  getAreasList() {
    this.firestoreService.getAreasList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(areas => {
      this.areas = areas;
    });
  }

  deleteArea(key) {
    this.firestoreService
      .deleteArea(key)
      .catch(err => console.log(err));
  }

  update(key, data) {
    if(data.status)
    data.status = false;
    else
    data.status = true;
    this.firestoreService
      .updateArea(key, data)
      .catch(err => console.log(err));
  }

}
