
<div class="container-fluid" *ngIf="!isCondo">
    <div class="row">
  
      <div class="col-sm-2" *ngFor="let condo of condos; index as i; first as isFirst">
        <div class="card">
          <img (click)="condoKey(condo.key)" src="{{condo.url}}" alt="Avatar" style="width:100%">
          <div class="container">
            <h4><b>{{condo.nombre}}</b></h4> 
            <!-- <p>Torre del lado sur</p> -->
          </div>
        </div>
      </div>
  
    </div>
  </div>

<div class="table100" *ngIf="isCondo">
    <table id="customers">
        <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Descripción</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr *ngFor="let user of users; index as i; first as isFirst">
            <td>{{user.nombre}}</td>
            <td>{{user.email}}</td>
            <td>{{user.descripcion}}</td>
            <td *ngIf="user.status == true">Activo</td>
            <td *ngIf="user.status == false">Inactivo</td>
            <td class="align-icon"> 
                <i (click)="update(user.id, users[i])" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                <i (click)="delete(user.id)" class="fa fa-trash" aria-hidden="true"></i>
            </td>
        </tr>

    </table>
    <br>
    <div class="col-sm-12">
        <button routerLink="/admin-form" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
    </div>
</div>