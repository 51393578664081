
import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { flatMap, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';


@Component({
  selector: 'app-administradores',
  templateUrl: './administradores.component.html',
  styleUrls: ['./administradores.component.css']
})
export class AdministradoresComponent implements OnInit {

  public users = [];
  public towers = [];
  public condos = [];
  public isCondo = false;
  public idCondominio;
  public dataAdmin;

  constructor(private firestoreService: FirestoreService, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getUsers();
    this.getTowersList();
    this.getCondos();
  }

  getUsers() {
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('idTipo', '==', 2))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
      snapshotResult.subscribe(reser => {
        this.users = reser;
      });


  }

  delete(key, email) {
    this.firestoreService
      .deleteAdminRoot(key)
      .catch(err => console.log(err));
    // this.getAdmin(email);
  }

  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    this.firestoreService
      .updateAdmin(key, data)
      .catch(err => console.log(err));
  }

  getTowersList() {
    this.firestoreService.getTowersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(towers => {
      this.towers = towers;
    });
  }

  getCondos() {
    this.firestoreService.getCondosList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(condos => {
      this.condos = condos;
    });
  }

  condoKey(key) {
    this.isCondo = true;
  }

  getAdmin(email) {
    const snapshotResult = this.firestore.collection('/Administradores', ref =>
      ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(flatMap(clients => clients));

    snapshotResult.subscribe(doc => {
      this.firestoreService
        .deleteAdminRoot(doc.payload.doc.id)
        .catch(err => console.log(err));
    });

  }
}
