<head>
    <title>Crear aviso • CondoClick</title>
</head>

<div *ngIf="register" class="alert">
    <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span> 
    <strong><i class="fa fa-flag" aria-hidden="true"></i></strong> El aviso fue dado de alta
  </div>

<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-6">
                <label>Titulo</label>
                <input id="titulo" type="text" formControlName="titulo"><span class="highlight"></span>
            </div>

            <div class="col-sm-6">
                <label>Descripcion</label>
                <input id="titulo" type="text" formControlName="descripcion"><span class="highlight"></span>
            </div>

            <div class="col-sm-12">
                <label>Imagen</label>
                <input type="file" class="form-control material-design-primary" accept="image/*,application/pdf"
                    (change)="setFile($event)" required>
            </div>

            <div class="col-sm-12">
                <input type="submit" value="Guardar">
            </div>
        </div>
    </div>
</form>

