<!-- <div *ngIf="checkoutForm.invalid" class="alert">
    <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
    <strong><i class="fa fa-flag" aria-hidden="true"></i></strong> Tiene que ingresar todos los campos.
</div> -->

<div class="row" style="width: 30%; display: contents;">
    <div class="w3-container">
        <div id="id03" class="w3-modal">
            <div class="w3-modal-content" style="width:387px;">
                <header class="w3-container w3-teal" style="background-color: #008591 !important;">
                    <span onclick="document.getElementById('id03').style.display='none'"
                        class="w3-button w3-display-topright">&times;</span>
                    <br>

                    <h2> <i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i> ¿Esta seguro que quiere eliminar
                        este
                        condominio permanentemente?</h2>


                </header>

                <div class="row" style="padding-left: 78px;">
                    <div class="col-sm-6">
                        <input type="submit" value="Si">
                    </div>


                    <div class="col-sm-6">
                        <input onclick="document.getElementById('id03').style.display='none'" type="submit" value="No">
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>


<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)" novalidate>
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-6">
                <label>Nombre del Área</label>
                <input id="nombre" type="text" formControlName="nombre" required>
            </div>
            <div class="col-sm-6">
                <label>Maximo de personas (Seleccionar 1 para reservación unica por departamento)</label>
                <select formControlName="capacidad">
                    <option selected value="" disabled>Seleccionar capacidad máxima</option>
                    <option *ngFor="let cap of capacidadMax; index as i; first as isFirst" [value]="cap">
                        {{cap}}
                    </option>
                </select>
            </div>

            <div class="col-sm-6">
                <label>Hora Apertura</label>
                <ng-select [items]="hours" formControlName="horaApertura" [searchable]="false" [(ngModel)]="hourStart">
                </ng-select>
            </div>
            <div class="col-sm-6">
                <label>Hora Cierre </label>
                <ng-select [items]="hours" formControlName="horaCierre" [searchable]="false" [(ngModel)]="hourEnd">
                </ng-select>
            </div>

            <!-- <div class="form-group col-md-6">
                <label for="heroId">Basic select</label>
                <ng-select appearance="outline" [searchable]="false" [clearable]="false" labelForId="heroId" formControlName="heroId">
                    <ng-option value="batman">
                        <img src="assets/batman.png" width="20px" height="20px"/>
                        Batman
                    </ng-option>
                    <ng-option value="spidey">
                        <img src="assets/spidey.png" width="20px" height="20px"/>
                        Spider-Man & Goblin
                    </ng-option>
                    <ng-option value="thor">
                        <img src="assets/thor.png" width="20px" height="20px"/>
                        Thor
                    </ng-option>
                </ng-select>
            </div> -->

            <div class="col-sm-6 form-group">
                <label>Icono</label>
                <div>
                    <img src="{{imageFront}}" width="20px" height="20px" />
                    <label>{{iconFront}}</label>
                </div>


                <ng-select [items]="icons" [searchable]="false" [clearable]="true" (change)="setIcon($event)"
                    name="icon">
                    <ng-template ng-label-tmp let-item="item">
                        <span class="mx-1">{{item.name}}</span>
                        <img src="{{item.image}}" width="20px" height="20px" />
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <img src="{{item.image}}" width="20px" height="20px" />
                        <span class="mx-1">{{item.name}}</span>
                    </ng-template>
                </ng-select>
            </div>

            <!-- <div class="col-sm-6">
                <label>Icono</label>
                <select formControlName="capacidad">
                    <option selected value="" disabled>Seleccionar icono</option>
                    <option *ngFor="let icon of icons; index as i; first as isFirst" [value]="icon">
                        {{icon.name}}
                        <img src="{{icon.image}}" width="20px" height="20px"/>
                    </option>
                </select>
            </div> -->

            <div class="col-sm-6">
                <label>Bloques maximos por dia por departamento</label>
                <select formControlName="porDepartamento">
                    <option selected value="" disabled>Seleccionar bloques maximos por dia por departamento</option>
                    <option *ngFor="let blo of bloquesMax; index as i; first as isFirst" [value]="blo">
                        {{blo}}
                    </option>
                </select>
            </div>

            <div class="col-sm-6">
                <label>Duración de bloques</label>
                <select formControlName="bloquesHorario">
                    <option selected vlaue="" disabled>Seleccionar duración</option>
                    <option value="1">1 hora</option>
                    <option value="2">2 horas</option>
                    <option value="3">3 horas</option>
                    <option value="4">4 horas</option>
                    <option value="5">5 horas</option>
                    <option value="6">6 horas</option>
                </select>

            </div>

            <div class="col-sm-6">
                <label>Se puede reservar desde</label>
                <select formControlName="antelacion">
                    <option selected vlaue="" disabled>Seleccionar tiempo</option>
                    <option *ngFor="let blo of bloquesMax; index as i; first as isFirst" [value]="blo">
                        {{blo}} horas antes
                    </option>

                </select>

            </div>

            <!-- <div class="col-sm-12">
                <label>Imagen</label>
                <input formControlName="imagen" type="file" class="form-control material-design-primary"
                    id="FotoReverso" name="zipcode" (change)="onSelectedFileFrontal($event)" required>
            </div> -->

            <div class="col-sm-12">
                <label>Mensaje para el usuario</label>
                <textarea type="text" formControlName="mensaje" class="form-control"
                    placeholder="Mensaje para el usuario" rows="4"></textarea>
            </div>


            <!-- <div class="col-sm-12">
                <input (click)="deleteBloques()" class="w-100" type="button" value="Borrar bloques">
            </div> -->

            <div class="col-sm-12">
                <input class="w-100" type="submit" value="Editar Area">
            </div>
        </div>
    </div>

</form>