import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';

import { UtilService } from 'src/app/services/util/util.service';
import { FlatService } from 'src/app/services/flat/flat.service';

@Component({
  selector: 'app-torres',
  templateUrl: './torres.component.html',
  styleUrls: ['./torres.component.css']
})
export class TorresComponent implements OnInit {

  public towers = [];
  public idCondominio;

  constructor(
    private firestore: AngularFirestore, 
    private firestoreService: FirestoreService,
    private flatService: FlatService,
    public utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
    this.getTowersList();
  }


  // getTowersList() {
  //   this.firestoreService.getTowersList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c =>
  //         ({ key: c.payload.doc.id, ...c.payload.doc.data() })
  //       )
  //     )
  //   ).subscribe(towers => {
  //     this.towers = towers;
  //     console.log(towers);
  //   });
  // }


  getTowersList() {
    const snapshotResult = this.firestore.collection('/Condominios/' + this.idCondominio + '/Torres', ref =>
      ref.orderBy("fechaAlta", "desc"))
      .snapshotChanges()
      .pipe(map(docs =>
        docs.map(
          c =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
        )
      ))
    snapshotResult.subscribe(reser => {
      this.towers = reser;
    });

  }

  async delete(key) {
    // console.log(key);
    // this.firestoreService
    //   .deleteTower(key)
    //   .catch(err => console.log(err));
    let flag = confirm(`¿Desea borrar ${key.nombre}?`);
    if(flag){
      // console.log('Si hay que borrarlo');
      this.flatService.deleteFlat(key.id);
    }
  }

  update(key, data) {
    if (data.status)
      data.status = false;
    else
      data.status = true;
    console.log(key, data);
    this.firestoreService
      .updateTower(key, data)
      .catch(err => console.log(err));
  }

}
