<div class="limiter">
    <div class="container-table100">
        <div class="wrap-table100">
            <div class="table100">
                <table id="customers">
                    <tr>
                        <th>Nombre</th>
                        <th>Antelacion</th>
                        <th>Bloque</th>
                        <th>Icono</th>
                        <th>Capacidad</th>
                        <th>Por Departamento</th>
                        <th>Estatus</th>
                        <th>Acción</th>

                    </tr>
                    <tr *ngFor="let area of areas; index as i; first as isFirst">
                        <td>{{area.nombre}}</td>
                        <td>{{area.antelacion}}</td>
                        <td>{{area.bloque}}</td>
                        <td><img class="imagenIcon" src="{{area.icon}}"></td>
                        <td>{{area.capacidad}}</td>
                        <td>{{area.porDepartamento}}</td>
                        <td>{{area.status ? "Activo" : "Inactivo"}}</td>
                        <td class="align-icon">
                            <ng-template #editar>Editar area</ng-template>
                            <ng-template #editarE>Activo/Inactivo</ng-template>
                            <ng-template #borrarE>Eliminar area</ng-template>
                            <i [ngbTooltip]="editar" [routerLink]="['/edit-area', area.key, area.nombre]"
                                class="fa fa-pencil-square" aria-hidden="true"></i>
                            <i [ngbTooltip]="editarE" (click)="update(area.key,area)" class="fa fa-pencil-square-o"
                                aria-hidden="true"></i>
                            <i [ngbTooltip]="borrarE" (click)="deleteArea(area.key)" class="fa fa-trash"
                                aria-hidden="true"></i>
                        </td>
                    </tr>

                </table>
                <br>
                <div class="col-sm-12">
                    <button routerLink="/areas-form" class="button">
                        Nuevo
                        <div class="button__horizontal"></div>
                        <div class="button__vertical"></div>
                    </button>
                    <!-- <i routerLink="/areas-form" class="fa fa-plus-square fa-2x"></i> -->
                </div>
            </div>
        </div>
    </div>
</div>