
<div *ngIf="isAlert" class="alert">
  <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span> 
  <strong><i class="fa fa-flag" aria-hidden="true"></i></strong> El correo electronico o contraseña son invalidos, favor de verificarlos.
</div>


<div class="all">
  <form method="get" [formGroup]="loginForm" (ngSubmit)="onLogin()" class="login-form" autocomplete="off" role="main">
    <img src="../assets/images/condoclick.jpeg" alt="image" class="logo">
    <div>
      <label class="label-email">
        <input (change)="getIdUser()" formControlName="email" type="email" class="text" name="email" placeholder="Email"
          tabindex="1" required />
        <span class="required">Email</span>
      </label>
    </div>
    <input type="checkbox" name="show-password" class="show-password a11y-hidden" id="show-password" tabindex="3" />
    <label class="label-show-password" for="show-password">
      <span>Show Password</span>
    </label>
    <div>
      <label class="label-password">
        <input formControlName="password" type="text" class="text" name="password" placeholder="Password" tabindex="2"
          required />
        <span class="required">Password</span>
      </label>
    </div>
    <input type="submit" value="Iniciar Sesión" class="inputBL" />
    <!-- <input (click)="onGoogleLogin()" type="button" value="Google" class="inputBG" />
    <input (click)="onFaceBookLogin()" type="button" value="Facebook" class="inputBF" /> -->
    <!-- <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
      <i (click)="onGoogleLogin()" class="fa fa-google fa-2x" aria-hidden="true"></i> -->
    <div class="email">
      <a routerLink="/registro">Registrarse</a>
    </div>

    <div class="email">
      <a routerLink="/forgot-password">¿Olvido su contraseña?</a>
    </div>

  </form>

</div>