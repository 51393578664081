import { Component, OnInit, Input } from '@angular/core';
import { 
  AngularFirestore, 
  AngularFirestoreCollection, 
  DocumentChangeAction,
  DocumentReference,
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { flatMap, map, mergeMapTo } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IconsService } from '../../../../services/icons/icons.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { firestore } from 'firebase';

@Component({
  selector: 'app-editar-seguridad-area-modal',
  templateUrl: './editar-seguridad-area-modal.component.html',
  styleUrls: ['./editar-seguridad-area-modal.component.css']
})
export class EditarSeguridadAreaModalComponent implements OnInit {

  @Input() public securityArea;

  public formReport: FormGroup;

  public icons: Array<any> = [];
  public iconSelected = null;
  public idCodominium: String = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore, 
    private formBuilder: FormBuilder,
    public iconsService: IconsService,
    private modalService: NgbModal,
    public toastService: ToastService,
  ) { }

  close(content){
    this.activeModal.close(content);
  }

  get f(){ 
    return this.formReport.controls; 
  }

  getIcons(){
    this.icons = [];
    let ref = this.iconsService.getIcons().ref;
    ref.orderBy('name', 'asc')
    .get().then((icons) => {
      this.icons = icons.docs.map((icon) => icon.data());
      // console.log(this.icons);
      this.searchIconSelected();
      
    });
  }

  searchIconSelected(){
    this.icons.forEach((icon, index) => {
      if(icon.image == this.securityArea.icon){
        this.iconSelected = icon;
      }
    });
    // console.log(this.iconSelected);
    
  }

  setIcon($obj){
    console.log($obj);
    this.iconSelected = (typeof $obj === 'undefined') ? null : $obj;
    // console.log(this.formReport.value);
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.formReport);
    if (this.formReport.invalid || this.iconSelected == null) {
        return;
    }

    let newData = {
      lastUpdate: firestore.Timestamp.now(),
      icon: this.iconSelected.image,
      name: this.formReport.value.name,
    }

    // console.log(newData);

    let ref = this.securityArea.ref as DocumentReference;
    ref.update(newData)
    .then(() => {
      this.toastService.show(`¡Área ${newData.name} actualizada exitosamente!`, { classname: 'bg-success text-light'});
      this.close('');
    })
    .catch((e) => {
      console.log(e);
      this.toastService.show('No se pudo actualizar area', { classname: 'bg-danger text-light'});
      this.close('');
    })
    
  }

  ngOnInit(): void {
    // this.iconSelected = {
    //   icon: "Actividad",
    //   image: "https://firebasestorage.googleapis.com/v0/b/condb-b725c.appspot.com/o/images%2Ficons%2Ficon_no0xls144ll.png?alt=media&token=8d4b688b-e39f-44…",
    //   name: "Actividad",
    // }
    console.log('AREA: ', this.securityArea);
    this.formReport = this.formBuilder.group({
      name: [this.securityArea.name, Validators.required],
    });

    this.getIcons();
  }

}
