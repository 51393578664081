<div class="container-fluid pt-4 px-0">
    <div class="row">
        <div class="col-12 text-center" *ngIf="boards.length == 0">
            No hay avisos actualmente
        </div>

        <div class="col-12" *ngIf="boards.length > 0">
            <div class="card-columns">
                <div class="card" *ngFor="let board of boards">
                    <div class="position-relative" *ngIf="board.image != ''">
                        <img src="{{board.image}}" width="100%">
                        <button (click)="delete(board.id)" class="btn rounded-circle bg-white btn-sm d-flex align-items-center position-absolute shadow p-1 m-2" style="top: 0px; right: 0px;"><i class="material-icons text-black">delete</i></button>
                    </div>

                    <div class="position-relative d-flex align-items-center justify-content-center" *ngIf="board.image == ''" style="height: 20vh;">
                        <h2><b>{{board.titulo}}</b></h2>
                        <button (click)="delete(board.id)" class="btn rounded-circle bg-white btn-sm d-flex align-items-center position-absolute shadow p-1 m-2" style="top: 0px; right: 0px;"><i class="material-icons text-black">delete</i></button>
                    </div>

                    <div class="dropdown-divider" *ngIf="board.image == ''"></div>

                    <div class="col-12 pt-2">
                        <h3><b>{{board.titulo}}</b></h3>
                    </div>
                    <div class="col-12 pb-3">
                        {{board.descripcion}}
                    </div>
                    <div class="col-12 pb-3">
                        <small class="text-secondary">Fecha de caducidad {{board.fechaCaducidad.seconds*1000 | date:'dd/MM/yyyy HH:mm'}}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
